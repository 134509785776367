export interface Condutor {
    avatarUrl?: string;
    anotacao?: Anotacao[];
    CNH: string;
    email: string;
    beneficiaria: any;
    firstName: string;
    id: string;
    lastName: string;
    nome: string;
    telefone: string;
    validadeCNH: string;
    userVirtualId: string;
    redFlag: string;
    enabled: boolean;

    // app
    user?: any;
}

export interface Anotacao {
    id: string;
    anotacao: string;
    condutor?: string;
    created_at?: string;
    updated_at?: string;
}