import { Component, OnInit } from '@angular/core';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from '../shared/components';
import { Router } from '@angular/router';
import { UsuarioService, UsuarioResponse } from '.';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr } from '../shared/utils';
import { AuthService, RolesEnum, RolesInfo } from '../core';
import { TranslatePipe } from '../shared/pipes';

@Component({
    selector: 'app-usuario',
    templateUrl: './usuario.component.html',
    providers: [TranslatePipe]
})
export class UsuarioComponent implements OnInit {

    loading: boolean;
    toggleInfo: boolean;
    toggleFilters: boolean;
    rolesInfo = RolesInfo;
    currentRole: string;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Usuários' },
    ]
    
    selectedRole: RolesEnum;
    filter: string;
    filters: any = {
        role: null        
    };
    dataSource: UsuarioResponse[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        {
            tdClass: 'td-w-auto',
            template: (rowData: UsuarioResponse) => `<img height="40" width="40" class="img-cover rounded-circle bg-default" src="${rowData.avatarUrl || '/assets/img/default_user.jpeg'}" />`
        },
        { header: 'Nome', attr: 'name' },
        { header: 'Email', attr: 'email' },
        { header: 'Perfil', attr: 'role' },
        {
            header: 'Status',
            template: (rowData: UsuarioResponse) => `
                <span class="status-badge text-white ${rowData.enabled ? 'bg-success' : 'bg-secondary'}">
                    ${rowData.enabled ? 'Ativo' : 'Inativo'}
                </span>`
        }, {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Visualizar', color: 'accent' },
            click: {action: (rowData: UsuarioResponse) => this.router.navigateByUrl(`/usuario/${rowData.id}/visualizar`)}
        }, {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: { action: (rowData: UsuarioResponse) => this.router.navigateByUrl(this.authService.user.id == rowData.id ? `/perfil` : `/usuario/${rowData.id}/editar`)}
        }
    ]

    constructor(
        private authService: AuthService,
        private router: Router,
        private usuarioService: UsuarioService,
        private snackBar: MatSnackBar,
        private translatePipe: TranslatePipe,
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        this.usuarioService.getPaginated<UsuarioResponse>(this.pagination.current, this.pagination.pageSize, this.filter, this.selectedRole).subscribe({
            next: (res) => {
                this.dataSource = res.data.map((usuario) => ({
                    ...usuario,
                    name: `${usuario.firstName} ${usuario.lastName}`,
                    role: this.translatePipe.transform(usuario.role)
                }))
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => {
            this.loading = false;            
        });
    }

    searchByRole(){
        this.loading = true;
        console.log('filters.role: ', this.selectedRole);
        this.loading = false;
    }

    searchFn(term: string, item: any) {
        console.log(term, item);
        term = term.toLocaleLowerCase();
        return item.nome.toLocaleLowerCase().indexOf(term) > -1;
    }

    compareWith(item: any, selected: any) {
        console.log('compareWith', item, selected);
        return item.id == selected.id
    }
}
