import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { catchErr, formatHumanTime } from 'src/app/shared/utils';
import { Dio, MonitoramentoGeolocalizacao, StatusAfericaoEnum } from '../dio.model';
import { DioService } from '../dio.service';
import { CancelarAfericaoDioComponent } from './cancelar-afericao-dio/cancelar-afericao-dio.component';
import { Observable, Subject, catchError, concat, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';

import { groupBy, identity, mapValues, pickBy, uniqBy } from 'lodash-es';
import { AfericaoService } from '../afericao.service';
import { AuthService, RolesEnum } from 'src/app/core';
import { AprovarDto } from './aprovar.dto';
import { convertMinutesToHours, formatarTempoMinutosESegundos, useTimeOneDate } from 'src/app/shared/date-utils';
import * as dayjs from 'dayjs';
import * as jsonMock from './response-mock'
import { MetricaService } from 'src/app/metrica/metrica.service';
import { UtcDatePipe } from 'src/app/shared/pipes/utc-date.pipe';

@Component({
    selector: 'app-aferir-dio',
    templateUrl: './aferir-dio.component.html',
    styleUrls: ['./aferir-dio.component.css'],
    providers: [DatePipe]
})
export class AferirDioComponent implements OnInit {

    loading: any = { main: true, dios: false, afericao: false, aprovacao: false, mapa: false, info2Points: false };
    show: any = { actions: false, timeline: false, options: false, cancels: false, legend: false, info2Points: false };
    isModoAfericao: boolean = true;
    panelOpenState: boolean[] = [];
    titlePage: string = 'Aferir DIO';
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Diários de Operação', route: '/dio' },
        { label: 'Aferir DIO' },
    ]

    dio = {} as Dio;
    afericao = {} as any;
    diosCompare: Dio[];
    dios$: Observable<Dio[]>;
    diosInput$ = new Subject<string>();
    particalCancels: any[];
    deletedCortes: string[] = [];
    currentRole: string;

    horasTrabalhadas: string;
    horasDisponiveis: string;
    kmRodados: number;

    startTime: string;
    endTime: string;

    map: google.maps.Map;
    heatmap: google.maps.visualization.HeatmapLayer;
    dataPositions: MonitoramentoGeolocalizacao[] = [];
    dataPositionsFiltered: MonitoramentoGeolocalizacao[] = [];

    directionsService = new google.maps.DirectionsService();
    mapDirectionsRenderer: google.maps.DirectionsRenderer;
    currentDirections: google.maps.DirectionsResult[] = [];
    lineDirections: google.maps.Polyline[] = [];

    points: any = {
        dio: true,
        stop: false,
        info2Points: { start: null, end: null, data: null },
    };

    infoWindow = new google.maps.InfoWindow();
    infoWindowEvent: any = { open: null, close: null };

    svgFlag = {
        url: '../../../assets/img/flag_start.svg',
        scaledSize: new google.maps.Size(30, 30),
        anchor: new google.maps.Point(3, 30),
    }
    svgMarkerStopPath = 'M12,2C8.1,2,5,5.1,5,9c0,5.2,7,13,7,13s7-7.8,7-13C19,5.1,15.9,2,12,2z M12,4.6c0.9,0,1.6,0.3,2.3,0.7l-6.1,6.1 C7.8,10.6,7.6,9.9,7.6,9C7.6,6.6,9.6,4.6,12,4.6z M12,13.4c-0.9,0-1.6-0.3-2.3-0.7l6.1-6.1c0.4,0.7,0.7,1.5,0.7,2.3 C16.4,11.4,14.4,13.4,12,13.4z';
    svgMarkerDefaultPath = 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z';
    svgMarker = {
        path: this.svgMarkerDefaultPath,
        fillColor: 'red',
        fillOpacity: 1,
        strokeWeight: 1,
        strokeOpacity: .3,
        rotation: 0,
        scale: 1,
        anchor: new google.maps.Point(12, 22),
    };

    constructor(
        private activatedRoute: ActivatedRoute,
        private afericaoService: AfericaoService,
        private authService: AuthService,
        private datePipe: DatePipe,
        private dialog: MatDialog,
        private dioService: DioService,
        private metricaService: MetricaService,
        private removeDialogService: RemoveDialogService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.currentRole = this.authService.user.realm_access.roles[0];
        if (this.activatedRoute.snapshot.url[1].path === 'mapa') {
            this.isModoAfericao = false;
            this.titlePage = 'Visualizar Mapa';
        }

        this.dio.id = this.activatedRoute.snapshot.params['idDio'];
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Diários de Operação', route: '/dio' },
            { label: 'Visualizar DIO', route: `/dio/${this.dio.id}/visualizar` },
            { label: this.isModoAfericao ? 'Aferir DIO' : 'Mapa' },
        ];

        if (this.dio.id) {
            this.getDio();
        }

        this.getAfericao();
    }

    aprove() {
        this.loading.aprovacao = true;
        const aprovarDto: AprovarDto = {
            isCorteTotal: this.deletedCortes.length === 0 && this.particalCancels.length === 0,
            cortes: this.deletedCortes,
            motivo: `Aprovado por ${this.authService.user.firstName} ${this.authService.user.lastName}`,
        };

        this.afericaoService.aprovar(this.dio.id || '', aprovarDto).subscribe({
            next: (res) => {
                this.snackBar.open('Aferição aprovada com sucesso', '✓', { panelClass: 'success' });
                this.getAfericao();
                this.getDio();
                this.dioService.notifygetMetricsSubject();
                this.loading.aprovacao = false;
            }, error: (err) => {
                catchErr(err, this.snackBar)
                this.loading.aprovacao = false;
            }
        });
    }

    finish() {
        this.loading.afericao = true;
        const hasRessalva = this.particalCancels && this.particalCancels.length > 0;

        this.afericaoService.finishAfericao(this.dio.id || '', {
            hasRessalva,
            isCorteTotal: false,
        }).subscribe({
            next: (res) => {
                this.snackBar.open('Aferição finalizada com sucesso', '✓', { panelClass: 'success' });
                this.getAfericao();
                this.getDio();
                this.dioService.notifygetMetricsSubject();
                this.loading.afericao = false;
            }, error: (err) => {
                this.loading.afericao = false;
                catchErr(err, this.snackBar);
            }
        });
    }

    checkDioFinishSameDay() {
        return dayjs(this.dio.dataInicioExecucao).isSame(this.dio.dataFimExecucao, 'day')
    }

    canAproveOrDeleteAfericao() {
        const rolesAllowed = [
            RolesEnum.GESTAO_ADMIN.toString(),
            RolesEnum.COORDENADOR.toString(),
        ].includes(this.currentRole);

        const status = this.afericao.status === StatusAfericaoEnum.PENDENTE_APROVACAO;
        return rolesAllowed && status;
    }

    canSeeActions() {
        return [
            RolesEnum.GESTAO_ADMIN.toString(),
            RolesEnum.COORDENADOR.toString(),
            RolesEnum.AFERIDOR.toString(),
        ].includes(this.currentRole);
    }

    disableCancelamentoParcial() {
        if (
            (this.currentRole === RolesEnum.COORDENADOR.toString() ||
                this.currentRole === RolesEnum.GESTAO_ADMIN.toString()) &&
            this.afericao?.status === StatusAfericaoEnum.PENDENTE_APROVACAO
        ) {
            return false;
        }

        return this.afericao?.status === StatusAfericaoEnum.PENDENTE_APROVACAO
            || !this.isModoAfericao;
    }

    disableCancelamentoTotal() {
        if (
            (this.currentRole === RolesEnum.COORDENADOR.toString() ||
                this.currentRole === RolesEnum.GESTAO_ADMIN.toString()) &&
            this.afericao?.status === StatusAfericaoEnum.PENDENTE_APROVACAO
        ) {
            return false;
        }

        return (this.particalCancels && this.particalCancels.length > 0)
    }

    showCancelDialog(cancelTotal?: boolean) {
        const aprovarCtx = this.afericao.status === StatusAfericaoEnum.PENDENTE_APROVACAO;

        const dialogRef = this.dialog.open(CancelarAfericaoDioComponent, { data: { aprovarCtx, cancelTotal, dio: this.dio }, width: '60vw' });
        dialogRef.afterClosed().subscribe(() => {
            this.getAfericao();
            this.getDio();
            this.getAfericao();
        });
    }

    showDeletedCortesDialog(corte: any) {
        this.removeDialogService.show('',
            (dialogRef: MatDialogRef<any>) => {
                this.deletedCortes.push(corte.id);
                this.particalCancels = this.particalCancels.filter(({ id }) => id !== corte.id);
                dialogRef.close();
            },
            'Deseja mesmo apagar esse corte?',
            'Os cortes parciais so serao apagados quando terminar a aprovacao da afericao abaixo',
            'apagar'
        );
    }

    showDiv(div: string) {
        this.show = mapValues(this.show, (value: any, key: any) => (div === key) ? !this.show[div] : (key === 'actions' ? value : false))
        this.show.actions = (this.show[div] && this.show.actions) ? true : !this.show.actions
    }

    showPoint(index: number) {
        this.map.setZoom(15);
        this.map.panTo(this.stringToLatLng(this.dataPositions[index].geolocalizacao));
        this.dataPositions[index].marker?.setAnimation(google.maps.Animation.BOUNCE);
        setTimeout(() => this.dataPositions[index].marker?.setAnimation(null), 2000);
        this.panelOpenState[index] = !this.panelOpenState[index];
    }

    toggleDioPoints($event: MatSlideToggleChange) {
        this.points.dio = $event.checked;
        this.points.stop = false;
        this.toggleStopPoint({ checked: false } as any);
        this.dataPositions.map((point) => $event.checked ? point.marker?.setMap(this.map) : point.marker?.setMap(null))
    }

    toggleStopPoint($event: MatSlideToggleChange) {
        if ($event.checked) {
            const group = groupBy(this.dataPositions, 'geolocalizacao');
            let stopPoints: any = pickBy(mapValues(group, (value) => value.length > 1 ? value : undefined), identity);
            stopPoints = mapValues(stopPoints, (points, index) => {
                let start: any, end: any;
                points?.map((point: any) => {
                    start = start || point.data_transmissao;
                    start = dayjs(start).valueOf() < dayjs(point.data_transmissao).valueOf() ? start : point.data_transmissao;
                    end = end || point.data_transmissao;
                    end = dayjs(end).valueOf() < dayjs(point.data_transmissao).valueOf() ? point.data_transmissao : end;

                    point.start = start;
                    point.end = end;
                    point.qtde = stopPoints[index].length;
                    point.period = dayjs(end).diff(dayjs(start), 'seconds');
                });
                return points.pop();
            });

            this.dataPositionsFiltered = Object.values(stopPoints);
        } else {
            this.dataPositionsFiltered = this.dataPositions;
        }

        this.dataPositions.map((point) => this.dataPositionsFiltered.find((p) => p.geolocalizacao === point.geolocalizacao) ? point.marker?.setMap(this.map) : point.marker?.setMap(null))
    }

    toggleHeatMap($event: MatSlideToggleChange) {
        $event.checked ? this.heatmap.setMap(this.map) : this.heatmap.setMap(null);
    }

    toggleDirections($event: { checked: boolean }, dataArray?: any[]) {
        if (($event.checked && !this.currentDirections.length) || !!dataArray) {
            const data = dataArray || this.dataPositions;
            const uniq = uniqBy(data, 'geolocalizacao');
            const size = 20;
            let count = 0;
            let dataChunk = uniq.reduce((prev: any, curr, index, arr) => {
                if (index % size === 0) {
                    prev.push(arr.slice(index - count, index - count + size));
                    count++;
                }
                return prev;
            }, []);

            dataChunk.map((chunk: any) => {
                const waypoints = chunk.slice(1).slice(0, -1).map((point: any) => ({
                    location: this.stringToLatLng(point.geolocalizacao).toJSON(),
                    stopover: true,
                }))

                this.directionsService.route({
                    origin: this.stringToLatLng(chunk[0].geolocalizacao),
                    destination: this.stringToLatLng(chunk[chunk.length - 1].geolocalizacao),
                    waypoints: waypoints,
                    travelMode: google.maps.TravelMode.DRIVING,
                    optimizeWaypoints: true,
                }).then((response) => {
                    this.currentDirections.push(response);
                    this.mapDirectionsRenderer.setDirections(response)
                    this.drawLineDirections(response.routes[0].overview_path)
                }).catch((e) => {
                    this.snackBar.open('Não foi possível traçar a rota', '✕', { panelClass: 'danger' })
                });
            });
        } else if ($event.checked && this.currentDirections.length) {
            this.currentDirections.map((direction) => this.drawLineDirections(direction.routes[0].overview_path));
        } else {
            this.lineDirections.map((line) => line.setMap(null));
            this.lineDirections = []
        }
    }

    private drawLineDirections(pathArray: google.maps.LatLng[]) {
        this.lineDirections.push(new google.maps.Polyline({
            map: this.map,
            path: pathArray,
            strokeColor: '#4285F4',
            strokeWeight: 5,
            strokeOpacity: .75,
            icons: [{
                icon: {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    scale: 5,
                    strokeColor: '#fff',
                    strokeWeight: 2,
                    strokeOpacity: 1,
                    fillColor: '#4285F4',
                    fillOpacity: 1
                },
                repeat: '100px',
            }]
        }));
    }

    private getHorasDisponiveis(dio: Dio): string {
        let minutosDisponiveis = 0;
        const execucao = dio.execucaoDetalhes;

        if (execucao && execucao.length) {
            minutosDisponiveis = execucao[0].disponibilidadeTotalMinutes ? execucao[0].disponibilidadeTotalMinutes : 0;
        }

        const { hours, minutes } = convertMinutesToHours(minutosDisponiveis);

        let message = `${hours} Horas e ${minutes} min`;

        if (hours === 0) {
            message = `${minutes} Minutos`;
        } else if (minutes === 0) {
            message = `${hours} Horas`;
        }

        return minutosDisponiveis != 0 ? message : '---';
    }

    private getHorasTrabalhadas(dio: Dio): string {
        let minutosTrabalhados = 0;
        const execucao = dio.execucaoDetalhes;

        if (execucao && execucao.length) {
            minutosTrabalhados = execucao[0].funcionamentoTotalMinutes ? execucao[0].funcionamentoTotalMinutes : 0;
        }

        const { hours, minutes } = convertMinutesToHours(minutosTrabalhados);

        let message = `${hours} Horas e ${minutes} min`;

        if (hours === 0) {
            message = `${minutes} Minutos`;
        } else if (minutes === 0) {
            message = `${hours} Horas`;
        }

        return minutosTrabalhados != 0 ? message : '---';
    }

    private getKmsRodados(dio: Dio): number {
        const execucao = dio.execucaoDetalhes;
        return execucao && execucao[0] && execucao[0].kmRodados ? execucao[0].kmRodados : 0;
    }

    // start

    private getAfericao() {
        this.afericaoService.findAfericaoByDio(this.dio?.id || '').subscribe({
            next: (res) => {
                this.afericao = res;
                if (this.afericao?.status !== 'PENDENTE_APROVACAO' && this.afericao?.status !== 'INICIADA') {
                    this.isModoAfericao = false;
                }

                // getParcialCancelations
                this.afericaoService.getCortesAfericao(this.afericao?.id).subscribe({
                    next: (res) => {
                        this.particalCancels = res
                        console.log('partial: ', this.particalCancels);
                        
                        if(this.particalCancels){
                            this.particalCancels.forEach(p => {
                                let regrasStr = ''
                                if(p.regras && p.regras.length){
                                    p.regras.forEach((r: { regra: string; }) => {regrasStr = regrasStr + r.regra + ', '});
                                }
                                p.regrasStr = regrasStr;
                                console.log('p: ', p);
                            })
                        }
                    },
                    error: (err) => console.log(err)
                });
            }, error: (err) => catchErr(err, this.snackBar)
        });
    }

    private getDio() {
        this.dioService.getById<Dio>(this.dio.id || '').subscribe({
            next: (res) => {
                this.dio = res;
                this.horasDisponiveis = this.getHorasDisponiveis(this.dio);
                this.horasTrabalhadas = this.getHorasTrabalhadas(this.dio);
                this.kmRodados = this.getKmsRodados(this.dio);

                this.getDios();
                this.initMap();
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }

    private getDios() {
        this.dios$ = concat(
            of([]), // default items
            this.diosInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.loading.dios = true),
                switchMap((term) => {
                    return term ?
                        this.dioService.getPaginated<Dio>(0, 20, { numero: term }).pipe(
                            map((res) => res.data),
                            catchError(() => of([])), // empty list on error
                            tap(() => this.loading.dios = false)
                        ) : new Promise(() => this.loading.dios = false) // not search all
                })
            )
        ) as Observable<Dio[]>;
    }

    private initMap() {
        this.mapDirectionsRenderer = new google.maps.DirectionsRenderer({
            polylineOptions: { strokeWeight: 0 }, // drawline with arrows
            preserveViewport: true,
            suppressMarkers: true,
        });
        this.map = new google.maps.Map(
            document.getElementById('map') as HTMLElement,
            {
                center: { lat: -15.8010569, lng: -47.9299997 },
                zoom: 11,
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControlOptions: { position: google.maps.ControlPosition.LEFT_BOTTOM },
                zoomControlOptions: { position: google.maps.ControlPosition.LEFT_BOTTOM },
                styles: [{
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }]
                }]
            }
        );

        this.mapDirectionsRenderer.setMap(this.map);
        this.getDataPositions();
    }

    getDataPositionsByTime() {
        if (this.startTime && this.endTime) {
            this.getDataPositions(`${this.startTime.slice(0, 2)}:${this.startTime.slice(2)}`, `${this.endTime.slice(0, 2)}:${this.endTime.slice(2)}`);
        } else if (!this.startTime && !this.endTime) {
            this.getDataPositions()
        }
    }

    private getDataPositions(startTime?: string, endTime?: string) {
        if (this.dio && this.dio.alocacao?.equipamento?.placa && this.dio.dataInicioExecucao && (this.dio.dataFimExecucao || this.currentRole === RolesEnum.GESTAO_ADMIN)) {
            if (this.dio.dataFimExecucao === undefined || this.dio.dataFimExecucao == null) {
                this.dio.dataFimExecucao = new Date();
            }

            this.loading.mapa = true;

            if (startTime && endTime) {
                this.dio.dataInicioExecucao = useTimeOneDate(this.dio.dataInicioExecucao, startTime);
                this.dio.dataFimExecucao = useTimeOneDate(this.dio.dataFimExecucao, endTime);
            }

            let periodoInicio = dayjs(this.dio.dataInicioExecucao).format('YYYY-MM-DD HH:mm:ss');
            let periodoFim = dayjs(this.dio.dataFimExecucao).format('YYYY-MM-DD HH:mm:ss');
            this.dioService.getGeolocalizacoesByEquipamentoId({
                idEquipamento: this.dio.alocacao.equipamento.id,
                start: periodoInicio,
                end: periodoFim
            }).subscribe({
                next: (res) => {

                    // TODO remover mock
                    if (this.dio.numero === '2H') {
                        console.log("mock: ", jsonMock);
                        res = jsonMock.geosMock;
                    }

                    if (res && res.length > 0) {
                        this.dataPositions = res;
                        this.dataPositionsFiltered = this.dataPositions;
                        this.heatmap = new google.maps.visualization.HeatmapLayer({
                            radius: 20,
                            data: res.map((point) => {
                                const geo = point.geolocalizacao.split(',');
                                return new google.maps.LatLng(parseFloat(geo[0]), parseFloat(geo[1]));
                            }),
                        })
                        this.setDataPositionsMarkers();
                    } else {
                        this.snackBar.open('Nenhum ponto foi retornado para o veículo', '✕', { panelClass: 'warning' });
                    }
                },
                error: (err) => {
                    this.snackBar.open('Não foi possível buscar a rota do veículo', '✕', { panelClass: 'danger' })
                }
            }).add(() => this.loading.mapa = false);
        }
    }

    private setDataPositionsMarkers() {
        this.dataPositions && this.dataPositions.length > 0 && this.dataPositions.map((data, index) => {
            // set marker icon/color
            this.svgMarker.fillColor = data.ignicao ? '#1E90FF' : '#ADD8E6';
            this.svgMarker.path = (!data.velocidade || data.velocidade <= 0) ? this.svgMarkerStopPath : this.svgMarkerDefaultPath;
            this.svgFlag.url = index === 0 ? '../../../assets/img/flag_start.svg' : '../../../assets/img/flag_end.svg';

            //set marker
            const marker = new google.maps.Marker({
                map: this.map,
                position: this.stringToLatLng(data.geolocalizacao),
                icon: index === 0 || (index === this.dataPositions.length - 1) ? this.svgFlag : this.svgMarker,
                zIndex: index === 0 || (index === this.dataPositions.length - 1) ? 2 : 1
            });
            marker.addListener('click', () => {
                this.infoWindow.setContent(this.getInfoWindowContent(data));
                this.infoWindow.open({
                    anchor: marker,
                    map: this.map,
                });
            });
            data.marker = marker;
        })
    }

    private stringToLatLng(string: string) {
        const geoSplited = string.split(',');
        return new google.maps.LatLng(Number(geoSplited[0]), Number(geoSplited[1]));
    }

    private getInfoWindowContent(dataPosition: any) {
        console.log('dataposition: ', dataPosition);
        // open
        this.infoWindowEvent.open = google.maps.event.addListener(this.infoWindow, 'domready', () => {
            const btnAdd = document.getElementById(`${dataPosition._id}_add`);
            btnAdd && btnAdd.addEventListener('click', () => {
                this.selectInfo2Points(dataPosition);
                this.infoWindow.close();
                this.removeInfoWindowListener();
            });

            const btnRemove = document.getElementById(`${dataPosition._id}_remove`);
            btnRemove && btnRemove.addEventListener('click', () => {
                this.removeInfo2Points(dataPosition);
                this.infoWindow.close();
                this.removeInfoWindowListener()
            });
        });

        // close
        this.infoWindowEvent.close = google.maps.event.addListener(this.infoWindow, 'closeclick', () => this.removeInfoWindowListener());

        return this.points.stop ? `
            <div class="overflow-hidden" style="width: 15rem">
                <div class="row">
                    <div class="col-6 mb-3">
                        <span class="fs-5">${dataPosition.qtde}</span>
                        <div class="fw-lighter text-gray">Qtde de pontos</div>
                    </div>
                    <div class="col-6 mb-3">
                        <span class="fs-5">${formatHumanTime(dataPosition.period, true, 'seconds')}</span>
                        <div class="fw-lighter text-gray">Duração</div>
                    </div>
                    <div class="col-6">
                        <span class="fs-5">${this.datePipe.transform(dataPosition.start, 'HH:mm:ss')}</span>
                        <div class="fw-lighter text-gray">Inicio da parada</div>
                    </div>
                    <div class="col-6">
                        <span class="fs-5">${this.datePipe.transform(dataPosition.end, 'HH:mm:ss')}</span>
                        <div class="fw-lighter text-gray">Fim da parada</div>
                    </div>
                </div>
            </div>
        ` : `
            <div class="overflow-hidden">
                <span class="status-badge bg-${dataPosition.ignicao ? 'success' : 'danger'} text-white mb-1">${dataPosition.ignicao ? 'Ligado' : 'Desligado'}</span>
                <div class="dotted-line fs-7 mt-1">
                    <div class="d-flex justify-content-between mt-1">
                        <span class="fw-lighter me-1">Data/Hora</span>
                        <span>${this.datePipe.transform(dataPosition.data_transmissao, 'dd/MM/yyyy HH:mm:ss')}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <span class="fw-lighter me-1">Bateria</span>
                        <span>${dataPosition.bateria}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <span class="fw-lighter me-1">Velocidade</span>
                        <span>${dataPosition.velocidade}km/h</span>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <span class="fw-lighter me-1">Odometro</span>
                        <span>${dataPosition.odometro} km</span>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <span class="fw-lighter me-1">Horímetro</span>
                        <span>${dataPosition.horimetro}</span>
                    </div>
                </div>
                ${this.points.info2Points.start !== dataPosition.odometro && this.points.info2Points.end !== dataPosition.odometro ? `
                    <button class="mat-mdc-unelevated-button mdc-button mat-primary btn-sm w-100 mt-2" id="${dataPosition._id}_add">
                        ${!this.points.info2Points.start ? 'Selecionar Partida' : 'Selecionar Destino'}
                    </button>
                ` : this.points.info2Points.start == dataPosition.odometro || this.points.info2Points.end == dataPosition.odometro ? `
                    <button class="mat-mdc-unelevated-button mdc-button mat-warn btn-sm w-100 mt-2" id="${dataPosition._id}_remove">
                        Desmarcar Ponto
                    </button>
                ` : ''}
            </div>`
    }

    private selectInfo2Points(dataPosition: any) {
        dataPosition.marker?.setAnimation(google.maps.Animation.BOUNCE);
        this.points.info2Points.end = this.points.info2Points.start && this.points.info2Points.end === null ? dataPosition.data_transmissao : this.points.info2Points.end;
        this.points.info2Points.start = this.points.info2Points.start === null ? dataPosition.data_transmissao : this.points.info2Points.start;
        this.points.info2Points.start && this.points.info2Points.end && this.getInfos2Points()
    }

    private removeInfo2Points(dataPosition: any) {
        dataPosition.marker?.setAnimation(null);
        this.removePartialDirection()
        this.show.info2Points = false;
        this.points.info2Points.start = this.points.info2Points.start === dataPosition.data_transmissao ? null : this.points.info2Points.start;
        this.points.info2Points.end = this.points.info2Points.end === dataPosition.data_transmissao ? null : this.points.info2Points.end;
    }

    private getInfos2Points() {
        this.show.info2Points = true;
        this.loading.info2Points = true;

        this.metricaService.getMetrics(this.dio.alocacao.equipamento.placa, this.points.info2Points.start!, this.points.info2Points.end!).subscribe({
            next: (res) => {
                this.points.info2Points.data = res;

                this.points.info2Points.data.disponibilidadeTotalMinutes = formatarTempoMinutosESegundos(this.points.info2Points.data.disponibilidadeTotalMinutes);
                this.points.info2Points.data.funcionamentoTotalMinutes = formatarTempoMinutosESegundos(this.points.info2Points.data.funcionamentoTotalMinutes);

                const startIndex = this.dataPositions.findIndex((point) => point.odometro === this.points.info2Points.start);
                const endIndex = this.dataPositions.findIndex((point) => point.odometro === this.points.info2Points.end);
                
                startIndex < endIndex 
                    ? this.toggleDirections({ checked: true }, this.dataPositions.slice(startIndex, endIndex + 1)) 
                    : this.toggleDirections({ checked: true }, this.dataPositions.slice(endIndex, startIndex + 1))
            },
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.info2Points = false);
    }

    closeInfos2Points() {
        this.dataPositions.map((point) => point.marker?.setAnimation(null));
        this.show.info2Points = false;
        this.points.info2Points.start = null;
        this.points.info2Points.end = null;
        this.removePartialDirection()
    }

    private removeInfoWindowListener() {
        google.maps.event.removeListener(this.infoWindowEvent.open);
        google.maps.event.removeListener(this.infoWindowEvent.close);
    }

    private removePartialDirection() {
        this.toggleDirections({ checked: false });
        this.currentDirections = [];
    }
}
