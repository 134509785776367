import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';

import { AuthInterceptor } from './core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import * as dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import { AppRoutingModule } from './app-routing.module';
import { BeneficiariaModule } from './beneficiaria/beneficiaria.module';
import { CondutorModule } from './condutor/condutor.module';
import { ConfigModule } from './config/config.module';
import { ContratoModule } from './contrato/contrato.module';
import { CoreModule } from './core/core.module';
import { DioModule } from './dio/dio.module';
import { EquipamentoModule } from './equipamento/equipamento.module';
import { FaturamentoModule } from './faturamento/faturamento.module';
import { LocalidadeModule } from './localidade/localidade.module';
import { RelatorioModule } from './relatorio/relatorio.module';
import { ServicoModule } from './servico/servico.module';
import { SharedModule } from './shared/shared.module';
import { UsuarioModule } from './usuario/usuario.module';
import { ApmModule } from '@elastic/apm-rum-angular';
import { MetricaModule } from './metrica/metrica.module';

dayjs.locale('pt-br');
registerLocaleData(ptBr);

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
    ],
    imports: [
        AppRoutingModule,
        BeneficiariaModule,
        BrowserAnimationsModule,
        BrowserModule,
        CondutorModule,
        ConfigModule,
        ContratoModule,
        CoreModule,
        DioModule,
        EquipamentoModule,
        FaturamentoModule,
        HttpClientModule,
        LocalidadeModule,
        RelatorioModule,
        ServicoModule,
        SharedModule,
        UsuarioModule,
        MetricaModule,
        ApmModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
