
<ng-container>
    <div mat-dialog-title>{{ cancelTotal ? 'Cancelamento Total' : 'Cancelamento Parcial' }}</div>
    <div mat-dialog-content>
        <div class="row">
            <!-- INICIO -->
            <div class="col-12 col-md-6" *ngIf="!cancelTotal">
                <span class="fw-bold fs-7">INICIO</span>
                <div class="d-flex">
                    <mat-form-field class="w-100 me-2" *ngIf="!checkDioFinishSameDay()">
                        <mat-label>Data</mat-label>
                        <input matInput [matDatepicker]="pickerStart" name="dateStart" #dateStart="ngModel"
                            autocomplete="off" [(ngModel)]="cancelData.dateStart" required>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>
                        <mat-error *ngIf="showInputError(dateStart, 'required')">Data é obrigatório</mat-error>
                    </mat-form-field>
            
                    <mat-form-field class="w-100">
                        <mat-label>Hora</mat-label>
                        <input matInput name="hourStart" #hourStart="ngModel" [(ngModel)]="cancelData.hourStart" mask="Hh:m0" placeholder="--:--" required>
                        <mat-error *ngIf="showInputError(hourStart, 'required')">Hora é obrigatório</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <!-- FIM -->
            <div class="col-12 col-md-6" *ngIf="!cancelTotal">
                <span class="fw-bold fs-7">FIM</span>
                <div class="d-flex">
                    <mat-form-field class="w-100 me-2" *ngIf="!checkDioFinishSameDay()">
                        <mat-label>Data</mat-label>
                        <input matInput [matDatepicker]="pickerEnd" name="dateEnd" #dateEnd="ngModel"
                            autocomplete="off" [(ngModel)]="cancelData.dateEnd" required>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                        <mat-error *ngIf="showInputError(dateEnd, 'required')">Data é obrigatório</mat-error>
                    </mat-form-field>
            
                    <mat-form-field class="w-100">
                        <mat-label>Hora</mat-label>
                        <input matInput name="hourEnd" #hourEnd="ngModel" [(ngModel)]="cancelData.hourEnd" mask="Hh:m0" placeholder="--:--" required>
                        <mat-error *ngIf="showInputError(hourEnd, 'required')">Hora é obrigatório</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <ng-select name="regrasCortes" [items]="regrasCortes" bindValue="id" placeholder="Regras de Corte" required
                    [searchFn]="searchFn" [(ngModel)]="regraCorteSelecionada" [compareWith]="compareWith"
                    (change)="setRegraCorte($event)">
                    <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                        {{ item.nome}}
                    </ng-template>
                </ng-select>
            </div>
            <div class="col-md-2">
                <button mat-flat-button color="primary"  (click)="addRegra()" [ngClass]="{'btn-dialog-spinner': loading}">
                    <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
                    <span *ngIf="!loading">Add</span>
                </button>
            </div>
            <div class="col-6">
                <span class="fw-bold fs-7">Regras de Corte Adicionadas</span>
                <ul>
                    <li *ngFor="let regra of cancelData.regras; let i = index">
                        {{ regra }}
                        <button (click)="removerRegra(i)">x</button>
                    </li>
                </ul>                  
            </div>
        </div>
        <div class="row">
            <!-- DESCRIÇÃO -->
            <mat-form-field class="col-12">
                <mat-label>Observação</mat-label>
                <textarea matInput name="observacao" #justificativa="ngModel" [(ngModel)]="cancelData.motivo"></textarea>
            </mat-form-field>
    
            <!-- ARQUIVOS -->
            <div class="col-12">
                <ng-dd-file 
                    [filesIn]="cancelData.imagens" [multiple]="true" 
                    [btnClass]="{ file: 'mat-button mat-primary', remove: 'mat-button mat-warn' }"
                    (filesOut)="filesOut($event)" (fileRemove)="fileRemove($event)"
                ></ng-dd-file>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="px-3 pb-3 justify-content-end">
        <button mat-button mat-dialog-close class="px-3">Fechar</button>
        <button mat-flat-button color="warn"  (click)="cancelDio()" [ngClass]="{'btn-dialog-spinner': loading}" [disabled]="diabledCancelationButton()">
            <mat-spinner class="btn-spinner" *ngIf="loading"></mat-spinner>
            <span *ngIf="!loading">Cancelar</span>
        </button>
    </div>
</ng-container>