import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb, GenerateTableColumn, RemoveDialogService } from 'src/app/shared/components';
import { Ausencia, UsuarioResponse } from '../usuario.model';
import { catchErr } from 'src/app/shared/utils';
import { UsuarioService } from '../usuario.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, RolesEnum } from 'src/app/core';
import { UsuarioAusenciaService } from '../usuario-ausencia.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AfericaoService, Aferidores } from 'src/app/dio';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-view-usuario',
    templateUrl: './view-usuario.component.html'
})
export class ViewUsuarioComponent implements OnInit {

    loading = { main: true, ausencia: false, reserPassword: false };
    usuario = {} as UsuarioResponse;
    aferidor: Aferidores;
    ausencias: Ausencia[];
    currentRole: string;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Usuários', route: '/usuario' },
        { label: 'Visualizar usuário' },
    ]

    constructor(
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private usuarioService: UsuarioService,
        private ausenciaService: UsuarioAusenciaService,
        private snackBar: MatSnackBar,
        private removeDialogService: RemoveDialogService,
        private aferidorService: AfericaoService,
    ) { }

    columns: GenerateTableColumn[] = [
        { header: 'Data Inicio', attr: 'dataInicio' },
        { header: 'Data Fim', attr: 'dataFim' },
        { header: 'Motivo', attr: 'motivo' },
        { header: 'Responsável Cadastro', attr: 'responsavel' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Apagar', color: 'warn' },
            click: { action: (rowData: Ausencia) => this.showRemoveDialog(rowData)}
        }
    ]

    ngOnInit() {
        this.currentRole = this.authService.user.realm_access.roles[0];
        this.usuarioService.getById<UsuarioResponse>(this.activatedRoute.snapshot.params['id']).subscribe({
            next: (res) => {
                this.usuario = res;
                this.getAusencias();

                const canEnableAferidor = this.currentRole === RolesEnum.COORDENADOR || this.currentRole === RolesEnum.GESTAO_ADMIN;
                const isAferidor = this.usuario.role === RolesEnum.AFERIDOR;
                if (canEnableAferidor && isAferidor) {
                    this.aferidorService.findAferidor(this.usuario.id || '').subscribe({
                        next: (res) => this.aferidor = res,
                        error: (err) => catchErr(err, this.snackBar)
                    });
                }
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);        
    }

    getAusencias() {
        this.loading.ausencia = true;
        this.ausenciaService.getAusencias(this.usuario.id).subscribe({
            next: (res) => this.ausencias = res.map((ausencia: Ausencia) => ({
                ...ausencia,
                dataInicio: `${dayjs(ausencia.dataInicio).format('DD/MM/YYYY')}`,
                dataFim: `${dayjs(ausencia.dataFim).format('DD/MM/YYYY')}`
            })), error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.ausencia = false);
    }

    sendResetPasswordEmail() {
        this.loading.reserPassword = true
        const userId = this.activatedRoute.snapshot.params['id'];
        this.usuarioService.sendResetPasswordEmail(userId).subscribe({
            next: () => this.snackBar.open('E-mail para redefinição de senha enviado com sucesso!', '✕', { panelClass: ['success'] }),
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.reserPassword = false);
    }

    showRemoveDialog(ausencia: Ausencia) {
        const title = `ausência do dia ${ausencia.dataInicio} até ${ausencia.dataFim}`;
        this.removeDialogService.show(title, (dialogRef: MatDialogRef<any>) => {
            this.ausenciaService.remove<Ausencia>(ausencia.id).subscribe({
                next: () => {
                    this.snackBar.open('Serviço apagado com sucesso!', '✕', { panelClass: ['success'] });
                    dialogRef.close();                    
                }, error: (err) => catchErr(err, this.snackBar)
            });
        });        
    }

    podeExibirAusencias(): boolean{        
        return this.isUsuarioTelaAferidor() && (this.currentRole === RolesEnum.GESTAO_ADMIN || this.currentRole === RolesEnum.COORDENADOR);
    }

    isUsuarioTelaAferidor(){
        if(this.usuario && this.usuario.id){
            return this.usuario.realm_access && this.usuario.realm_access.roles && this.usuario.realm_access.roles.includes(RolesEnum.AFERIDOR);
        }
        return false;        
    }
}
