import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Data, Pagination } from 'src/app/shared/models';
import { GenericCRUDService } from 'src/app/shared/services';
import { Equipamento } from './equipamento.model';

@Injectable({
    providedIn: 'root'
})
export class EquipamentoService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/equipamentos`;
    }

    override getPaginated<Equipamento>(page: number, pageSize: number, placa?: string): Observable<Pagination<Equipamento>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);
        placa && params.append('placa', `${placa}`);

        return this.http.get<Pagination<Equipamento>>(`${this.url}?${params}`);
    }

    getAllByBeneficiaria(beneficiariaId: string) {
        const params = new URLSearchParams();
        params.append('removePagination', `true`);
        params.append('beneficiariaId', beneficiariaId);
        return this.http.get<Data<Equipamento>>(`${this.url}?${params}`);
    }

    getAlocacoesByEquipamento<T>(equipamentoId: string): Observable<T> {
        return this.http.get<T>(`${this.url}/${equipamentoId}/alocacoes`);
    }

}
