<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} alocação</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save()" #form="ngForm" novalidate>

                <!-- INICIO -->
                <mat-form-field class="col-12 col-md-4">
                    <mat-label>Inicio</mat-label>
                    <input matInput [matDatepicker]="pickerStart" name="inicioVigencia" #inicioVigencia="ngModel" autocomplete="off"
                        [(ngModel)]="alocacao.inicioVigencia" required>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart></mat-datepicker>
                    <mat-error *ngIf="showInputError(inicioVigencia, 'required')">Inicio da vigência é obrigatório</mat-error>
                </mat-form-field>

                <!-- FIM -->
                <mat-form-field class="col-12 col-md-4">
                    <mat-label>Fim</mat-label>
                    <input matInput [matDatepicker]="pickerEnd" name="fimVigencia" #fimVigencia="ngModel" autocomplete="off"
                        [(ngModel)]="alocacao.fimVigencia" required>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEnd></mat-datepicker>
                    <mat-error *ngIf="showInputError(fimVigencia, 'required')">Fim da vigência é obrigatório</mat-error>
                </mat-form-field>

                <!-- PREFIXO -->
                <mat-form-field class="col-12 col-md-4">
                    <mat-label>Prefixo</mat-label>
                    <input matInput name="codigo" #codigo="ngModel" (input)="verifyIfCodeAlreadyExists($event)" [(ngModel)]="alocacao.codigo" [disabled]="!alocacao.equipamento" required>
                    <mat-error *ngIf="showInputError(codigo, 'required')">Prefixo é obrigatório</mat-error>
                    <mat-hint class="text-danger" *ngIf="codigoNotAllowed">Esse Prefixo já está sendo utilizado por favor tente outro.</mat-hint>
                </mat-form-field>

                <!-- EQUIPAMENTO -->
                <div class="col-12" *ngIf="!showFormEquip">
                    <ng-select name="beneficiaria"  [items]="equipamentos" required
                        [placeholder]="!equipamentos.length && !alocacao.id ? 'Todos os equipamentos foram preenchidos' : 'Equipamento'" [searchFn]="searchFn"
                        [(ngModel)]="alocacao.equipamento" bindValue="id"
                        [loading]="loading.equipamentos" [compareWith]="compareWith" [addTag]="addEquipamento" (change)="setEquipamento($event)">
                        <!-- selected/list -->
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="d-flex align-items-center">
                                <span>{{ item.placa }}</span>
                                <div class="status-badge bg-success text-white mx-2 lh-lg">
                                    {{ item.grupo.tipo.sigla }}
                                </div>
                                <div class="fs-7 opacity-50">{{ item.marca }} {{ item.modelo }}</div>
                            </div>
                        </ng-template>
                        <!-- add item -->
                        <ng-template ng-tag-tmp let-search="searchTerm">
                            <div class="d-flex align-items-center pointer">
                                <mat-icon class="text-primary me-1">add_circle</mat-icon> 
                                <span class="text-primary me-1">Adicionar novo equipamento:</span>
                                <span>{{ search }}</span>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- FORM EQUIPAMENTO -->
                <ng-container *ngIf="showFormEquip">
                    <div class="col-12">
                        <hr class="border-gray mt-0">
                    </div>
                    <h2 class="fs-4 fw-light mb-3">Equipamento</h2>
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Marca</mat-label>
                        <input matInput name="marca" #marca="ngModel" [(ngModel)]="newEquipamento.marca">
                    </mat-form-field>
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Modelo</mat-label>
                        <input matInput name="modelo" #modelo="ngModel" [(ngModel)]="newEquipamento.modelo" required>
                        <mat-error *ngIf="showInputError(modelo, 'required')">Modelo é obrigatório</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Placa/Série</mat-label>
                        <input matInput name="placa" #placa="ngModel" [(ngModel)]="newEquipamento.placa" mask="AAA-AAAA" required>
                        <mat-error *ngIf="showInputError(placa, 'required')">Placa/Série é obrigatório</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-12 col-md-6">
                        <mat-label>Ano de Fabricação</mat-label>
                        <input matInput name="fabricacaoAno" #fabricacaoAno="ngModel" [(ngModel)]="newEquipamento.fabricacaoAno" mask="0000">
                    </mat-form-field>
                    <mat-form-field class="col-12">
                        <mat-label>Grupo</mat-label>
                        <mat-select name="grupo" #grupo="ngModel" [(ngModel)]="newEquipamento.grupo" required>
                            <mat-option *ngFor="let grupo of grupos" [value]="grupo.id">
                                {{ grupo.tipo.sigla }} - {{ grupo.nome }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="showInputError(grupo, 'required')">Grupo é obrigatório</mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-12">
                        <mat-label>Informações Adicionais</mat-label>
                        <textarea matInput name="descricao" #descricao="ngModel" [(ngModel)]="newEquipamento.descricao"></textarea>
                    </mat-form-field>
                </ng-container>

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                <div class="col-12 col-md-auto" *ngIf="alocacao.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4" (click)="showRemoveDialog()" [disabled]="!alocacao.ativa">INATIVAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" [routerLink]="['/contrato', alocacao.contrato.id, 'visualizar']"
                        [queryParams]="{ tab:'alocacao' }">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save || codigoNotAllowed">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
