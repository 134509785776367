import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from 'src/app/shared/components';
import { catchErr, convertFromBase64, showInputError } from 'src/app/shared/utils';
import { UsuarioService, UsuarioResponse, Ausencia } from '..';
import { Observable } from 'rxjs';
import { AuthService, RolesEnum, RolesInfo } from 'src/app/core';
import { UsuarioAusenciaService } from '../usuario-ausencia.service';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-form-usuario-ausencia',
    templateUrl: './form-ausencia.component.html'
})
export class FormUsuarioAusenciaComponent implements OnInit {

    loading = { main: false, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    usuario = {} as UsuarioResponse;
    ausencia = {} as Ausencia;
    currentRole: string;

    rolesInfo = RolesInfo;
    showInputError = showInputError;
    
    constructor(
        private activatedRoute: ActivatedRoute,
        private usuarioService: UsuarioService,
        private usuarioAusenciaService: UsuarioAusenciaService,
        private router: Router,
        private authService: AuthService,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.loading.main = true;
        this.usuario.id = this.activatedRoute.snapshot.params['id'];
        if (this.usuario.id) {
            this.breadcrumb = [
                { label: 'Início' },
                { label: 'Usuários', route: '/usuario' },
                { label: 'Visualizar usuário', route: `/usuario/${this.usuario.id}/visualizar` },
                { label: 'Nova ausência' },
            ]

            this.usuarioService.getById<UsuarioResponse>(this.usuario.id).subscribe({
                next: (res) => this.usuario = res,
                error: (err) => catchErr(err, this.snackBar)
            }).add(() => this.loading.main = false);
        }
    }

    compareWith(item: any, selected: any) {
        return item.value == selected
    }

    save() {
        this.loading.save = true;
        let request: Observable<any>

        const ausenciaToSave = { ...this.ausencia }
        ausenciaToSave.dataInicio = ausenciaToSave.dataInicio ? `${dayjs(ausenciaToSave.dataInicio).format('YYYY-MM-DD')}` : ausenciaToSave.dataInicio;
        ausenciaToSave.dataFim = ausenciaToSave.dataFim ? `${dayjs(ausenciaToSave.dataFim).format('YYYY-MM-DD')}` : ausenciaToSave.dataFim;
        ausenciaToSave.usuario = this.usuario.id || '';
        ausenciaToSave.responsavelCadastro = this.authService.user.name + ' (' + this.authService.user.email + ')';

        request = ausenciaToSave.id 
            ? this.usuarioAusenciaService.update<Ausencia>(ausenciaToSave.id, ausenciaToSave)
            : this.usuarioAusenciaService.create<Ausencia>(ausenciaToSave)

        request.subscribe({
            next: () => {
                this.snackBar.open('Ausência cadastrada com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(['/usuario', this.usuario.id, 'visualizar']);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    isFuncionario(): boolean {
        return this.usuario && this.usuario.role === RolesEnum.FUNCIONARIO;
    }

    back() {
        const previewPageBase64 = this.activatedRoute.snapshot.queryParams['previewsPage'];
        this.router.navigateByUrl(previewPageBase64 ? convertFromBase64(previewPageBase64) : `/usuario/${this.usuario.id}/visualizar`);
    }

}

