import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../shared/components';
import { RolesEnum } from '../core';

@Component({
    selector: 'app-config',
    templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit {

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Configurações' },
    ]

    filteredRelatorios: any [];
    relatorios: any = [
        { titulo: 'Configurações do Sistema', routerLink: 'configuracoes-sistema', descricao: 'Relatório de diário de operações não executados por contratos.', skipRoles: [RolesEnum.EMISSOR] },
        { titulo: 'Regras de Cortes de Aferição', routerLink: 'regras-cortes-afericoes', descricao: 'Relatório de consumo por equipamento', skipRoles: [RolesEnum.EMISSOR] },        
    ]

    ngOnInit() {
        this.filteredRelatorios = this.relatorios;
    }    

    deveExibirRelatorio(skipRoles: RolesEnum[]) {
        return true;
    }
}
