import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericCRUDService } from 'src/app/shared/services';
import { FinishAfericaoDto } from './view-dio/update-aferidor/finish-afericao.dto';
import { environment } from 'src/environments/environment';
import { AprovarDto } from './aferir-dio/aprovar.dto';
import { Aferidores } from './dio.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AferidorService extends GenericCRUDService {
  
    constructor(http: HttpClient) {
        super(http);
        this.url = `/aferidores`;
    }

    findAferidorById(id: string): Observable<Aferidores> {
        return this.http.get<any>(`${this.url}/${id}`);
    }

    findAferidorByNumero(numero: number) {        
        return this.http.get<any>(`${this.url}/${numero}/numero`);
    }

}
