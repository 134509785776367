export enum StatusExecucaoDIO {
    AGUARDANDO_EXECUCAO = 'AGUARDANDO_EXECUCAO',
    EM_EXECUCAO = 'EM_EXECUCAO',
    AGUARDANDO_FUNCIONARIO = 'AGUARDANDO_FUNCIONARIO',
    AGUARDANDO_FISCAL = 'AGUARDANDO_FISCAL',
    EXECUTADA = 'EXECUTADA',
    EXPIRADA = 'EXPIRADA',
    CANCELADA = 'CANCELADA',
}

export enum StatusAfericaoDIO {
    AGUARDANDO_AFERICAO = 'AGUARDANDO_AFERICAO',
    AFERIDA_COM_APONTAMENTOS = 'AFERIDA_COM_APONTAMENTOS',
    AFERIDA_SEM_RESSALVAS = 'AFERIDA_SEM_RESSALVAS',
    AGUARDANDO_APROVACAO_AFERICAO = 'AGUARDANDO_APROVACAO_AFERICAO',
    AFERIDA = 'AFERIDA',
}

export enum StatusConclusaoDIO {
    FINALIZANDO_ASSINADA = 'FINALIZANDO_ASSINADA',
    FINALIZANDO_RELATADA = 'FINALIZANDO_RELATADA',
    FINALIZADA_COM_DESVIO = 'FINALIZADA_COM_DESVIO',
    FINALIZADA = 'FINALIZADA',
}

export enum DIOPrecificacaoTipoEnum {
    PROVISORIA = 'PROVISORIA',
    DEFINITIVA = 'DEFINITIVA',
}

export interface Demandante {
    id?: string;
    nome: string;
    email: string;
    telefone?: string;
    descricao?: string;
    regiaoAdm?: string;
}

export interface Aferidores {
  id?: string;
  nome: string;
  firstName: string;
  lastName: string;
  numero: number;
  telefone?: string;
  descricao?: string;
  regiaoAdm?: string;  
  ativo?: boolean;
}

export interface Dio {
    alocacao?: any;
    assinaturas: any[];
    afericao: any;
    condutor?: any;
    contrato: any;
    criadorEmail: string;
    criadorNome: string;
    criadorId: string;
    created_at?: string;
    dataFimExecucao?: Date;
    dataInicioExecucao?: Date;
    dataLimiteExecucao?: Date | string;
    dataPeriodoInicioOperacao?: Date;
    dataPeriodoFimOperacao?: Date;
    dataFimOperacao?: Date;
    dataDisponibilizacao?: Date;
    demandante: any;
    endereco?: any;
    execucaoDetalhes: DioDetalhesExecucao[] | undefined;
    execucaoDetalhesValida: DioDetalhesExecucao | undefined;
    funcionarioResponsavelOrgao?: string;
    horimetroFinal?: number;
    horimetroInicial?: number;
    hodometroFinal?: number;
    hodometroInicial?: number;
    id?: string;
    localApresentacao?: any;
    motivoDesvio: string;
    numero?: string;
    orgao?: any;
    polo?: any;
    precificacoes?: any[];
    precessoSei?: string;
    regiaoAdm?: any;
    sei?: string;
    servico?: any;
    statusAfericao: StatusAfericaoDIO;
    statusConclusao: StatusConclusaoDIO;
    statusExecucao?: StatusExecucaoDIO;
}

export interface DioDetalhesExecucao {
    created_at: string;
    disponibilidadeTotalMinutes?: number
    funcionamentoTotalMinutes?: number
    kmRodados?: number
    imagens?: any;
    descricao?: string;
    dio: Dio;
    contrato: string;
    prefixo: string;
    placa: string;
    grupo: string;
    kmReal: number;
    valorKm: number;
    numeroDio: string;
    dataInicioExecucao: string;
    tipo: string;
}

export interface ExecucaoDetalhes {
    created_at: string;
    descricao: string;
    id: string;
    key: string;
    name: string;
    url: string;
}

export interface DioFilters {
    sei: string;
    statusExecucao: StatusExecucaoDIO | undefined,
    statusAfericao: StatusAfericaoDIO | undefined,
    orgao: string | undefined;
    demandante: string | undefined;
    condutor: string;
    contrato: string;
    beneficiaria: string;
    funcionario: string;
    equipamento: string;
    alocacao: string;
    atrasados: string;
    startDataPeriodoInicioOperacao: string;
    endDataPeriodoInicioOperacao: string;
    startDateLimite: string;
    endDateLimite: string;
    startCreatedAt: string;
    endCreatedAt: string;
    numero?: string;
    aferidor?: string;
    isAferida?: boolean;
    orderBy?: string;
}

export interface MonitoramentoGeolocalizacao {
    _id: string;
    geolocalizacao: string;

    alocacao_id?: string;
    bateria?: string;
    codigo_alocacao?: string;
    contrato_id?: string;
    data_carregamento?: string;
    data_transmissao?: string;
    descricao?: string;
    distancia_granular?: number;
    equipamento_id?: string;
    frota?: string;
    geolocalizacao_id?: string;
    grupo_nome?: string;
    horimetro?: string;
    ignicao?: boolean;
    logradouro?: string
    marca?: string;
    modelo?: string;
    odometro?: number;
    placa?: string;
    velocidade?: number;

    marker?: google.maps.Marker;
}

export interface DIOPrecificacao {
    tipoPrecificacao: DIOPrecificacaoTipoEnum;
    valorTotalDisponibilidadeMaquina: number;
    valorTotalDisponibilidadeVeiculo: number;
    valorTotalProduzido: number;
    valorTotalKmRodado: number;
    precificacaoManual: boolean;
}

export interface DIOCancelamento {
    id: string;
    tipo: string;
    motivo: string;
    created_at: string;
    updated_at: string;
}

export interface DioEventos {
    id: string;
    created_at: string;
    tipoEvento: string;
    statusExecucaoAtual: string;
    statusExecucaoAnterior: string;
    statusConclusaoAnterior: string;
    statusConclusaoAtual: string;
    statusAfericaoAnterior: string;
    statusAfericaoAtual: string;
    usuarioNome: string;
    dioId: string;
}

export const statusExecucao = [
    {
        value: StatusExecucaoDIO.AGUARDANDO_EXECUCAO,
        label: 'Aguardando Execução',
    },
    {
        value: StatusExecucaoDIO.EM_EXECUCAO,
        label: 'Em Execução',
    },
    {
        value: StatusExecucaoDIO.AGUARDANDO_FUNCIONARIO,
        label: 'Aguardando Funcionário',
    },
    {
        value: StatusExecucaoDIO.AGUARDANDO_FISCAL,
        label: 'Aguardando Fiscal',
    },
    {
        value: StatusExecucaoDIO.EXECUTADA,
        label: 'Executada',
    },
    {
        value: StatusExecucaoDIO.EXPIRADA,
        label: 'Expirada',
    },
    {
        value: StatusExecucaoDIO.CANCELADA,
        label: 'Cancelada',
    }
]

export const statusConclusao = [
    {
        value: StatusConclusaoDIO.FINALIZADA,
        label: 'Finalizada',
    },
    {
        value: StatusConclusaoDIO.FINALIZADA_COM_DESVIO,
        label: 'Finalizada com desvio',
    },
    {
        value: StatusConclusaoDIO.FINALIZANDO_RELATADA,
        label: 'Finalizando Relatada',
    },
    {
        value: StatusConclusaoDIO.FINALIZANDO_ASSINADA,
        label: 'Finalizando Assinada',
    }
]

export const statusAfericao = [
    {
        value: StatusAfericaoDIO.AGUARDANDO_AFERICAO,
        label: 'Aguardando Aferição',
    },
    {
        value: StatusAfericaoDIO.AFERIDA_SEM_RESSALVAS,
        label: 'Aferida e Aceita Integralmente',
    },
    {
        value: StatusAfericaoDIO.AFERIDA_COM_APONTAMENTOS,
        label: 'Aferida com apontamentos',
    },   
]

export const statusExecucaoDictionary = () => {
    return statusExecucao.reduce((prev, cur) => {
        if (cur) {
            return {
                ...prev,
                [cur.value]: cur.label
            }
        }

        return prev;
    }, {})
}

export const statusAfericaoDictionary = () => {
    return statusAfericao.reduce((prev, cur) => {
        if (cur) {
            return {
                ...prev,
                [cur.value]: cur.label
            }
        }

        return prev;
    }, {})
}

export const statusConclusaoDictionary = () => {
    return statusConclusao.reduce((prev, cur) => {
        if (cur) {
            return {
                ...prev,
                [cur.value]: cur.label
            }
        }

        return prev;
    }, {})
}
