import { Pipe, PipeTransform } from '@angular/core';
import { toZonedTime, format } from 'date-fns-tz';

@Pipe({
  name: 'utcHoraMinuto'
})
export class UtcHoraMinutoPipe implements PipeTransform {

  transform(value: string | Date | undefined): string {    
    if(!value){
        return ''
    }

    console.log('value: ', value);

    // Converter para o fuso horário desejado (se necessário)
    const timeZone = 'UTC'; // Ou outro fuso horário, como 'America/Sao_Paulo'
    const zonedDate = toZonedTime(value, timeZone);

    console.log('zonedDate: ', zonedDate);

    // Formatar a data
    const formattedDate = format(zonedDate, 'HH:mmXXX', { timeZone });

    return formattedDate;
  }

}
