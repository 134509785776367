import { Component, OnInit } from '@angular/core';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { Beneficiaria } from '../beneficiaria.model';
import { catchErr, convertFromBase64, convertToBase64, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { BeneficiariaService } from '../beneficiaria.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject, catchError, concat, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { UsuarioResponse, UsuarioService } from 'src/app/usuario';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageService } from 'src/app/shared/services';
import { StoragePageEnum } from 'src/app/shared/models';
import { RolesEnum } from 'src/app/core';
import { Location } from '@angular/common';

@Component({
    selector: 'app-form-beneficiaria',
    templateUrl: './form-beneficiaria.component.html'
})
export class FormBeneficiariaComponent implements OnInit {

    loading: any = { main: false, save: false, responsaveis: false };
    breadcrumb: Breadcrumb[];

    responsaveis$: Observable<UsuarioResponse[]>;
    responsaveisInput$ = new Subject<string>();

    title: string;
    beneficiaria = {} as Beneficiaria;
    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private beneficiariaService: BeneficiariaService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private usuarioService: UsuarioService,
        private snackBar: MatSnackBar,
        private storageService: StorageService,
        private location: Location,
    ) { }

    ngOnInit() {
        const storage = this.storageService.storage[StoragePageEnum.FormBeneficiaria];
        if (storage && storage.beneficiaria) {
            this.beneficiaria = { ...storage.beneficiaria, [storage.returnObj]: storage[storage.returnObj] };
            this.storageService.remove(StoragePageEnum.FormBeneficiaria);
        }

        this.getUsuarios();

        this.beneficiaria.id = this.activatedRoute.snapshot.params['idBeneficiaria'];
        this.title = this.beneficiaria.id ? 'Editar' : 'Nova';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Beneficiárias', route: '/beneficiaria' },
            { label: this.title + ' beneficiaria' },
        ]
        if (this.beneficiaria.id) {
            this.loading.main = true;
            this.beneficiariaService.getById<Beneficiaria>(this.beneficiaria.id).subscribe({
                next: (res) => this.beneficiaria = res,
                error: (err) => catchErr(err, this.snackBar)
            }).add(() => this.loading.main = false);
        }
    }

    compareWith(item: Beneficiaria, selected: Beneficiaria) {
        return item.id == selected.id
    }

    routeWithStorage(route: string) {
        const pathBase64 = convertToBase64(this.location.path());

        this.storageService.set(StoragePageEnum.FormBeneficiaria, {
            beneficiaria: this.beneficiaria,
            contexto: 'beneficiaria',
            returnObj: 'responsaveis',
            isArray: true
        });

        this.router.navigate([route], { queryParams: { previewsPage: pathBase64 } });
    }

    save() {
        this.loading.save = true;
        let request: Observable<any>

        const responsaveis = this.beneficiaria?.responsaveis.map((responsavel) => {
            if (typeof responsavel !== 'object') return responsavel;
            return responsavel.id;
        });

        request = this.beneficiaria.id
            ? this.beneficiariaService.update<Partial<Beneficiaria>>(this.beneficiaria.id, {
                descricao: this.beneficiaria?.descricao,
                nome: this.beneficiaria?.nome,
                cnpj: this.beneficiaria?.cnpj,
                responsaveis: responsaveis,
            })
            : this.beneficiariaService.create<Beneficiaria>({
                ...this.beneficiaria,
                responsaveis,
            })

        request.subscribe({
            next: (res) => {
                // CHECK IS HAS STORAGE REDIRECT
                const storage = this.storageService.storage[StoragePageEnum.FormContrato] || this.storageService.storage[StoragePageEnum.FormCondutor];
                if (storage) {
                    const returnObj = this.activatedRoute.snapshot.queryParams['returnObj'];
                    const isArray = !!this.activatedRoute.snapshot.queryParams['isArray'];

                    storage.contrato && this.storageBeneficiaria(res, storage, returnObj, StoragePageEnum.FormContrato, 'contrato', '/contrato/novo');

                    // TODO REVIEW
                    storage.condutor && this.storageBeneficiaria(res, storage, returnObj, StoragePageEnum.FormCondutor, 'condutor', '/condutor/novo');
                    // /beneficiaria/37e02219-7882-4086-b603-3535addb6ce4/condutor/novo
                } else {
                    this.router.navigateByUrl('/beneficiaria');
                }

                this.snackBar.open('Beneficiaria salva com sucesso!', '✕', { panelClass: ['success'] });
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.beneficiaria.nome, (dialogRef: MatDialogRef<any>) => {
            this.beneficiariaService.remove<Beneficiaria>(this.beneficiaria.id).subscribe({
                next: () => {
                    this.snackBar.open('Beneficiaria apagada com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigateByUrl('/beneficiaria');
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }

    removeResponsavel(responsavel: any) {
        this.beneficiariaService.removeResponsavel(responsavel.id).subscribe();
    }

    private getUsuarios() {
        this.responsaveis$ = concat(
            of([]), // default items
            this.responsaveisInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.loading.responsaveis = true),
                switchMap((term) => {
                    return term ?
                        this.usuarioService.getPaginated<UsuarioResponse>(0, 20, term).pipe(
                            map((res) => {
                                return res.data.filter((usuario) => usuario.role === RolesEnum.CONTRATADO_ADM);
                            }), // select by attr
                            catchError(() => of([])), // empty list on error
                            tap(() => this.loading.responsaveis = false)
                        ) : new Promise(() => this.loading.responsaveis = false) // not search all
                })
            )
        ) as Observable<UsuarioResponse[]>;
    }

    private storageBeneficiaria(beneficiaria: Beneficiaria, storage: any, returnObj: any, storageKey: string, objKey: string, route: string) {
        const obj = { ...storage[objKey], [returnObj]: beneficiaria };
        this.storageService.set(storageKey, { [objKey]: obj })
        this.router.navigateByUrl(route);
    }

    back() {
        const previewPageBase64 = this.activatedRoute.snapshot.queryParams['previewsPage'];
        this.router.navigateByUrl(previewPageBase64 ? convertFromBase64(previewPageBase64) : '/beneficiaria');
    }

}
