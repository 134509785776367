import { Component, OnInit } from '@angular/core';
import { RegiaoAdmin } from '../localidade.model';
import { GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { Pagination } from 'src/app/shared/models';
import { RegiaoAdminService } from './regiao-admin.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr } from 'src/app/shared/utils';

@Component({
    selector: 'regiao-admin',
    templateUrl: './regiao-admin.component.html',
})
export class RegiaoAdminComponent implements OnInit {

    loading = true;
    filter: string;
    dataSource: RegiaoAdmin[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Nome', attr: 'nome' },
        { header: 'Informações Adicionais', attr: 'descricao' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: { action: (rowData: RegiaoAdmin) => this.router.navigateByUrl(`/localidade/regiao-admin/${rowData.id}/editar`)}
        }
    ]

    constructor(
        private regiaoAdminService: RegiaoAdminService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize
        this.regiaoAdminService.getPaginated<RegiaoAdmin>(this.pagination.current, this.pagination.pageSize, this.filter).subscribe({
            next: (res: Pagination<RegiaoAdmin>) => {
                this.dataSource = res.data.map((regiaoAdmin) => ({ ...regiaoAdmin }));
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
