import { Component, OnInit } from '@angular/core';
import { GenericRelatorioComponent } from '../generic-relatorio.component';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AfericaoService } from 'src/app/dio';
import { ContratoService } from 'src/app/contrato';

@Component({
  selector: 'app-relatorio-afericoes-aferidor',
  templateUrl: './relatorio-afericoes-aferidor.component.html',
  styleUrls: ['./relatorio-afericoes-aferidor.component.css']
})
export class RelatorioAfericoesAferidorComponent extends GenericRelatorioComponent implements OnInit {

  breadcrumb: Breadcrumb[] = [
    { label: 'Início' },
    { label: 'Relatórios', route: '/relatorio' },
    { label: 'Relatório DIO\'s aferidas' },
  ]

  dataSource: any[];
  pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 };
  columns: GenerateTableColumn[] = [
    { header: 'Aferidor', attr: 'aferidor_nome' },
    { header: 'Quantidade Aferições', attr: 'quantidadeAfericoes' },    
];

  constructor(
    private afericaoService: AfericaoService,    
    protected override contratoService: ContratoService,
    protected override snackBar: MatSnackBar,
  ) {
      super(contratoService, snackBar);
  }

  ngOnInit(): void {
    this.afericaoService.findAfericaoAferidores().subscribe({
      next: (res) => this.dataSource = res,
      error: (err) => this.snackBar.open('Erro ao carregar aferidores', 'OK', { duration: 5000 })
    }).add(() => this.loading = false);
  }

  search(pageEvent?: GenerateTablePageEvent) {
  }

  export(type: string = 'csv') {

  }

}
