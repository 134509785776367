export * from './localidade.model';
export * from './localidade.component';

export * from './regiao-admin/regiao-admin.component';
export * from './regiao-admin/regiao-admin.service';
export * from './regiao-admin/form-regiao-admin/form-regiao-admin.component';

export * from './orgao/orgao.model';
export * from './orgao/orgao.service';
export * from './orgao/orgao.component';
export * from './orgao/form-orgao/form-orgao.component';

export * from './orgao/view-orgao/view-orgao.component';
export * from './orgao/view-orgao/detail-orgao/detail-orgao.component';

export * from './orgao/view-orgao/funcionario/funcionario.service';
export * from './orgao/view-orgao/funcionario/funcionario.component';
export * from './orgao/view-orgao/funcionario/form-funcionario/form-funcionario.component';

export * from './orgao/view-orgao/locais-apresentacao/locais-apresentacao.service';
export * from './orgao/view-orgao/locais-apresentacao/locais-apresentacao.component';
export * from './orgao/view-orgao/locais-apresentacao/form-locais-apresentacao/form-locais-apresentacao.component';