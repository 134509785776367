import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { ConfigComponent, FormConfigComponent, ConfigService } from "./";
import { RegrasCortesAfericaoComponent } from "./regras-cortes-afericao/regras-cortes-afericao.component";
import { FormRegrasCortesAfericaoComponent } from "./regras-cortes-afericao/form-regras-cortes-afericao/form-regras-cortes-afericao.component";
import { SystemConfigComponent } from "./system-configs/system-config.component";

@NgModule({
    declarations: [
        ConfigComponent,
        FormConfigComponent,
        SystemConfigComponent,
        RegrasCortesAfericaoComponent,
        FormRegrasCortesAfericaoComponent,
    ],
    imports: [SharedModule],
    providers: [ConfigService]
})
export class ConfigModule { }