import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../shared/components';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-localidade',
    templateUrl: './localidade.component.html',
})
export class LocalidadeComponent implements OnInit {

    selectedTab: number;
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Localidades' },
    ];

    constructor(private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.selectedTab = this.getTab(this.activatedRoute.snapshot.queryParams['tab'])
    }

    private getTab(tabName: string) {
        switch (tabName) {
            case 'regiao-admin': return 1;
            default: return 0;
        }
    }
}
