import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pagination } from 'src/app/shared/models';
import { GenericCRUDService } from 'src/app/shared/services';

@Injectable({
    providedIn: 'root'
})
export class EquipamentoTipoService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/equipamentos-tipo`;
    }

    override create<EquipamentoTipo>(object: EquipamentoTipo): Observable<EquipamentoTipo> {
        const form = this.getFormData(object);
        return this.http.post<EquipamentoTipo>(`${this.url}`, form);
    }

    override update<EquipamentoTipo>(id: string, object: EquipamentoTipo): Observable<EquipamentoTipo> {
        const form = this.getFormData(object);
        return this.http.patch<EquipamentoTipo>(`${this.url}/${id}`, form);
    }

    removeImage(idImg: string): Observable<any> {
        return this.http.delete(`${this.url}/imagem/${idImg}`);
    }

    override getPaginated<EquipamentoTipo>(page: number, pageSize: number, nome?: string): Observable<Pagination<EquipamentoTipo>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);
        nome && params.append('nome', `${nome}`);
        return this.http.get<Pagination<EquipamentoTipo>>(`${this.url}?${params}`);
    }

    private getFormData(object: any) {
        const formData: FormData = new FormData();
        for (const key in object) {
            if (Array.isArray(object[key])) {
                for (const imgKey in object[key]) {
                    formData.append(key, object[key][imgKey]);
                }
            } else {
                formData.append(key, object[key]);
            }
        }
        return formData;
    }


}
