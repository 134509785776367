<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content [ngClass]="{'pb-0': !dataSource}">
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light mb-0">Faturamentos</h1>
                <button mat-flat-button color="primary" class="ms-auto" [appOpenInNewTab]="['faturamentos', 'novo']">NOVO FATURAMENTO</button>
            </div>
            <generate-table 
                [dataSource]="dataSource" [columns]="columns" [loading]="loading.faturamentos"
                [total]="pagination.total" [pageSize]="pagination.pageSize" 
                (pageEvent)="search($event)"
            ></generate-table>
        </mat-card-content>
    </mat-card>
</div>
