import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService, RolesEnum } from '../core';
import { GenericCRUDService } from '../shared/services';
import { Pagination } from '../shared/models';
import { UsuarioRequest } from './usuario.model';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService extends GenericCRUDService {

    profileURL = `${environment.managerURL}/users`
    aferidorURL = `${environment.managerURL}/aferidores`

    constructor(
        http: HttpClient, 
        private authService: AuthService
    ) {
        super(http);
        this.url = `/admin/users`;
    }

    override getPaginated<UsuarioResponse>(page: number, pageSize: number, byNameOrEmail?: string | null, role?: RolesEnum): Observable<Pagination<UsuarioResponse>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);        
        
        if (role) {
            byNameOrEmail && params.append('byNameOrEmail', `${byNameOrEmail}`);
            params.append('enabled', 'true');
            params.append('extraFields', `role:${role}`);
        } else {
            byNameOrEmail && params.append('byNameOrEmail', `${byNameOrEmail}`);
        }

        return this.http.get<Pagination<UsuarioResponse>>(`${this.url}?${params}`);
    }

    override update<UsuarioResponse>(id: string, usuario: UsuarioResponse): Observable<UsuarioResponse> {
        return this.http.put<UsuarioResponse>(`${this.url}/${id}`, usuario);
    }

    getProfile(): Observable<any> {
        return this.http.get(`${this.profileURL}/me`);
    }

    updateProfile(usuario: UsuarioRequest): Observable<any> {
        return this.http.put(`${this.profileURL}`, usuario);
    }

    updatePassword(usuarioId: string, password: string): Observable<any> {
        return this.http.put(`${this.profileURL}/change-password`, { usuarioId, password });
    }

    updateAvatar(file: any): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('avatar', file);
        return this.http.put(`${this.profileURL}/avatar`, formData)
    }

    createAferidorOnManager(userId: string, numero: number): Observable<any> {
      const data = { userId, numero };
      return this.http.post(`${this.aferidorURL}`, data)
    }

    sendResetPasswordEmail(userId: string) {                        
        return this.http.put(`${this.profileURL}/reset-password`, { usuarioId : userId });
    }
}
