<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content [ngClass]="{'pb-0': !dataSource}">
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light mb-0">Novo Faturamento</h1>
            </div>

            <!-- FILTROS -->
            <form class="border-top pt-3" name="form" (ngSubmit)="form.valid && search()" #form="ngForm" novalidate>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <ng-select name="contrato" [items]="contratos" bindValue="id" placeholder="Contrato"
                            [(ngModel)]="filters.contratoId" [loading]="loading.contratos" [disabled]="loading.contratos" 
                            [searchFn]="searchFn" [compareWith]="compareWith" [clearable]="false" required>
                            <!-- selected -->
                            <ng-template ng-label-tmp let-item="item">
                                <span>{{ item.nome }}</span>
                                <span class="opacity-50" *ngIf="item.beneficiaria"> - {{ item.beneficiaria.nome }}</span>
                            </ng-template>

                            <!-- lista -->
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <div class="lh-sm mt-2">{{ item.nome }}</div>
                                <div class="fs-7 opacity-50">{{ item.beneficiaria ? item.beneficiaria.nome : '---' }}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                    <mat-form-field class="col-12 col-md">
                        <mat-label>Inicio</mat-label>
                        <input matInput [matDatepicker]="startAt" name="inicio" #inicio="ngModel" autocomplete="off"
                            [(ngModel)]="filters.startAt" required>
                        <mat-datepicker-toggle matIconSuffix [for]="startAt"></mat-datepicker-toggle>
                        <mat-datepicker #startAt></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="col-12 col-md">
                        <mat-label>Fim</mat-label>
                        <input matInput [matDatepicker]="endAt" name="fim" #fim="ngModel" autocomplete="off"
                            [(ngModel)]="filters.endAt" required>
                        <mat-datepicker-toggle matIconSuffix [for]="endAt"></mat-datepicker-toggle>
                        <mat-datepicker #endAt></mat-datepicker>
                    </mat-form-field>
                    <div class="col-12 col-md-auto">
                        <button mat-fab color="accent" type="submit" [disabled]="form.form.invalid || loading.contratos">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>

            <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>

            <div class="callout text-muted" *ngIf="dataSource && !dataSource.length && !loading.main">Nenhuma informação encontrada!</div>
            <ng-container *ngIf="dataSource && dataSource.length && !loading.main">
                <div class="container-fluid">
                    <div class="row mb-3">
                        <div class="col-12 col-md callout border-blue-300 pt-2">
                            <div class="row">
                                <div class="col-12 col-md">
                                    <span class="fw-bold fs-7 opacity-50">HORA DISP</span>
                                    <div class="lh-sm">{{ formatHumanTime(totalizadores.totalHorasDisponiveisEmMinutos) }}</div>
                                </div>
                                <div class="col-12 col-md">
                                    <span class="fw-bold fs-7 opacity-50">HORA TRAB</span>
                                    <div class="lh-sm">{{ formatHumanTime(totalizadores.totalHorasFuncionamentoEmMinutos) }}</div>
                                </div>
                                <div class="w-100 mb-1" *ngIf="showEdit"></div>
                                <div class="col-12 col-md">
                                    <span class="fw-bold fs-7 opacity-50">KM</span>
                                    <div class="lh-sm">{{ totalizadores.totalKmRodados }} km</div>
                                </div>
                                <div class="col-12 col-md" *ngIf="!showEdit">
                                    <span class="fw-bold fs-7 opacity-50">CUSTO</span>
                                    <div class="lh-sm">{{ totalizadores.custo | currency:'BRL' }}</div>
                                </div>
                                <div class="col-12 col-md">
                                    <span class="fw-bold fs-7 opacity-50">QTDE DIOs</span>
                                    <div class="lh-sm">{{ totalizadores.dios }}</div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="showEdit">
                            <div class="col-12 col-md-3 callout border-success pt-2 ms-3">
                                <div class="row">
                                    <div class="col-12 col-md-auto">
                                        <div class="fw-bold fs-7 mt-4 lh-base">VALOR BASE</div>
                                        <div class="fw-bold fs-7 lh-lg text-primary">AJUSTADO</div>
                                        <div class="fw-bold fs-7 lh-base text-success">ECONOMIA</div>
                                    </div>
                                    <div class="col-12 col-md-auto ps-4">
                                        <span class="fw-bold fs-7 opacity-50">CUSTO</span>
                                        <div class="lh-sm text-dark">{{ totalizadores.custo | currency:'BRL' }}</div>
                                        <div class="lh-sm text-primary">
                                            {{ totalizadores.valorReajustado ? (totalizadores.valorReajustado | currency:'BRL') : '---' }}
                                        </div>
                                        <div class="lh-base text-success">
                                            {{ totalizadores.diferenca ? (totalizadores.diferenca | currency:'BRL') : '---' }}
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </ng-container>
                    </div>
                </div>
    
                <!-- VISUALIZAR -->
                <generate-table [dataSource]="dataSource" [columns]="columns" *ngIf="!showEdit"
                    [expanded]="true" [expandedContent]="dioListContent" (openExpanded)="getDioByEquip($event, filters)"></generate-table>

                <!-- AJUSTAR -->
                <ng-container *ngIf="showEdit">
                    <generate-table [dataSource]="dataSource" [columns]="columnsEdit"></generate-table>
                    <div class="border-top pt-3">
                        <mat-form-field class="w-100">
                            <mat-label>Justificativa</mat-label>
                            <textarea matInput type="textarea" name="motivo" [(ngModel)]="motivo" required></textarea>
                        </mat-form-field>
                    </div>
                </ng-container>

                <div class="row g-2" [ngClass]="{'border-top pt-2 mt-0': !showEdit}">
                    <div class="col-12 col-md-auto ms-auto">
                        <button mat-button color="primary" (click)="showEdit = true" *ngIf="!showEdit">
                            AJUSTAR
                        </button>
                    </div>
                    <ng-container *ngIf="showEdit">
                        <div class="col-12 col-md-auto ms-auto">
                            <button mat-button color="warn" class="px-3" (click)="showEdit = false">
                                CANCELAR
                            </button>
                        </div>
                        <div class="col-12 col-md-2">
                            <button mat-flat-button color="primary" [ngClass]="loading.salvar || !motivo ? 'btn-dialog-spinner': 'bg-success'"
                                class="w-100"  (click)="criarFaturamento()" [disabled]="loading.salvar || !motivo">
                                <mat-spinner class="btn-spinner" *ngIf="loading.salvar"></mat-spinner>
                                <span *ngIf="!loading.salvar">FINALIZAR</span>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #dioListContent let-item>
    <div class="w-100 px-3 pt-2 pb-4">
        <mat-spinner class="mx-auto mt-4" *ngIf="loading.DIOs"></mat-spinner>
        <div class="border-start border-3 border-blue-300 shadow-sm" *ngIf="!loading.DIOs">
            <generate-table [dataSource]="dataSourceDIOs" [columns]="columnsDIO" trSize="sm"></generate-table>
        </div>
    </div>
</ng-template>
