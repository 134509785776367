import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { RolesEnum } from '../auth/roles';
import { DioService } from 'src/app/dio/dio.service';
import { StatusAfericaoDIO, StatusExecucaoDIO } from 'src/app/dio/dio.model';
import { Subscription } from 'rxjs';

interface Menu {
    name: string;
    icon: string;
    route: string;
    accessControl: RolesEnum[];
    fontSet: string,
    exact?: boolean,
    badge?: string;
    badgeStatus?: StatusExecucaoDIO | StatusAfericaoDIO;
}

interface Metric {
    status: StatusExecucaoDIO | StatusAfericaoDIO;
    total_por_status: string;
}

@Component({
    selector: '[sidebar]',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
    private subscription: Subscription;

    menus: Menu[] = [
        // { name: 'Início', icon: 'home', route: '/', exact: true, accessControl: RolesEnum.PADRAO, fontSet: 'material-icons'},
        {
            name: 'Pendentes de Atesto',
            icon: 'report_problem',
            route: '/pendencias/atesto',
            accessControl: [RolesEnum.GESTAO_ADMIN, RolesEnum.FUNCIONARIO],
            fontSet: 'material-icons-outlined',
            badgeStatus: StatusExecucaoDIO.AGUARDANDO_FUNCIONARIO
        }, {
            name: 'Pendentes de Validação',
            icon: 'checklist',
            route: '/pendencias/validacao',
            accessControl: [RolesEnum.GESTAO_ADMIN, RolesEnum.FISCAL],
            fontSet: 'material-icons',
            badgeStatus: StatusExecucaoDIO.AGUARDANDO_FISCAL
        }, {
            name: 'Pendentes de Aferição',
            icon: 'person_search',
            route: '/pendencias/afericao',
            accessControl: [RolesEnum.GESTAO_ADMIN, RolesEnum.AFERIDOR, RolesEnum.COORDENADOR],
            fontSet: 'material-icons-outlined',
            badgeStatus: StatusAfericaoDIO.AGUARDANDO_AFERICAO
        },
        {
            name: 'Pendente de Aprovação',
            icon: 'task_alt',
            route: '/pendencias/afericao-aprovacao',
            accessControl: [RolesEnum.GESTAO_ADMIN, RolesEnum.COORDENADOR],
            fontSet: 'material-icons-outlined',
            badgeStatus: StatusAfericaoDIO.AGUARDANDO_APROVACAO_AFERICAO
        }, {
            name: 'Aprovações Realizadas',
            icon: 'thumb_up',
            route: '/aprovacoes',
            accessControl: [RolesEnum.GESTAO_ADMIN, RolesEnum.COORDENADOR],
            fontSet: 'material-icons-outlined',            
        }, {
            name: 'DIOs',
            icon: 'task',
            route: '/dio',
            accessControl: [RolesEnum.GESTAO_ADMIN, RolesEnum.CONTRATADO_ADM, RolesEnum.FUNCIONARIO, RolesEnum.FISCAL, RolesEnum.EMISSOR],
            fontSet: 'material-icons',
        }, {
            name: 'Beneficiárias',
            icon: 'store',
            route: '/beneficiaria',
            accessControl: [RolesEnum.GESTAO_ADMIN],
            fontSet: 'material-icons'
        }, {
            name: 'Condutores',
            icon: 'local_shipping',
            route: '/condutores',
            accessControl: [RolesEnum.CONTRATADO_ADM],
            fontSet: 'material-icons'
        }, {
            name: 'Contratos',
            icon: 'description',
            route: '/contrato',
            accessControl: [RolesEnum.GESTAO_ADMIN],
            fontSet: 'material-icons'
        }, {
            name: 'Equipamentos',
            icon: 'construction',
            route: '/equipamento',
            accessControl: [RolesEnum.GESTAO_ADMIN],
            fontSet: 'material-icons'
        }, {
            name: 'Faturamentos',
            icon: 'request_quote',
            route: '/faturamentos',
            accessControl: [RolesEnum.GESTAO_ADMIN],
            fontSet: 'material-icons'
        }, {
            name: 'Localidades',
            icon: 'pin_drop',
            route: '/localidade',
            accessControl: [RolesEnum.GESTAO_ADMIN, RolesEnum.EMISSOR],
            fontSet: 'material-icons'
        }, {
            name: 'Relatorios',
            icon: 'file_copy',
            route: '/relatorio',
            accessControl: [RolesEnum.GESTAO_ADMIN, RolesEnum.EMISSOR],
            fontSet: 'material-icons'
        }, {
            name: 'Serviços',
            icon: 'design_services',
            route: '/servico',
            accessControl: [RolesEnum.GESTAO_ADMIN, RolesEnum.EMISSOR],
            fontSet: 'material-icons'
        }, {
            name: 'Usuários',
            icon: 'group',
            route: '/usuario',
            accessControl: [RolesEnum.GESTAO_ADMIN, RolesEnum.COORDENADOR],
            fontSet: 'material-icons'
        }, {
            name: 'Perfil',
            icon: 'account_circle',
            route: '/perfil',
            accessControl: [RolesEnum.CONTRATADO_ADM, RolesEnum.FUNCIONARIO, RolesEnum.FISCAL, RolesEnum.AFERIDOR, RolesEnum.COORDENADOR, RolesEnum.EMISSOR, RolesEnum.CONTRATADO_CONDUTOR, RolesEnum.PADRAO],
            fontSet: 'material-icons'
        }, {
            name: 'Configurações',
            icon: 'settings',
            route: '/configuracoes',
            accessControl: [RolesEnum.GESTAO_ADMIN],
            fontSet: 'material-icons'
        }
    ]

    constructor(public authService: AuthService, private dioService: DioService) { }

    ngOnInit() {
        this.getMetrics();

        var userRole = this.authService.user.realm_access ? this.authService.user.realm_access.roles[0] : '';        
        
        if(userRole !== RolesEnum.CONTRATADO_ADM){
            this.dioService.getMetricsEveryMinute().subscribe({
                next: (metrics) => this.setMenuByMetrics(metrics)
            })
            this.subscription = this.dioService.action$.subscribe(() => {
                this.getMetrics();
            });        
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getMetrics() {
        this.dioService.getMetricsByStatus().subscribe({
            next: (metrics) => this.setMenuByMetrics(metrics)
        })
    }

    setMenuByMetrics(metrics: any) {
        metrics.map((metric: Metric) => {
            this.menus.map((menu) => {
                menu.badge = menu.badgeStatus === metric.status ? metric.total_por_status : menu.badge
            })
        })
    }
}
