<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content>
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light mb-0">Relatório DIO's Aferidas</h1>
                <button mat-icon-button color="primary" class="d-print-none btn-sm ms-auto" (click)="toggleFilters = !toggleFilters">
                    <mat-icon>tune</mat-icon>
                </button>
                <button mat-flat-button class="d-print-none ms-3 bg-gray-400 ms-auto" (click)="print()">IMPRIMIR</button>
                <button mat-flat-button color="primary" class="d-print-none ms-3" [matMenuTriggerFor]="menu">EXPORTAR</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="export('pdf')">PDF</button>
                    <button mat-menu-item (click)="export('csv')">Excel</button>
                </mat-menu>
            </div>

            <!-- FILTROS -->
            <ng-collapse [toggle]="toggleFilters">
                <div class="border-top px-1 py-3">
                    <div class="row align-items-end">                                            
                        <div class="col-12 col-md-6">
                            <ng-select name="beneficiaria" [items]="beneficiarias" bindValue="id" placeholder="Beneficiaria" required
                                [searchFn]="searchFn" [(ngModel)]="filters.beneficiaria" [compareWith]="compareWith"
                                (change)="setBeneficiaria($event)">
                                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                                    {{ item.nome}}
                                </ng-template>
                            </ng-select>                        
                        </div>
                        <div class="col-12 col-md-6">
                            <ng-select name="contrato" [items]="contratos" bindValue="id" bindLabel="nome" placeholder="Contrato"
                            [searchFn]="searchFn" [(ngModel)]="filters.contrato" [compareWith]="compareWith">
                            </ng-select>
                        </div>
                        <div class="col-12 col-md-6">
                            <span class="fw-bold fs-7 opacity-50">PERÍODO AFERIÇÃO</span>
                            <div class="d-flex">
                                <mat-form-field class="me-2">
                                    <mat-label>Inicio</mat-label>
                                    <input matInput [matDatepicker]="dataInicio" name="inicio" #inicio="ngModel"
                                        autocomplete="off" [(ngModel)]="filters.dataInicio">
                                    <mat-datepicker-toggle matIconSuffix [for]="dataInicio"></mat-datepicker-toggle>
                                    <mat-datepicker #dataInicio></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Fim</mat-label>
                                    <input matInput [matDatepicker]="dataFim" name="fim" #fim="ngModel" autocomplete="off"
                                        [(ngModel)]="filters.dataFim">
                                    <mat-datepicker-toggle matIconSuffix [for]="dataFim"></mat-datepicker-toggle>
                                    <mat-datepicker #dataFim></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <mat-form-field class="col-12 col-md-6">
                            <input matInput name="prefixo" placeholder="Prefixo" [(ngModel)]="filters.prefixo" />
                        </mat-form-field>
                    </div>       
                    <div>            
                        <div class="col-12 col-md-2 ms-auto">
                            <button mat-flat-button color="accent" class="w-100" (click)="search()">FILTRAR</button>
                        </div>
                    </div>
                </div>
            </ng-collapse>

            <!-- TABLE -->
            <div class="overflow-x-auto">
                <generate-table [dataSource]="dataSource" [columns]="columns" [loading]="loading"
                    [total]="pagination.total" [pageSize]="pagination.pageSize"
                    (pageEvent)="search($event)"></generate-table>
            </div>
        </mat-card-content>
    </mat-card>
</div>