<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
            <h1 class="fs-3 fw-light mb-3">{{ title }} DIO</h1>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && preSave()" #form="ngForm"
                novalidate>
                <!-- CONTRATOS -->
                <div class="col-12 col-md-4">
                    <ng-select name="contrato" [items]="contratos" bindValue="id" placeholder="Contratos" required
                        [searchFn]="searchFn" [(ngModel)]="dio.contrato" [compareWith]="compareWith"
                        (change)="setContrato($event)">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                            {{ item.nome}}
                        </ng-template>
                    </ng-select>
                </div>

                <!-- ALOCACAO/EQUIPAMENTO -->
                <div class="col-12 col-md-4">
                    <ng-select [disabled]="!dio.contrato || loading.alocacao" name="alocacao" [items]="alocacoes"
                        bindValue="id" [loading]="loading.alocacao" placeholder="Prefixo/Placa ou Série/Equipamento"
                        [searchFn]="searchFn" [(ngModel)]="dio.alocacao" [compareWith]="compareWith" 
                        (change)="setAlocacao($event)" required>
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index"
                            let-search="searchTerm">
                            {{ item.codigo }} - {{ item.placa }} ({{ item.equipamento }})
                        </ng-template>
                    </ng-select>
                </div>

                <!-- CONDUTOR -->
                <div class="col-12 col-md-4">
                    <ng-select name="condutor" [disabled]="!dio.contrato || loading.condutor" [items]="condutores"
                        bindValue="id" [loading]="loading.condutor" placeholder="Condutor" [searchFn]="searchFn"
                        [(ngModel)]="dio.condutor" [compareWith]="compareWith">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index"
                            let-search="searchTerm">
                            {{ item.firstName }} {{ item.lastName }}
                        </ng-template>
                    </ng-select>
                </div>

                <!-- PROCESSO SEI -->
                <mat-form-field class="col-12 col-md-4">
                    <mat-label>Processo SEI</mat-label>
                    <input matInput name="precessoSei" #precessoSei="ngModel" [(ngModel)]="dio.precessoSei">
                </mat-form-field>

                <!-- SEI -->
                <mat-form-field class="col-12 col-md-4">
                    <mat-label>SEI</mat-label>
                    <input matInput name="sei" #sei="ngModel" [(ngModel)]="dio.sei">
                </mat-form-field>

                <!-- SERVICO -->
                <div class="col-12 col-md-4">
                    <ng-select name="servico" [items]="servicos" bindValue="id" placeholder="Serviço"
                        [searchFn]="searchFn" [(ngModel)]="dio.servico" [compareWith]="compareWith" required>
                        <ng-template ng-label-tmp let-item="item">
                            {{ item.titulo }}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            {{ item.titulo }}
                        </ng-template>
                    </ng-select>
                </div>

                <!-- ORGAO -->
                <div class="col-12 col-md-4">
                    <ng-select name="orgao" [items]="orgaos" bindValue="id" placeholder="Orgão" [searchFn]="searchFn"
                        [(ngModel)]="dio.orgao" [compareWith]="compareWith" (change)="setOrgao($event)"
                        (clear)="setOrgao()" required>
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                            {{ item.nome }}
                        </ng-template>
                    </ng-select>
                </div>

                <!-- LOCAIS APRESENTACAO -->
                <div class="col-12 col-md-4">
                    <ng-select name="localApresentacao" [disabled]="disableLocal" [items]="locaisApresentacao"
                        bindValue="id" placeholder="Locais de Apresentação" [searchFn]="searchFn"
                        [(ngModel)]="dio.localApresentacao" [compareWith]="compareWith" required>
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                            {{ item.nome }}
                        </ng-template>
                    </ng-select>
                </div>

                <!-- Funcionário -->
                <mat-form-field class="col-12 col-md-4">
                    <mat-label>Nome do Funcionário</mat-label>
                    <input matInput name="funcionarioResponsavelOrgao" #funcionarioResponsavelOrgao="ngModel" [(ngModel)]="dio.funcionarioResponsavelOrgao">
                </mat-form-field>

                <!-- REGIAO ADM -->                
                <div class="col-12 col-md-4">
                    <ng-select name="regiaoAdm" [disabled]="!!dio.orgao" [items]="regioesAdm" bindValue="id"
                        placeholder="Região Administrativa" [searchFn]="searchFn" [(ngModel)]="dio.regiaoAdm"
                        [compareWith]="compareWith" (change)="setRegiaoAdm($event)">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                            {{ item.nome }}
                        </ng-template>
                    </ng-select>
                </div>                

                <!-- POLO -->
                 <!--
                <div class="col-12 col-md-4">
                    <ng-select name="polo" [disabled]="!!dio.orgao || disablePolo" [items]="polos" bindValue="id"
                        placeholder="Polo" [searchFn]="searchFn" [(ngModel)]="dio.polo" [compareWith]="compareWith">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                            {{ item.nome }}
                        </ng-template>
                    </ng-select>
                </div>
                -->

                <!-- DEMANDANTE -->
                <div class="col-12 col-md-4" *ngIf="!showDemandanteForm">
                    <ng-select name="demandante" [items]="demandantes" bindValue="id" placeholder="Demandante"
                        [searchFn]="searchFn" [(ngModel)]="dio.demandante" [compareWith]="compareWith"
                        [addTag]="addDemandante">
                        <!-- lista -->
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                            {{ item.nome }}
                        </ng-template>
                        <!-- add item -->
                        <ng-template ng-tag-tmp let-search="searchTerm">
                            <div class="d-flex align-items-center pointer">
                                <mat-icon class="text-primary me-1">add_circle</mat-icon>
                                <span class="text-primary me-1">Adicionar novo demandante:</span>
                                <span>{{ search }}</span>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- DEMANDANTE FORM -->
                <ng-container *ngIf="showDemandanteForm">
                    <div class="col-12">
                        <hr class="border-gray mt-0 mb-2">
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-2">
                        <h1 class="fs-3 fw-light mb-0">Demandante</h1>
                        <button mat-icon-button type="button" color="accent" (click)="showDemandanteForm = false">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>

                    <!-- NOME -->
                    <mat-form-field class="col-12 col-md-3">
                        <mat-label>Nome</mat-label>
                        <input matInput name="nome" #nome="ngModel" [(ngModel)]="demandante.nome" required>
                        <mat-error *ngIf="showInputError(nome, 'required')">Nome é obrigatório</mat-error>
                    </mat-form-field>

                    <!-- EMAIL -->
                    <mat-form-field class="col-12 col-md-3">
                        <mat-label>Email</mat-label>
                        <input matInput name="email" #email="ngModel" [(ngModel)]="demandante.email">
                    </mat-form-field>

                    <!-- PHONE -->
                    <mat-form-field class="col-12 col-md-3">
                        <mat-label>Telefone</mat-label>
                        <input matInput name="telefone" #telefone="ngModel" [(ngModel)]="demandante.telefone"
                            mask="(00) 00000-0000">
                    </mat-form-field>

                    <!-- RA DEMANDANTE -->
                    <mat-form-field class="col-12 col-md-3">
                        <mat-label>Regiao Administrativa</mat-label>
                        <mat-select name="regiaoAdmDemandante" #regiaoAdmDemandante="ngModel"
                            [(ngModel)]="demandante.regiaoAdm">
                            <mat-option *ngFor="let regiaoAdm of regioesAdmDemandante" [value]="regiaoAdm.id">{{
                                regiaoAdm.nome }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- DESCRICAO -->
                    <mat-form-field class="col-12">
                        <mat-label>Informações Adicionais</mat-label>
                        <textarea matInput name="descricao" #descricao="ngModel"
                            [(ngModel)]="demandante.descricao"></textarea>
                    </mat-form-field>
                </ng-container>

                <!-- DATA INICIO PERIODO OPERACAO -->
                <div class="col-12 col-md-4">
                    <mat-form-field class="col-12">
                        <mat-label>Data Início Operação</mat-label>
                        <input matInput [matDatepicker]="pickerStart" name="dataPeriodoInicioOperacao" #dataPeriodoInicioOperacao="ngModel" autocomplete="off"
                            [(ngModel)]="dio.dataPeriodoInicioOperacao" (dateChange)="onDateChange($event)" required>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>                        
                    </mat-form-field>
                </div>

                <!-- DATA FIM PERIODO OPERACAO -->
                <div class="col-12 col-md-4">
                    <mat-form-field class="col-12">
                        <mat-label>Data Fim Operação</mat-label>
                        <input matInput [matDatepicker]="pickerEnd" name="dataPeriodoFimOperacao" #dataPeriodoFimOperacao="ngModel" autocomplete="off"
                            [(ngModel)]="dio.dataPeriodoFimOperacao" required>
                        <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                <div class="col-12 col-md-auto" *ngIf="dio.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4"
                        (click)="showRemoveDialog()">APAGAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4"
                        [routerLink]="dio.id ? ['/dio', dio.id, 'visualizar'] : ['/dio']">VOLTAR</button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100"
                        [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>