import { Injectable } from '@angular/core';
import { GenericCRUDService } from '../shared/services';
import { HttpClient } from '@angular/common/http';
import { Pagination } from '../shared/models';
import { Servico } from './servico.model';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})
export class ServicoService extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/servico`;
    }

    override getPaginated<Servico>(page: number, pageSize: number, titulo?: string): Observable<Pagination<Servico>> {
        console.log('titulo: ', titulo);
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);
        titulo && params.append('titulo', `${titulo}`);
        return this.http.get<Pagination<Servico>>(`${this.url}?${params}`);
    }
}
