import { Component, OnInit } from '@angular/core';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { Polo, RegiaoAdmin } from '../../localidade.model';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { RegiaoAdminService } from '../regiao-admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-form-regiao-admin',
    templateUrl: './form-regiao-admin.component.html',
})
export class FormRegiaoAdminComponent implements OnInit {

    loading: any = { main: true, save: false };
    breadcrumb: Breadcrumb[];

    title: string;
    regiaoAdmin = {} as RegiaoAdmin;
    showInputError = showInputError;

    constructor(
        private activatedRoute: ActivatedRoute,
        private regiaoAdminService: RegiaoAdminService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.regiaoAdmin.id = this.activatedRoute.snapshot.params['id'];
        this.title = this.regiaoAdmin.id ? 'Editar' : 'Nova';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Localidades', route: '/localidade', queryParams: { tab: 'regiao-admin' } },
            { label: this.title + ' região administrativa' },
        ]
        
        if(this.regiaoAdmin.id){
            this.getById();
        } else {
            this.loading.main = false;
        }
    }

    compareWith(item: any, selected: any) {
        return selected && item == selected.id
    }

    save() {
        this.loading.save = true;
        let request: Observable<RegiaoAdmin>

        const regiaoToSave = { ...this.regiaoAdmin }
        if (regiaoToSave.id) {
            request = this.regiaoAdminService.update<RegiaoAdmin>(regiaoToSave.id, regiaoToSave)
        } else {
            request = this.regiaoAdminService.create<RegiaoAdmin>(regiaoToSave)
        }

        request.subscribe({
            next: () => {
                this.snackBar.open('Região administrativa salva com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(['/localidade'], { queryParams: { tab: 'regiao-admin' } });
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.regiaoAdmin.nome, (dialogRef: MatDialogRef<any>) => {
            this.regiaoAdminService.remove<RegiaoAdmin>(this.regiaoAdmin.id).subscribe({
                next: () => {
                    this.snackBar.open('Região administrativa apagada com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigate(['/localidade'], { queryParams: { tab: 'regiao-admin' } });
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }

    private getById() {
        this.loading.main = true;
        this.regiaoAdminService.getById<RegiaoAdmin>(this.regiaoAdmin.id).subscribe({
            next: (res) => this.regiaoAdmin = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }
}
