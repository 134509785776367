<div class="p-4 d-print-none">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card class="p-4">
        <mat-spinner class="mx-auto my-5" *ngIf="loading"></mat-spinner>
        <ng-container *ngIf="!loading">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="d-flex align-items-center">
                    <h1 class="fs-3 fw-light mb-0"><strong>{{ dio.numero }}</strong> - {{ dio.servico.titulo }}</h1>
                </div>
                <div>
                    <button mat-icon-button color="primary" class="btn-sm" [matMenuTriggerFor]="beforeMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before" class="d-print-none">
                        <button mat-menu-item (click)="openAssociarCondutorDialog()" *ngIf="!dio.condutor">Associar Condutor</button>
                        <button mat-menu-item (click)="print()">Imprimir</button>
                        <button mat-menu-item (click)="openToSignDialog()" *ngIf="allowedSignatureDio()">
                            {{ dio.statusExecucao === 'AGUARDANDO_FUNCIONARIO' ? 'Atestar' : 'Validar' }}
                        </button>
                        <button mat-menu-item [routerLink]="['/dio', dio.id, 'editar']" *ngIf="allowedEditDio()">Editar</button>
                        <button mat-menu-item (click)="showCancelarDialog()" *ngIf="allowedToCancelDio()">Cancelar</button>
                    </mat-menu>       
                </div>
            </div>
            
            <!-- CRIAÇÃO -->
            <div class="callout border-primary pt-2 mb-4">
                <div class="row">
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">PREFIXO</span>
                        <div class="d-flex" *ngIf="dio.alocacao">
                            <span class="status-badge bg-info fs-7 lh-lg">{{ dio.alocacao.codigo }}</span>
                        </div>
                        <div class="lh-sm" *ngIf="!dio.alocacao">---</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">PLACA EQUIPAMENTO</span>
                        <div class="lh-sm">{{ dio.alocacao && dio.alocacao.equipamento ? dio.alocacao.equipamento.placa : '---' }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">ANO DO EQUIPAMENTO</span>
                        <div class="lh-sm">{{ dio.alocacao && dio.alocacao.equipamento && dio.alocacao.equipamento.fabricacaoAno ? (dio.alocacao.equipamento.fabricacaoAno | date:'yyyy') : '---' }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">EQUIPAMENTO</span>
                        <ng-container *ngIf=" dio.alocacao && dio.alocacao.equipamento">
                            <div class="lh-sm">{{ dio.alocacao.equipamento.modelo ? dio.alocacao.equipamento.modelo : '---' }}</div>
                            <div class="lh-sm fs-7 text-gray">{{ dio.alocacao.equipamento.marca ? dio.alocacao.equipamento.marca : '---' }}</div>
                        </ng-container>
                    </div>                    
                    <div class="col-12 mb-3">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">BENEFICIÁRIA</span>
                        <div class="lh-sm">{{ dio.contrato.beneficiaria.nome }}</div>
                        <div class="lh-sm fs-7 text-gray">{{ dio.contrato.beneficiaria.cnpj | mask:'CPF_CNPJ' }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">CONTRATO</span>
                        <div class="lh-sm">{{ dio.contrato.nome }}</div>
                        <div class="lh-sm fs-7 text-gray" *ngIf="dio.contrato.beneficiaria">{{ dio.contrato.beneficiaria.nome }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">CONDUTOR</span>
                        <div class="lh-sm">{{ dio.condutor ? dio.condutor.firstName : '---' }} {{ dio.condutor ? dio.condutor.lastName : '' }}</div>
                        <div class="lh-sm fs-7 text-gray">{{ dio.condutor ? dio.condutor.email : '---' }}</div>
                    </div>
                    <div class="col-12 mb-3">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">DEMANDANTE</span>
                        <div class="lh-sm">{{ dio.demandante ? dio.demandante.nome : '---' }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">SEI</span>
                        <div class="lh-sm">{{ dio.sei || '---' }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">PROCESSO SEI</span>
                        <div class="lh-sm">{{ dio.precessoSei || '---' }}</div>
                    </div>
                    <div class="col-12 mb-3">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">ORGÃO</span>
                        <div class="lh-sm">{{ dio.orgao ? dio.orgao.nome : '---' }}</div>
                        <div class="lh-sm fs-7 text-gray" *ngIf="dio.localApresentacao">{{ dio.localApresentacao.nome }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">REGIÃO</span>
                        <div class="lh-sm">{{ dio.regiaoAdm ? dio.regiaoAdm.nome : '---' }}</div>
                    </div>                    
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">EXECUTOR LOCAL</span>
                        <div class="lh-sm">{{ dio.funcionarioResponsavelOrgao || '---' }}</div>
                    </div>
                    <div class="col-12 mb-3">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="col-12 col-md-4 mb-3 mb-md-0">
                        <span class="fw-bold fs-7 opacity-50">CRIADO POR</span>
                        <div class="lh-sm">{{ dio.criadorNome || '---' }}</div>
                        <div class="lh-sm fs-7 text-gray" *ngIf="dio.criadorEmail">{{ dio.criadorEmail }}</div>
                    </div>
                    <div class="col-12 col-md-4">
                        <span class="fw-bold fs-7 opacity-50">CRIADO EM</span>
                        <div class="lh-sm">{{ dio.created_at | date:'dd/MM/yyyy' }}</div>
                        <div class="lh-sm fs-7 text-gray">{{ dio.created_at | date:'HH:mm:ss' }}</div>
                    </div>
                </div>
            </div>

            <!-- EXECUÇÃO -->
            <div class="callout border-success pt-2 pb-0 mb-4">
                <div class="row">
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">INICIO DA EXECUÇÃO</span>
                        <div class="lh-sm">{{ dio.dataInicioExecucao ? (dio.dataInicioExecucao | date:'dd/MM/yyyy HH:mm:ss') : '---' }}
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">FIM DA EXECUÇÃO</span>
                        <div class="lh-sm">{{ dio.dataFimExecucao ? (dio.dataFimExecucao | date:'dd/MM/yyyy HH:mm:ss') : '---' }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">LIMITE DA EXECUÇÃO</span>
                        <div class="lh-sm">{{ dio.dataLimiteExecucao | date:'dd/MM/yyyy HH:mm:ss' : 'UTC' }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">STATUS EXECUÇÃO</span>
                        <div class="d-flex">
                            <span class="status-badge {{getBadgeColor(dio.statusExecucao)}}">{{ dio.statusExecucao | translate }}</span>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-2" *ngIf="dio.alocacao">
                        <div *ngIf="dio.alocacao.grupoTarifacaoTipo === 'VEICULOS'">
                            <span class="fw-bold fs-7 opacity-50">HODÔMETRO INICIAL DA EXECUÇÃO </span>
                            <div class="lh-sm">{{ dio.hodometroInicial ? dio.hodometroInicial + ' km' : '---' }}</div>
                        </div>
                        <div *ngIf="dio.alocacao.grupoTarifacaoTipo === 'MAQUINAS'">
                            <span class="fw-bold fs-7 opacity-50">HORÍMETRO INICIAL DA EXECUÇÃO</span>
                            <div class="lh-sm">{{ dio.horimetroInicial ? dio.horimetroInicial + ' horas' : '---' }}</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-2" *ngIf="dio.alocacao">
                        <div *ngIf="dio.alocacao.grupoTarifacaoTipo === 'VEICULOS'">
                            <span class="fw-bold fs-7 opacity-50">HODÔMETRO FINAL DA EXECUÇÃO</span>
                            <div class="lh-sm">{{ dio.hodometroFinal ? dio.hodometroFinal + ' km' : '---' }}</div>
                        </div>
                        <div *ngIf="dio.alocacao.grupoTarifacaoTipo === 'MAQUINAS'">
                            <span class="fw-bold fs-7 opacity-50">HORÍMETRO FINAL DA EXECUÇÃO</span>
                            <div class="lh-sm">{{ dio.horimetroFinal ? dio.horimetroFinal + ' horas' : '---' }}</div>                        
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">STATUS CONCLUSÃO</span>
                        <div class="d-flex" *ngIf="dio.statusConclusao">
                            <span class="status-badge {{getBadgeColor(dio.statusConclusao)}}">{{ dio.statusConclusao | translate }}</span>
                        </div>
                        <div *ngIf="!dio.statusConclusao">---</div>
                    </div>
                    <div class="col-12 col-md-4 mb-2" *ngIf="dio.alocacao">
                        <div *ngIf="dio.alocacao.grupoTarifacaoTipo === 'VEICULOS'">
                            <span class="fw-bold fs-7 opacity-50">TOTALIZADOR HODÔMETRO</span>
                            <div class="lh-sm">{{ dio.hodometroInicial && dio.hodometroFinal ? (dio.hodometroFinal - dio.hodometroInicial) + ' km' : '---' }}</div>
                        </div>
                        <div *ngIf="dio.alocacao.grupoTarifacaoTipo === 'MAQUINAS'">
                            <span class="fw-bold fs-7 opacity-50">TOTALIZADOR HORÍMETRO</span>
                            <div class="lh-sm">{{ dio.horimetroInicial && dio.horimetroFinal ? (dio.horimetroFinal - dio.horimetroInicial) + ' horas' : '---' }}</div>                        
                        </div>
                    </div>                                    
                    <div class="col-12 mb-2">
                        <mat-divider></mat-divider>
                    </div>                    
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">INICIO DA OPERAÇÃO</span>
                        <div class="lh-sm">{{ dio.dataPeriodoInicioOperacao ? (dio.dataPeriodoInicioOperacao | date:'dd/MM/yyyy') : '---' }}
                        </div>
                    </div>
                    <div class="col-12 col-md-4 mb-3">
                        <span class="fw-bold fs-7 opacity-50">FIM DA OPERAÇÃO</span>
                        <div class="lh-sm">{{ dio.dataPeriodoFimOperacao ? (dio.dataPeriodoFimOperacao | date:'dd/MM/yyyy') : '---' }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <span class="fw-bold fs-7 opacity-50">MOTIVO DESVIO</span>
                        <div class="lh-sm">{{ dio.motivoDesvio || '---' }}</div>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <span class="fw-bold fs-7 opacity-50">DETALHES DE EXECUÇÃO</span>
                        <div class="lh-sm" *ngIf="dio.execucaoDetalhesValida && dio.execucaoDetalhesValida.imagens && dio.execucaoDetalhesValida.imagens.length">
                            <button mat-button color="primary" (click)="openCarousel(dio.execucaoDetalhesValida.imagens)">
                                <mat-icon>collections</mat-icon> {{ dio.execucaoDetalhesValida.imagens.length }} imagen(s)
                            </button>
                        </div>
                        <div class="lh-sm" *ngIf="!dio.execucaoDetalhesValida || !dio.execucaoDetalhesValida.imagens || !dio.execucaoDetalhesValida.imagens.length">{{ '---' }}</div>
                    </div>

                    <!-- tarifação -->
                    <ng-container *ngIf="canReadTarifacoes()">
                        <div class="col-12 mb-3">
                            <mat-divider></mat-divider>
                        </div>
                        <h2 class="fs-6 mb-1">TARIFAÇÃO</h2>
                        <div class="col-12 col-md-4 mb-3" *ngIf="dio.alocacao.grupoTarifacaoTipo === 'MAQUINAS'">
                            <span class="fw-bold fs-7 opacity-50">VALOR/HORA DISPONÍVEL MÁQUINA</span>
                            <div class="lh-sm">{{ dio.alocacao.valorHoraDisponivelMaquina | currency: 'BRL' }}</div>
                        </div>
                        <div class="col-12 col-md-4 mb-3" *ngIf="dio.alocacao.grupoTarifacaoTipo === 'MAQUINAS'">
                            <span class="fw-bold fs-7 opacity-50">VALOR/HORA FUNCIONAMENTO MÁQUINA</span>
                            <div class="lh-sm">{{ dio.alocacao.valorHoraProduzida | currency: 'BRL' }}</div>
                        </div>
                        <div class="col-12 col-md-4 mb-3" *ngIf="dio.alocacao.grupoTarifacaoTipo === 'VEICULOS'">
                            <span class="fw-bold fs-7 opacity-50">VALOR/HORA DISPONÍVEL VEÍCULO</span>
                            <div class="lh-sm">{{ dio.alocacao.valorHoraDisponivelVeiculo | currency: 'BRL' }}</div>
                        </div>
                        <div class="col-12 col-md-4 mb-3" *ngIf="dio.alocacao.grupoTarifacaoTipo === 'VEICULOS'">
                            <span class="fw-bold fs-7 opacity-50">VALOR/HORA KM RODADO</span>
                            <div class="lh-sm">{{ dio.alocacao.valorKmRodado | currency: 'BRL' }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="(dio.statusExecucao === 'EXECUTADA' || dio.statusExecucao === 'AGUARDANDO_FUNCIONARIO' || dio.statusExecucao === 'AGUARDANDO_FISCAL') && dio.execucaoDetalhesValida">
                        <div class="col-12 mb-3">
                            <mat-divider></mat-divider>
                        </div>
                        <h2 class="fs-6 mb-1">USO DO EQUIPAMENTO</h2>
                        <div class="col-12 col-md-4 mb-3">
                            <span class="fw-bold fs-7 opacity-50">TEMPO DE DISPONIBILIDADE</span>
                            <div class="lh-sm">{{ formatHumanTime(dio.execucaoDetalhesValida.disponibilidadeTotalMinutes) }}</div>
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <div *ngIf="dio.alocacao.grupoTarifacaoTipo === 'MAQUINAS'">
                                <span class="fw-bold fs-7 opacity-50">TEMPO DE FUNCIONAMENTO</span>
                                <div class="lh-sm">{{ formatHumanTime(dio.execucaoDetalhesValida.funcionamentoTotalMinutes) }}</div>
                            </div>
                            <div *ngIf="dio.alocacao.grupoTarifacaoTipo === 'VEICULOS'">  
                                <span class="fw-bold fs-7 opacity-50">KM RODADOS</span>
                                <div class="lh-sm">{{ dio.execucaoDetalhesValida.kmRodados + ' km' }}</div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                        </div>
                    </ng-container>
                    <ng-container *ngIf="canReadPrecificacoes()">
                        <div class="col-12 mb-3">
                            <mat-divider></mat-divider>
                        </div>
                        <ng-container *ngFor="let precificacao of precificacoes">
                            <h2 class="fs-6 mb-1">PRECIFICAÇÃO - {{ precificacao.tipoPrecificacao | translate }}</h2>
                            <div class="col-12 col-md-4 mb-3" *ngIf="precificacao.valorTotalDisponibilidadeMaquina">
                                <span class="fw-bold fs-7 opacity-50">TOTAL POR DISPONIBILIDADE MÁQUINA</span>
                                <div class="lh-sm">{{ precificacao.valorTotalDisponibilidadeMaquina | currency: 'BRL' }}</div>
                            </div>
                            <div class="col-12 col-md-4 mb-3" *ngIf="precificacao.valorTotalDisponibilidadeVeiculo">
                                <span class="fw-bold fs-7 opacity-50">TOTAL POR DISPONIBILIDADE VEÍCULO</span>
                                <div class="lh-sm">{{ precificacao.valorTotalDisponibilidadeVeiculo | currency: 'BRL' }}</div>
                            </div>
                            <div class="col-12 col-md-4 mb-3" *ngIf="precificacao.valorTotalProduzido">
                                <span class="fw-bold fs-7 opacity-50">TOTAL POR FUNCIONAMENTO</span>
                                <div class="lh-sm">{{ precificacao.valorTotalProduzido | currency: 'BRL' }}</div>
                            </div>
                            <div class="col-12 col-md-4 mb-3" *ngIf="precificacao.valorTotalKmRodado">
                                <span class="fw-bold fs-7 opacity-50">TOTAL POR KM RODADOS</span>
                                <div class="lh-sm">{{ precificacao.valorTotalKmRodado | currency: 'BRL' }}</div>
                            </div>
                            <div class="col-12 col-md-4 mb-3">
                                <span class="fw-bold fs-7 opacity-50">TOTAL</span>
                                <div class="lh-sm fw-bold text-danger">{{
                                    (precificacao.valorTotalDisponibilidadeMaquina +
                                    precificacao.valorTotalDisponibilidadeVeiculo +
                                    precificacao.valorTotalProduzido +
                                    precificacao.valorTotalKmRodado) | currency: 'BRL' }}</div>
                            </div>
                            <div class="col-12 col-md-4 mb-3" *ngIf="precificacao.valorTotalKmRodado">
                              <span class="fw-bold fs-7 opacity-50">TIPO</span>
                              <div class="lh-sm">{{ precificacao.precificacaoManual ? 'MANUAL' : 'AUTOMÁTICA' }}</div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- assinaturas -->
                    <div class="col-12 mb-3">
                        <mat-divider></mat-divider>
                    </div>
                    <h2 class="fs-6">ASSINATURAS</h2>
                    <ul class="timeline-ul">
                        <ng-container *ngFor="let item of [].constructor(3); index as index; first as first; last as last">
                            <li [ngClass]="{'only': first && last}">
                                <div class="timeline-div fst-italic text-gray text-center" *ngIf="index == 0 && !dio.assinaturas[index]">Aguardando conclusão do condutor</div>
                                <div class="timeline-div fst-italic text-gray text-center" *ngIf="index == 1 && !dio.assinaturas[index]">Aguardando atesto do funcionário</div>
                                <div class="timeline-div fst-italic text-gray text-center" *ngIf="index == 2 && !dio.assinaturas[index]">Aguardando validação do fiscal</div>
                                <ng-container *ngIf="dio.assinaturas[index]">
                                    <span>{{ dio.assinaturas[index].role | translate | uppercase }}</span>
                                    <div class="timeline-div text-center">
                                        <a href="javascript:;" class="d-block text-pink mb-2" *ngIf="dio.assinaturas[index].url"
                                            (click)="openSignatureDialog(dio.assinaturas[index])">
                                            {{ abreviateName(dio.assinaturas[index].userName) | titlecase }}
                                        </a>
                                        <div class="mb-2" *ngIf="!dio.assinaturas[index].url">
                                            {{ abreviateName(dio.assinaturas[index].userName) | titlecase }}
                                        </div>
                                        <div class="fs-7 opacity-50" *ngIf="!dio.assinaturas[index].observacao">Nenhuma observação</div>
                                        <div class="fs-7 opacity-50" *ngIf="dio.assinaturas[index].observacao">"{{ dio.assinaturas[index].observacao }}"</div>
                                        <div class="fs-7 opacity-50 pt-2 mt-3 border-top">
                                            {{ dio.assinaturas[index].created_at | date:'dd/MM/yyyy HH:mm:ss' || '---' }}
                                        </div>
                                    </div>
                                </ng-container>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>

            <!-- MAPA -->
            <div class="callout border-warning pt-2 mb-4" 
                *ngIf="allowedViewMapaDio()">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <span class="fw-bold fs-7 opacity-50">MAPA</span>                        
                    </div>
                    <ng-container>
                        <div class="col-12 mb-2">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="col-12 col-md-4">
                          <div class="d-flex">
                            <button mat-flat-button class="bg-info" [routerLink]="['/dio', this.idDio, 'mapa']">
                                VER MAPA
                            </button>                            
                          </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- AFERIÇÃO -->
            <div class="callout border-warning pt-2" *ngIf="allowedAferirDio()">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <span class="fw-bold fs-7 opacity-50">STATUS AFERIÇÃO</span>
                        <div class="d-flex">
                            <span class="status-badge {{getBadgeColor(dio.statusAuditoria)}}">{{ dio.statusAuditoria | translate }}</span>
                        </div>
                    </div>
                    <ng-container  *ngIf="dio.afericao && dio.afericao.aferidor">
                        <div class="col-12 col-md-4">
                            <span class="fw-bold fs-7 opacity-50" >AFERIDOR</span>
                            <div class="lh-sm">{{ dio.afericao.aferidor.firstName ? dio.afericao.aferidor.firstName + ' ' + dio.afericao.aferidor.lastName : '---' }}</div>
                        </div>
                        <div class="col-12 col-md-4">
                            <span class="fw-bold fs-7 opacity-50">DATA DISPONIBILIZACAO</span>
                            <div class="lh-sm">{{ dio.afericao.dataDisponibilizacao ? (dio.afericao.dataDisponibilizacao | date:'short') :  '---'}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="dio.statusAuditoria == 'AGUARDANDO_AUDITORIA' && dio.afericao.status != 'PENDENTE_APROVACAO'">
                        <div class="col-12 my-2">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="col-12 col-md-4">
                          <div class="d-flex">
                            <button mat-flat-button color="warn" (click)="iniciarAfericao()">
                                AFERIR DIO
                            </button>
                            <button mat-flat-button class="ms-3 text-bg-secondary" (click)="openUpdateAferidorDialog()" *ngIf="canChangeAferidor()">
                                MUDAR AFERIDOR
                            </button>
                          </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="dio.afericao.status == 'PENDENTE_APROVACAO' || dio.afericao.status == 'FINALIZADA'">
                      <div class="col-12 my-2">
                          <mat-divider></mat-divider>
                      </div>
                      <div class="col-12 col-md-4">
                        <div class="d-flex">
                          <button mat-flat-button color="warn" [routerLink]="['/dio', this.idDio, 'aferir']">
                              VER AFERIÇÃO
                          </button>
                        </div>
                      </div>
                  </ng-container>
                </div>
            </div>
        </ng-container>
    </mat-card>
</div>

<print-dio [dio]="dio"></print-dio>