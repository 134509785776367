import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, interval, switchMap } from 'rxjs';
import { GenericCRUDService } from '../shared/services';
import { Data, Pagination } from '../shared/models';
import { environment } from 'src/environments/environment';
import { Dio, DIOPrecificacao, MonitoramentoGeolocalizacao } from './dio.model';
import { formatDataAsString } from '../shared/date-utils';

type GeolocalizacoesByEquipamentoParams = {
    idEquipamento: string;
    start: string;
    end: string;
}

@Injectable({
    providedIn: 'root'
})
export class DioService extends GenericCRUDService {
    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/dio`;
    }

    private getMetricsSubject = new Subject<void>();
    action$ = this.getMetricsSubject.asObservable();

    notifygetMetricsSubject() {
      this.getMetricsSubject.next();
    }

    override getPaginated<T>(page: number, pageSize: number, filters?: any): Observable<Pagination<T>> {
        const params = new URLSearchParams();
        params.append('page', `${page}`);
        params.append('pageSize', `${pageSize}`);
        if (filters)
            for (const key in filters)
                params.append(key, filters[key]);
        return this.http.get<Pagination<T>>(`${this.url}?${params}`);
    }

    // TODO rename getShortDioPaginated
    getAllByParametersV2<Dio>(page: number, pageSize: number, filters?: any): Observable<Data<Dio>> {
      const params = new URLSearchParams();
      params.append('page', `${page}`);
      params.append('pageSize', `${pageSize}`);

      if (filters)
          for (const key in filters)
              params.append(key, filters[key]);

      return this.http.get<Data<Dio>>(`${this.url}/native/v2?${params}`);
    }

    getGeolocalizacoesByEquipamentoId({ idEquipamento, start, end }: GeolocalizacoesByEquipamentoParams): Observable<MonitoramentoGeolocalizacao[]> {
        const params = new URLSearchParams();
        params.append('idEquipamento', idEquipamento);
        params.append('start', start);
        params.append('end', end);
        return this.http.get<MonitoramentoGeolocalizacao[]>(`${environment.elasticMsURL}/monitoramento-geolocalizacoes?${params}`);
    }

    getMetricsByStatus() {        
        return this.http.get<any>(`${this.url}/metrics/pendentes`);                
    }

    getMetricsEveryMinute() {        
        return interval(60000).pipe(
            switchMap(() => this.getMetricsByStatus())
        );              
    }

    findPrecificacoesByDIOId(id: string): Observable<DIOPrecificacao[]> {
        return this.http.get<DIOPrecificacao[]>(`${environment.managerURL}/admin/dio-precificacao?dioId=${id}`);
    }

    synchronizeDadosDeExecucao(id: string) {
        return this.http.get<any>(`${this.url}/synchronize/metrics/${id}`);
    }

    signatureDio(id: string, pathSignature: 'funcionario' | 'fiscal', observacao: string) {    
        return this.http.post<any>(`${this.url}/${id}/assinaturas/${pathSignature}`, {observacao: observacao ? observacao : ''});
    }

    cancelDio(id: string, tipo: 'DIO_NAO_ATESTADA' | 'DIO_NAO_ACOLHIDO', motivo: string) {
        return this.http.post<any>(`${this.url}/${id}/cancelamentos`, {tipo: tipo, motivo: motivo});
    }

    findAllAferidores() {
      return this.http.get<any>(`${environment.managerURL}/aferidor`);
    }

    findLastDio(contratoId: string, alocacaoId: string): Observable<Dio>{
        const params = new URLSearchParams();
        params.append('contrato', `${contratoId}`);
        params.append('alocacao', `${alocacaoId}`);
        
        return this.http.get<Dio>(`${environment.managerURL}/dio-eventos/last/by-contrato-alocacao?${params}`);
    }

    checkSimilarDio(dio: Dio): Observable<Dio>{
        const params = new URLSearchParams();
        params.append('dataInicioExecucao', `${dio.dataPeriodoInicioOperacao}`);

        console.log('dio pre salvar: ', dio);

        return this.http.get<Dio>(`${environment.managerURL}/dio-alocacao/${dio.alocacao}/last-by-date?${params}`);
    }

    findDiosIguaisPorPeriodo(id: string | undefined, dataInicio: Date | undefined, dataFim: Date | undefined): Observable<Dio[]>{
        const params = new URLSearchParams();
        params.append('dataInicio', `${formatDataAsString(dataInicio)}`);
        params.append('dataFim', `${formatDataAsString(dataFim)}`);

        console.log('params data: ', params);
        
        return this.http.get<Dio[]>(`${environment.managerURL}/find-dio/${id}/iguais?${params}`);
    }
}
