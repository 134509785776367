import { Component, OnInit } from '@angular/core';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { Router } from '@angular/router';
import { RegraCorteAfericao, RegrasCortesAfericoesService } from './regras-cortes-afericoes.service';

@Component({
  selector: 'app-regras-cortes-afericao',
  templateUrl: './regras-cortes-afericao.component.html'
})
export class RegrasCortesAfericaoComponent implements OnInit {

    loading = true;
    filter: string = '';
    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Configurações', route: '/configuracoes' },
        { label: 'Regras de Corte de Aferição' },
    ]
    
    items: any[] = [];
    dataSource: RegraCorteAfericao[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Nome', attr: 'nome' },
        { header: 'Descrição', attr: 'descricao' },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: {
                action: (rowData: RegraCorteAfericao) => this.router.navigateByUrl(`/configuracoes/regras-cortes-afericoes/${rowData.id}/editar`)
            }
        }
    ]

    constructor(
        private regrasService: RegrasCortesAfericoesService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loadRegras();
    }

    loadRegras(): void {
        this.loading = true;
        this.regrasService.getRegrasAfericoes().subscribe({
            next: (res) => {
                this.items = res;
                this.dataSource = res;
            },
            error: (err) => {
                console.error('Erro ao carregar regras de aferições', err);
            }
        }).add(() => this.loading = false);
    }

    search(pageEvent?: GenerateTablePageEvent) {
        if (!this.filter) {
            this.dataSource = this.items;
        } else {
            const filterValue = this.filter.toLowerCase();
            this.dataSource = this.items.filter(item => {
              return (
                (item.nome && item.nome.toLowerCase().includes(filterValue)) ||
                (item.descricao && item.descricao.toLowerCase().includes(filterValue))
              );
            });
        }
    }
}
