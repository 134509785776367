
<div class="d-flex flex-column h-100 p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <h1 class="fs-3 fw-light mb-0">{{ titlePage }}</h1>
    <mat-card *ngIf="loading.main || loading.mapa">
        <mat-card-content>            
            <mat-spinner class="mx-auto my-5"></mat-spinner>
            <div class="text-center">
                <h3>Carregando pontos do mapa...</h3>
            </div>            
        </mat-card-content>
    </mat-card>

    <div class="d-flex flex-column h-100" [ngClass]="{'d-none': loading.main || loading.mapa}">
        <div class="d-flex position-relative overflow-hidden h-100 mb-3">

            <!-- DIO INFOS -->
            <div id="map-infos" class="py-2 px-3">
                <div><strong>{{ dio.numero }}</strong> - {{ dio.servico ? dio.servico.titulo : '' }}</div>
                <div class="fs-7 lh-sm" *ngIf="dio.alocacao">
                    O equipamento {{ dio.alocacao.codigo }} ({{ dio.alocacao.equipamento.placa | mask: 'AAA-AAAA' }}) executou o serviço
                    no dia {{ dio.dataInicioExecucao | date:'dd/MM/yyyy HH:mm' }} até {{ dio.dataFimExecucao | date:'dd/MM/yyyy HH:mm' }}
                    resultando em {{ horasTrabalhadas }} trabalhados, {{ horasDisponiveis }} disponíveis e {{ kmRodados }} kms rodados.
                </div>
            </div>
            
            <!-- 2 POINTS INFOS -->
            <div class="map-left-side overflow-visible text-nowrap p-2 ps-3" [ngClass]="{'show': show.info2Points}">
                <mat-spinner class="btn-spinner mx-auto my-5" *ngIf="loading.info2Points || !points.info2Points.data"></mat-spinner>
                <ng-container *ngIf="!loading.info2Points && points.info2Points.data">
                    <div class="d-flex justify-content-between">
                        <span>Informações sobre os pontos</span>
                        <mat-icon (click)="closeInfos2Points()" class="pointer">close</mat-icon>
                    </div>
                    <div class="row">
                        <div class="col-6 mb-2">
                            <span class="fs-5">{{ points.info2Points.data.disponibilidadeTotalMinutes }}</span>
                            <div class="fs-7 fw-lighter text-gray">Disponibilidade Total</div>
                        </div>
                        <div class="col-6 mb-2">
                            <span class="fs-5">{{ points.info2Points.data.funcionamentoTotalMinutes }}</span>
                            <div class="fs-7 fw-lighter text-gray">Funcionamento Total</div>
                        </div>                        
                        <div class="col-6">
                            <span class="fs-5">{{ points.info2Points.data.kmRodadosByOdometro }}</span>
                            <div class="fs-7 fw-lighter text-gray">KM Rodados</div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <!-- AÇÕES -->
            <div id="map-actions" [ngClass]="{'show ms-2': show.actions}" *ngIf="canSeeActions()">
                <button mat-mini-fab class="mb-2" [ngClass]="show.options ? 'bg-primary' : 'bg-white text-black'" (click)="showDiv('options')"
                    matTooltip="Opções" matTooltipPosition="left">
                    <mat-icon>tune</mat-icon>
                </button>
                <button mat-mini-fab class="mb-2" [ngClass]="show.timeline ? 'bg-primary' : 'bg-white text-black'" (click)="showDiv('timeline')"
                    matTooltip="Ponto a ponto" matTooltipPosition="left">
                    <mat-icon fontSet="material-icons-outlined">push_pin</mat-icon>
                </button>
                <button mat-mini-fab class="mb-2 bg-danger" (click)="showDiv('cancels')"
                    matTooltip="Cancelamento Parcial" matTooltipPosition="left">
                    <mat-icon fontSet="material-icons-outlined">wrong_location</mat-icon>
                </button>
                <button mat-mini-fab class="mb-2" [ngClass]="show.legend ? 'bg-primary' : 'bg-white text-black'" (click)="showDiv('legend')"
                    matTooltip="Legenda" matTooltipPosition="left">
                    <mat-icon>help_outline</mat-icon>
                </button>
            </div>

            <!-- OPÇÕES -->
            <div class="map-right-side overflow-visible text-nowrap" [ngClass]="{'show px-3': show.options}">
                <div class="my-3">
                    <mat-slide-toggle color="primary" [(ngModel)]="points.dio" (change)="toggleDioPoints($event)">Percurso aferido</mat-slide-toggle>
                </div>
                <div class="mb-3">
                    <mat-slide-toggle color="primary" disabled>Percurso previsto</mat-slide-toggle>
                </div>
                <div class="mb-3">
                    <mat-slide-toggle color="primary" [(ngModel)]="points.stop" (change)="toggleStopPoint($event)">Detecção de parada</mat-slide-toggle>
                </div>
                <div class="mb-3">
                    <mat-slide-toggle color="primary" (change)="toggleHeatMap($event)">Mapa de calor</mat-slide-toggle>
                </div>
                <div class="mb-3">
                    <mat-slide-toggle color="primary" (change)="toggleDirections($event)">Ver direções</mat-slide-toggle>
                </div>
                <div class="mb-3">
                    <mat-slide-toggle color="primary" disabled>DIO's semelhantes <span class="status-badge bg-danger text-white">3</span></mat-slide-toggle>
                </div>
                <div class="d-flex">
                    <mat-form-field class="w-100">
                        <mat-label>Inicio</mat-label>
                        <input matInput name="startTimeInput" #startTimeInput="ngModel" (change)="getDataPositionsByTime()"
                            [(ngModel)]="startTime" mask="Hh:m0" placeholder="--:--">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <mat-label>Fim</mat-label>
                        <input matInput name="endTimeInput" #endTimeInput="ngModel" (change)="getDataPositionsByTime()"
                            [(ngModel)]="endTime" mask="Hh:m0" placeholder="--:--">
                    </mat-form-field>
                </div>
                <div>
                    <ng-select name="user" [items]="dios$ | async" bindValue="id" [typeahead]="diosInput$" [loading]="loading.dios"
                        placeholder="Comparar DIO" typeToSearchText="Buscar DIO's..." notFoundText="Nenhum item encontrado" loadingText="Buscando..."
                        [(ngModel)]="diosCompare">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <span>#{{ item.numero }} - {{ item.servico.titulo }}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>

            <!-- PONTO A PONTO -->
            <div class="map-right-side overflow-auto" [ngClass]="{'show': show.timeline}">
                <mat-card class="rounded-end-0 mh-100">
                    <mat-card-content class="d-flex flex-column p-0 mh-100 ">
                        <div class="text-center text-muted py-4" *ngIf="!dataPositionsFiltered || !dataPositionsFiltered.length">
                            Nenhum ponto encontrado
                        </div>
                        <mat-accordion class="overflow-auto" *ngIf="dataPositionsFiltered && dataPositionsFiltered.length">
                            <mat-expansion-panel (opened)="showPoint(index)" (closed)="panelOpenState[index] = false"
                                *ngFor="let data of dataPositionsFiltered; index as index">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="flex-column align-items-start justify-content-between py-3 lh-sm">
                                        <span class="status-badge bg-{{ data.ignicao ? 'success' : 'danger' }} text-white">{{ data.ignicao ? 'Ligado' : 'Desligado' }}</span>
                                        <div class="row row-cols-2 g-2 fs-7 lh-sm mt-0">
                                            <div>
                                                <div class="fw-lighter">Data/Hora</div>
                                                <span>{{ data.data_transmissao | date:'dd/MM/yyyy HH:mm:ss' }}</span>                                                
                                            </div>
                                        </div>
                                        <div class="row row-cols-2 g-2 fs-7 lh-sm mt-0">
                                            <div>
                                                <div class="fw-lighter">Bateria</div>
                                                <span>{{ data.bateria }}</span>
                                            </div>
                                            <div>
                                                <div class="fw-lighter">Velocidade</div>
                                                <span>{{ data.velocidade }}km/h</span>
                                            </div>
                                            <div class="mt-2">
                                                <div class="fw-lighter">Odometro</div>
                                                <span>{{ data.odometro }} km</span>
                                            </div>
                                            <div class="mt-2">
                                                <div class="fw-lighter">Horímetro</div>
                                                {{ data.horimetro || '---' }}
                                            </div>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <!-- expanded -->
                                <div class="mt-2 fs-7 lh-sm">
                                    <div class="fw-lighter">Lat/Lng</div>
                                    <span class="fw-medium">{{ data.geolocalizacao }}</span>
                                </div>
                                <div class="mt-2 fs-7 lh-sm">
                                    <div class="fw-lighter">Endereço</div>
                                    <span class="fw-medium">{{ data.logradouro || '---' }}</span>
                                </div>
                                <!-- expanded -->

                            </mat-expansion-panel>
                        </mat-accordion>
                    </mat-card-content>
                </mat-card>
            </div>

            <!-- CANCELAMENTO PARCIAL -->
            <div class="map-right-side" [ngClass]="{'show': show.cancels}">
                <div class="d-flex flex-column p-0 mh-100 overflow-hidden">
                    <div class="pt-3 px-3 overflow-auto">
                        <div *ngFor="let partial of particalCancels">
                        <div class="callout border-danger mb-2">
                            <div class="d-flex justify-content-between pb-2 mb-2 fs-7 text-gray-600">
                              <span>{{ partial.usuarioResponsavelEmail }}</span>
                              <div class="pointer" *ngIf="canAproveOrDeleteAfericao()" (click)="showDeletedCortesDialog(partial)">
                                <mat-icon fontSet="material-icons-outlined" class="opacity-75">close</mat-icon>
                              </div>
                            </div>

                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="text-center lh-sm" *ngIf="!checkDioFinishSameDay()">{{ partial.startAt | date:'dd/MM/yyyy' }}</div>
                                    <div class="text-center lh-sm">{{ partial.dataInicio | date:'HH:mm' }}</div>
                                </div>
                                <mat-icon fontSet="material-icons-outlined" class="text-danger mx-3">east</mat-icon>
                                <div>
                                    <div class="text-center lh-sm" *ngIf="!checkDioFinishSameDay()">{{ partial.endAt | date:'dd/MM/yyyy' }}</div>
                                    <div class="text-center lh-sm">{{ partial.dataFim | date:'HH:mm' }}</div>
                                </div>
                            </div>
                            <div class="border-top pt-2 mt-2 fs-7 lh-sm text-gray-600">Motivo: {{ partial.motivo ? partial.motivo : '' }}</div>
                            <div class="border-top pt-2 mt-2 fs-7 lh-sm text-gray-600">Regras: {{ partial.regrasStr }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-column pb-3 px-3">
                        <button mat-flat-button color="warn" [disabled]="disableCancelamentoParcial()" (click)="showCancelDialog(false)">CANCELAMENTO PARCIAL</button>
                    </div>
                </div>
            </div>

            <!-- LEGENDA -->
            <div class="map-right-side" [ngClass]="{'show px-3 py-2': show.legend}">
                <mat-list>
                    <mat-list-item class="py-1 px-0">
                        <div class="d-flex align-items-center mb-1">
                            <mat-icon class="icon-sm ms-1 me-2" style="color: #EA4335">circle</mat-icon>
                            <span>Ignição ligada</span>
                        </div>
                    </mat-list-item>
                    <mat-list-item class="py-1 px-0">
                        <div class="d-flex align-items-center mb-1">
                            <mat-icon class="icon-sm ms-1 me-2" style="color: RosyBrown">circle</mat-icon>
                            <span>Ignição desligada</span>
                        </div>
                    </mat-list-item>
                    <mat-list-item class="py-1 px-0">
                        <img class="me-1 mb-1" height="26px" src="/assets/img/pin_block.svg" alt="Inicio">
                        <span>Parado</span>
                    </mat-list-item>
                    <mat-list-item class="py-1 px-0">
                        <img class="ms-1 me-2" height="20px" src="/assets/img/flag_start.svg" alt="Inicio">
                        <span>Inicio do trajeto</span>
                    </mat-list-item>
                    <mat-list-item class="py-1 px-0">
                        <img class="ms-1 me-2" height="20px" src="/assets/img/flag_end.svg" alt="Inicio">
                        <span>Fim do trajeto</span>
                    </mat-list-item>
                </mat-list>
            </div>

            <!-- MAPA -->
            <div class="flex-fill rounded" id="map"></div>
        </div>
        <div class="d-flex pb-3" *ngIf="isModoAfericao">
            <button [disabled]="disableCancelamentoTotal()" mat-flat-button color="warn" class="px-5 ms-auto" (click)="showCancelDialog(true)">
                CANCELAMENTO TOTAL
            </button>
            <button mat-flat-button class="bg-success text-white px-5 ms-2" [ngClass]="{'btn-dialog-spinner': loading.afericao}" 
                (click)="finish()" *ngIf="afericao.status != 'PENDENTE_APROVACAO'">
                <mat-spinner class="btn-spinner" *ngIf="loading.afericao"></mat-spinner>
                <span *ngIf="!loading.afericao">FINALIZAR AFERIÇÃO</span>  
            </button>
            <button mat-flat-button class="bg-success text-white px-5 ms-2" [ngClass]="{'btn-dialog-spinner': loading.afericao}"
                (click)="aprove()" *ngIf="canAproveOrDeleteAfericao()">
                <mat-spinner *ngIf="loading.afericao" class="btn-spinner"></mat-spinner>
                <span *ngIf="!loading.afericao">APROVAR AFERIÇÃO</span>  
            </button>
        </div>
        <div class="d-flex pb-3" *ngIf="!isModoAfericao">
            <button mat-flat-button class="bg-white px-5 ms-auto" [routerLink]="['/dio', this.dio.id, 'visualizar']">
                VOLTAR
            </button>
        </div>
    </div>
</div>
