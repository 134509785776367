import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { RegraCorteAfericao, RegrasCortesAfericoesService } from '../regras-cortes-afericoes.service';

@Component({
  selector: 'app-form-regras-cortes-afericao',
  templateUrl: './form-regras-cortes-afericao.component.html'  
})
export class FormRegrasCortesAfericaoComponent implements OnInit {

    loading: any = { main: false, save: false, responsaveis: false };
    breadcrumb: Breadcrumb[];
    showInputError = showInputError;

    regra = {} as RegraCorteAfericao;
    title: string;

    constructor(
        private regrasService: RegrasCortesAfericoesService, 
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private removeDialogService: RemoveDialogService,
        private snackBar: MatSnackBar,
        ) {}
    
    ngOnInit(): void {     
        this.regra.id = this.activatedRoute.snapshot.params['idRegra'];

        this.title = this.regra.id ? 'Editar' : 'Nova';
        this.breadcrumb = [
            { label: 'Início' },
            { label: 'Configurações', route: '/configuracoes' },
            { label: 'Regras de Corte de Aferição', route: '/configuracoes/regras-cortes-afericoes' },
            { label: this.title + ' regra de aferição' },
        ]   
        
        if (this.regra.id) {
            this.loading.main = true;
            this.regrasService.getById<RegraCorteAfericao>(this.regra.id).subscribe({
                next: (res) => this.regra = res,
                error: (err) => catchErr(err, this.snackBar)
            }).add(() => this.loading.main = false);
        }
    }

    save(): void {
        if (this.regra.nome.trim()) {
            this.regrasService.createRegraAfericao(this.regra).subscribe({
                next: () => {
                    // Redireciona para a lista de regras após criar a regra com sucesso
                    this.router.navigate(['configuracoes/regras-cortes-afericoes']);
                },
                error: (err) => {
                    console.error('Erro ao criar regra de aferição', err);
                }
            });
        }
    }

    voltar(){
        this.router.navigateByUrl('configuracoes/regras-cortes-afericoes');
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.regra.nome, (dialogRef: MatDialogRef<any>) => {
            this.regrasService.remove<RegraCorteAfericao>(this.regra.id).subscribe({
                next: () => {
                    this.snackBar.open('Regra de Aferição apagada com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigateByUrl('configuracoes/regras-cortes-afericoes');
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }
}
