import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { Dio } from '../../dio.model';
import { AfericaoService } from '../../afericao.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AprovarDto } from '../aprovar.dto';
import { DioService } from '../../dio.service';
import * as dayjs from 'dayjs';
import { RegraCorteAfericao, RegrasCortesAfericoesService } from 'src/app/config/regras-cortes-afericao/regras-cortes-afericoes.service';
import { catchError, concat, distinctUntilChanged, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { RolesEnum } from 'src/app/core';
import { CancelarAfericaoDioDto } from './cancelar-afericao-dio.dto';

@Component({
    selector: 'app-cancelar-afericao-dio',
    templateUrl: './cancelar-afericao-dio.component.html',
    styleUrls: ['./cancelar-afericao-dio.component.css']
})
export class CancelarAfericaoDioComponent implements OnInit {

    loading: boolean;
    cancelTotal: boolean
    cancelData: CancelarAfericaoDioDto;
    idDio: string | undefined;
    showInputError = showInputError;
    afericaoId: string;
    aprovarCtx: boolean;
    regrasCortes: RegraCorteAfericao[];
    regraCorteSelecionada: RegraCorteAfericao;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { dio: Dio, cancelTotal: boolean, aprovarCtx: boolean },
        public dialogRef: MatDialogRef<CancelarAfericaoDioComponent>,
        private afericaoService: AfericaoService,
        private regrasCortesService: RegrasCortesAfericoesService,
        private dioService: DioService,
        private snackBar: MatSnackBar
    ) {
        this.idDio = data?.dio?.id;
        this.cancelTotal = data.cancelTotal;
        this.aprovarCtx = data.aprovarCtx;
    }

    ngOnInit() {
        this.cancelData = new CancelarAfericaoDioDto();
        this.loading = true;
        this.cancelTotal = this.data.cancelTotal

        this.regrasCortesService.getRegrasAfericoes().subscribe({
            next: (res) => this.regrasCortes = res,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }


    filesOut($event: any) {
        this.cancelData.imagens = $event;
    }

    fileRemove($event: { file: any, index: number }) {
        this.cancelData.imagens.splice($event.index, 1);
    }

    checkDioFinishSameDay() {
        return dayjs(this.data.dio.dataInicioExecucao).isSame(this.data.dio.dataFimExecucao, 'day')
    }

    cancelDio() {
        this.loading = true;

        if (this.aprovarCtx) {
            this.aprovar();
            return;
        }


        if (this.cancelTotal) {
            this.totalCancelation();
            return;
        }

        this.parcialCancelation();
    }

    totalCancelation() {
        const createCorte = {
            dataInicio: this.data.dio?.dataInicioExecucao,
            dataFim: this.data.dio?.dataFimExecucao,
            motivo: this.cancelData?.motivo,
            afericaoId: this?.data?.dio?.afericao.id,
            isTotalCancelation: this.cancelTotal || true,
            isCorteTotal: true,
        };

        const imagens = this.cancelData.imagens

        this.afericaoService.createCorteParcialTotal({
            ...createCorte,
            imagens
        }).subscribe({
            next: (res) => {
                this.afericaoService.finishAfericao(this.idDio || "", {
                    ...createCorte,
                    isCorteTotal: true
                }).subscribe({
                    next: (res) => {
                        this.snackBar.open('Aferição cancelada com sucesso!', 'OK', { duration: 5000 });
                        this.loading = false;
                        this.dioService.notifygetMetricsSubject();
                        this.dialogRef.close(true);
                    },
                    error: (err) => {
                        this.loading = false;
                        catchErr(err, this.snackBar)
                    }
                });
            },
            error: (err) => {
                this.loading = false;
                catchErr(err, this.snackBar)
            }
        });
    }

    diabledCancelationButton() {
        if (this.cancelTotal) {
            return this.loading || !this.cancelData.regras;
        }

        const startDateInvalid = !this.cancelData.dateStart || this.cancelData.dateStart == undefined;
        const startHourInvalid = !this.cancelData.hourStart || this.cancelData.hourStart == undefined;

        const endDateInvalid = !this.cancelData.dateEnd || this.cancelData.dateEnd == undefined;
        const endHourInvalid = !this.cancelData.hourEnd || this.cancelData.hourEnd == undefined;

        if (this.checkDioFinishSameDay()) {
            return this.loading ||
                startHourInvalid ||
                endHourInvalid ||
                !this.cancelData.regras;
        }

        return this.loading ||
            startDateInvalid ||
            startHourInvalid ||
            endDateInvalid ||
            endHourInvalid ||
            !this.cancelData.regras;
    }

    parcialCancelation() {
        const formatHourInicio = this.cancelData?.hourStart.slice(0, 2) + ":" + this.cancelData?.hourStart.slice(2) + ":00";
        const formatHourFim = this.cancelData?.hourEnd.slice(0, 2) + ":" + this.cancelData?.hourEnd.slice(2) + ":00";

        let dataInicio = dayjs(this.cancelData.dateStart).format('YYYY-MM-DD') + "T" + formatHourInicio;
        let dataFim = dayjs(this.cancelData.dateEnd).format('YYYY-MM-DD') + "T" + formatHourFim;

        if (this.checkDioFinishSameDay()) {
            const dataInicioRaw = this.data.dio?.dataInicioExecucao?.toString().split('T')[0];
            const dataFimRaw = this.data.dio?.dataFimExecucao?.toString().split('T')[0];
            dataInicio = dataInicioRaw + "T" + formatHourInicio;
            dataFim = dataFimRaw + "T" + formatHourFim;
        }

        if(this.cancelData && this.cancelData.regras && this.cancelData.regras.length == 1){
            this.cancelData.regras.push("."); //contornar problema ao enviar array apenas com 1 elemento com content-type form-data
        }

        const createCorte = {
            dataInicio,
            dataFim,
            motivo: this.cancelData?.motivo,
            regras: this.cancelData?.regras,
            afericaoId: this?.data?.dio?.afericao.id,
            isTotalCancelation: this.cancelTotal || true,
        };

        console.log('criando corte: ', createCorte);

        const imagens = this.cancelData.imagens

        this.afericaoService.createCorteParcialAfericao({
            ...createCorte,
            imagens
        }).subscribe({
            next: (res) => {
                this.loading = false;
                this.dioService.notifygetMetricsSubject();
                this.dialogRef.close(true);
            },
            error: (err) => {
                this.loading = false;
                catchErr(err, this.snackBar)
            }
        });
    }

    aprovar() {
        const aprovarDto: AprovarDto = {
            isCorteTotal: true,
            motivo: this.cancelData?.motivo,
        };

        this.afericaoService.aprovar(this.idDio || '', aprovarDto).subscribe({
            next: (res) => {
                this.snackBar.open('Aferição Recusada com sucesso', '✓', { panelClass: 'success' });
                this.dioService.notifygetMetricsSubject();
                this.dialogRef.close(true);
            }, error: (err) => {
                catchErr(err, this.snackBar)
                this.dialogRef.close(true);
            }
        });
    }

    searchFn(term: string, item: any) {
        term = term?.toLocaleLowerCase();
        return item?.nome?.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.titulo?.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.firstName?.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.lastName?.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.codigo && item?.codigo.toLocaleLowerCase().indexOf(term) > -1 ||
            item?.equipamento && item?.equipamento.toLocaleLowerCase().indexOf(term) > -1;
    }

    setRegraCorte(regraCorte: RegraCorteAfericao) {
        this.regraCorteSelecionada = regraCorte;
    }

    compareWith(item: any, selected: any) {
        return item.id == selected
    }

    removerRegra(index: number) {
        this.cancelData.regras.splice(index, 1);
        console.log('Regra removida. Regras atualizadas: ', this.cancelData);
    }

    addRegra(){
        console.log('regraSelecionada: ', this.regraCorteSelecionada);

        // Inicializa o array de regras se não existir
        if (!this.cancelData.regras) {
            this.cancelData.regras = [];
        }

        // Verifica se a regra já existe no array, comparando pelo id
        const regraExiste = this.cancelData.regras.some((regra: string) => regra === this.regraCorteSelecionada.nome);

        if (regraExiste) {
            console.log('A regra já existe e não será adicionada.');
        } else {
            // Adiciona a regra se ela não for duplicada
            this.cancelData.regras.push(this.regraCorteSelecionada.nome);
            console.log('Regras atualizadas: ', this.cancelData);
        }
    }
}
