<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content>
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light mb-0">Relatório de Consumo por Equipamento</h1>
                <button mat-icon-button color="primary" class="d-print-none btn-sm ms-auto" (click)="toggleFilters = !toggleFilters">
                    <mat-icon>tune</mat-icon>
                </button>
                <button mat-flat-button class="d-print-none ms-3 bg-gray-400" (click)="print()">IMPRIMIR</button>
                <button mat-flat-button color="primary" class="d-print-none ms-3" [matMenuTriggerFor]="menu">EXPORTAR</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="export('pdf')">PDF</button>
                    <button mat-menu-item (click)="export('csv')">Excel</button>
                </mat-menu>
            </div>

            <!-- FILTROS -->
            <ng-collapse [toggle]="toggleFilters" >
                <div class="border-top px-1 py-3">
                    <div class="row align-items-end">
                        <div class="col-12 col-md-6">
                            <ng-select name="beneficiaria" [items]="beneficiarias" bindValue="id" placeholder="Beneficiaria" required
                                [searchFn]="searchFn" [(ngModel)]="filters.beneficiaria" [compareWith]="compareWith"
                                (change)="setBeneficiaria($event)" required="true">
                                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
                                    {{ item.nome}}
                                </ng-template>
                            </ng-select>                        
                        </div>
                        <div class="col-12 col-md-6">
                            <ng-select name="contrato" [items]="contratos" bindValue="id" bindLabel="nome" placeholder="Contrato"
                                [searchFn]="searchFn" [(ngModel)]="filters.contrato" [compareWith]="compareWith" required="true">
                            </ng-select>
                        </div>
                        <div class="col-12 col-md-6">
                            <mat-form-field class="w-100">
                                <mat-label>Inicio</mat-label>
                                <input matInput [matDatepicker]="dataInicio" name="inicio" #inicio="ngModel"
                                    autocomplete="off" [(ngModel)]="filters.dataInicio">
                                <mat-datepicker-toggle matIconSuffix [for]="dataInicio"></mat-datepicker-toggle>
                                <mat-datepicker #dataInicio></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-6">
                            <mat-form-field class="w-100">
                                <mat-label>Fim</mat-label>
                                <input matInput [matDatepicker]="dataFim" name="fim" #fim="ngModel" autocomplete="off"
                                    [(ngModel)]="filters.dataFim">
                                <mat-datepicker-toggle matIconSuffix [for]="dataFim"></mat-datepicker-toggle>
                                <mat-datepicker #dataFim></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-2 ms-auto">
                            <button mat-flat-button color="accent" class="w-100" (click)="search()">FILTRAR</button>
                        </div>
                    </div>
                </div>
            </ng-collapse>
            <div *ngIf="dataSource">
                <div class="callout border-blue-300 mb-3">
                    <div class="row">
                        <div class="col-12 col-md-4 mb-3">
                            <span class="fw-bold fs-7 opacity-50">NÚMERO CONTRATO</span>
                            <div class="lh-sm">{{ contrato || '---' }}</div>
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <span class="fw-bold fs-7 opacity-50">BENEFICIÁRIO</span>
                            <div class="lh-sm">{{ beneficiaria || '---' }}</div>
                        </div>
                        <div class="col-12 col-md-4 mb-3" *ngIf="dataSource">
                            <span class="fw-bold fs-7 opacity-50">PERÍODO</span>
                            <div class="lh-sm">
                                <span *ngIf="dataInicioPesquisada"> De {{ dataInicioPesquisada }} até {{ dataFimPesquisada }} </span>
                            </div>
                        </div>     
                    </div>
                </div>
            </div>
            <generate-table 
                [dataSource]="dataSource" [columns]="columns" [loading]="loading"
                [total]="pagination.total" [pageSize]="pagination.pageSize" 
                (pageEvent)="search($event)"
            ></generate-table>
        </mat-card-content>
    </mat-card>
</div>
