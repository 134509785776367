export * from './relatorio.component';
export * from './relatorio.service';

export * from './dio-nao-executado/dio-nao-executado.component';
export * from './equipamentos-existentes/equipamentos-existentes.component';
export * from './relatorio-afericoes/relatorio-afericoes.component';
export * from './relatorio-aprovacoes/relatorio-aprovacoes.component';
export * from './relatorio-consumo-por-equipamento/relatorio-consumo-por-equipamento.component';
export * from './relatorio-dios-aferidas/relatorio-dios-aferidas.component';
export * from './valor-bruto-beneficiario/valor-bruto-beneficiario.component';
export * from './valor-bruto-monetario/valor-bruto-monetario.component';
export * from './valores-brutos-equipamentos/valores-brutos-equipamentos.component';
