import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Alocacao, AlocacaoService, Contrato } from '../..';
import { catchErr } from 'src/app/shared/utils';
import { GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { CurrencyPipe } from '@angular/common';
import * as dayjs from 'dayjs';

@Component({
    selector: 'alocacao',
    templateUrl: './alocacao.component.html',
    providers: [CurrencyPipe]
})
export class AlocacaoComponent implements OnInit {

    @Input() contrato: Contrato;

    loading = true;
    filter: string;
    dataSource: Alocacao[];

    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        { header: 'Prefixo', attr: 'prefixo' },
        { header: 'Equipamento', attr: 'equipamento' },
        { header: 'Ativa?', attr: 'ativaDesc' },

        { header: 'Início', attr: 'inicioVigencia' },
        { header: 'Fim', attr: 'fimVigencia' },
        {
          tdClass: 'td-w-auto',
          btnProps: { label: 'Visualizar', color: 'accent' },
          click: {
            action:(rowData: Alocacao) => this.router.navigateByUrl(`/contrato/${this.contrato.id}/alocacao/${rowData.id}/visualizar`)
          }
        },
        {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: {
                action: (rowData: Alocacao) => this.router.navigateByUrl(`/contrato/${rowData.contrato.id}/alocacao/${rowData.id}/editar`)
            }
        }
    ]

    constructor(
        private alocacaoService: AlocacaoService,
        private router: Router,
        private snackBar: MatSnackBar,
        private currencyPipe: CurrencyPipe
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        this.alocacaoService.getPaginated<Alocacao>(this.pagination.current, this.pagination.pageSize, this.contrato.id, this.filter).subscribe({
            next: (res) => {
                this.dataSource = res.data.map((alocacao) => ({
                    ...alocacao,
                    ativaDesc: alocacao.ativa ? 'Sim' : 'Não',
                    prefixo: alocacao.codigo,
                    equipamento: alocacao.equipamento.placa,
                    inicioVigencia: dayjs(alocacao.inicioVigencia).format('DD/MM/YYYY'),
                    fimVigencia: dayjs(alocacao.fimVigencia).format('DD/MM/YYYY'),
                    valorHoraDisponivelMaquina: this.currencyPipe.transform(alocacao.valorHoraDisponivelMaquina, 'BRL') || alocacao.valorHoraDisponivelMaquina,
                    valorHoraDisponivelVeiculo: this.currencyPipe.transform(alocacao.valorHoraDisponivelVeiculo, 'BRL') || alocacao.valorHoraDisponivelVeiculo,
                    valorHoraProduzida: this.currencyPipe.transform(alocacao.valorHoraProduzida, 'BRL') || alocacao.valorHoraProduzida,
                    valorKmRodado: this.currencyPipe.transform(alocacao.valorKmRodado, 'BRL') || alocacao.valorKmRodado,
                }));
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

}
