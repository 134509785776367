import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AjusteFaturamento, FaturamentoEquipamento, FaturamentoTotal } from '../faturamento.model';
import { catchErr } from 'src/app/shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CurrencyPipe } from '@angular/common';
import { DioPrecificacao } from '../dio-precificacao.service';
import { FormFaturamentoComponent } from '../form-faturamento/form-faturamento.component';

@Component({
    selector: 'app-ajustar-faturamento',
    templateUrl: './ajustar-faturamento.component.html',
    providers: [CurrencyPipe, FormFaturamentoComponent]
})
export class AjustarFaturamentoComponent implements OnInit {

    loading = { main: false, simular: false };

    dios: any;
    equipamento: any;
    totalizadores: FaturamentoTotal;

    dataSourceEquip: any[];
    dataSourceDIOs: any[];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { dios: any[], filters: any, equipamento: FaturamentoEquipamento, totalizadores: FaturamentoTotal },
        public dialogRef: MatDialogRef<AjustarFaturamentoComponent>,

        private dioPrecificacaoService: DioPrecificacao,
        private financeiroComponent: FormFaturamentoComponent,
        private snackBar: MatSnackBar,
    ) { }

    ngOnInit() {
        this.loading.main = true;
        this.totalizadores = this.data.totalizadores;
        this.equipamento = { ...this.data.equipamento };
        this.financeiroComponent.getDioByEquip(this.data.equipamento, this.data.filters, (res: any[]) => {
            this.loading.main = false;
            this.dataSourceDIOs = res.map((dio) => ({
                ...dio, 
                precificacoes: { 
                    ...dio.precificacoes, 
                    value: dio.precificacoes ? dio.precificacoes.value : 0 
                }
            }));
        });
    }

    closeDialog() {
        this.dialogRef.close({ equipamento: this.equipamento, dios: this.dios });
    }

    simularFaturamento() {
        this.loading.simular = true;
        const ajustes: AjusteFaturamento[] = this.dataSourceDIOs.map((dio) => ({
            dio: dio.id,
            valor: dio.precificacoes.value
        }))

        this.dioPrecificacaoService.simularFaturamento(this.data.filters.contratoId, this.data.filters.startAt, this.data.filters.endAt, ajustes).subscribe({
            next: (res) => {
                if (res.errors.length) {
                    res.errors.map((item) => this.snackBar.open(item.error, '✕', { panelClass: 'danger' }))
                } else {
                    const e = res.equipamentos.find((e) => e.equipamentoId == this.equipamento.equipamentoId)
                    this.equipamento.diferenca = e?.diferenca;
                    this.equipamento.valorReajustado = e?.valorReajustado;

                    this.totalizadores.diferenca = res.total.diferenca;
                    this.totalizadores.valorReajustado = res.total.valorReajustado;

                    this.dios = res.dios;
                }
            },
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.simular = false)
    }

}
