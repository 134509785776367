import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericCRUDService } from '../shared/services';
import { AjusteFaturamento, SimulacaoFaturamento } from './faturamento.model';

@Injectable({
    providedIn: 'root'
})
export class DioPrecificacao extends GenericCRUDService {

    constructor(http: HttpClient) {
        super(http);
        this.url = `/admin/dio-precificacao`;
    }

    simularFaturamento(idContrato: string, startDate: string, endDate: string, arrayAjuste: AjusteFaturamento[]) {
        const params = new URLSearchParams();
        params.append('startDate', `${startDate}`);
        params.append('endDate', `${endDate}`);
        return this.http.post<SimulacaoFaturamento>(`${this.url}/simulate/reajuste/${idContrato}?${params}`, arrayAjuste);
    }

    reajustePrecificacao(motivo: string, arrayAjuste: AjusteFaturamento[]) {
        return this.http.post(`${this.url}/reajuste`, { motivo, reajustes: arrayAjuste });
    }
}
