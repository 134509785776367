import { Directive, HostListener, Input } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Directive({
  selector: '[appOpenInNewTab]'
})
export class OpenInNewTabDirective {
  
  @Input('appOpenInNewTab') route!: any[]; 

  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.route || this.route.length === 0) return;
    
    const urlTree: UrlTree = this.router.createUrlTree(this.route);
    const url: string = this.router.serializeUrl(urlTree);

    if (event.ctrlKey || event.shiftKey) {
      window.open(url, '_blank'); // Abre em nova aba
    } else {
      this.router.navigate(this.route); // Navega na mesma aba
    }
  }
}