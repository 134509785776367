<div class="d-flex align-items-end mb-3">
    <h1 class="fs-3 fw-light mb-0">Alocações</h1>
    <mat-form-field appearance="outline" class="input-sm ms-auto me-3">
        <mat-label>Buscar alocações</mat-label>
        <input matInput [(ngModel)]="filter" (keyup.enter)="search()">
        <button mat-icon-button matSuffix (click)="search()">
            <mat-icon>search</mat-icon>
        </button>
    </mat-form-field>
    <button mat-flat-button color="primary" [routerLink]="['/contrato', contrato.id, 'alocacao', 'nova']">NOVA ALOCAÇÃO</button>
</div>
<div class="d-flex align-items-center border-top p-2">
    <div class="flex-shrink-0">
        <mat-icon class="text-primary">description</mat-icon>
    </div>
    <div class="flex-grow-1 ms-2">
        <span class="fw-bold fs-7 text-muted">{{ contrato.nome | uppercase }}</span>
        <div class="opacity-50 fs-7 mb-2">{{ contrato.beneficiaria.nome }}</div>
    </div>
</div>
<generate-table 
    [dataSource]="dataSource" [columns]="columns" [loading]="loading"
    [total]="pagination.total" [pageSize]="pagination.pageSize" 
    (pageEvent)="search($event)"
></generate-table>