import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { catchErr } from "../../../shared/utils";
import { DioService } from "../../dio.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "src/app/core";
import * as dayjs from "dayjs";
import { Router } from "@angular/router";

@Component({
    selector: 'assinatura-dio',
    templateUrl: 'assinatura-dio.component.html',
})
export class AssinaturaDioComponent {

    loading: boolean;

    approveDio = true;
    observacao: string;    
    motivoReprovacao: string;

    userName = this.authService.user.name;
    today = dayjs().format('DD [de] MMMM [de] YYYY [às] HH:mm');

    constructor(
        private authService: AuthService,
        private dioService: DioService,
        private snackBar: MatSnackBar,
        private router: Router,
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: { dioId: string, dioNumber: string, statusExecucao: string }
    ) { }

    confirmSignature() {
        this.loading = true;
        const pathSignature = this.data.statusExecucao === 'AGUARDANDO_FUNCIONARIO' ? 'funcionario' : 'fiscal';
        this.dioService.signatureDio(this.data.dioId, pathSignature, this.observacao).subscribe({
            next: () => {
                this.snackBar.open(`Assinatura do DIO ${this.data.dioNumber} realizada com sucesso!`, '✕', { panelClass: ['success'] });
                this.dialogRef.close(true);

                pathSignature === 'funcionario' ? this.router.navigateByUrl(`/pendencias/atesto`) : this.router.navigateByUrl(`/pendencias/validacao`);
                
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

    confirmReprove(){
        this.loading = true;
        if(!this.motivoReprovacao || this.motivoReprovacao.length == 0){
            this.snackBar.open('Motivo da reprovação é obrigatório!', '✕', { panelClass: ['error'] });
            this.loading = false;
            return;
        }

        const tipo = this.data.statusExecucao === 'AGUARDANDO_FUNCIONARIO' ? 'DIO_NAO_ATESTADA' : 'DIO_NAO_ACOLHIDO';

        this.dioService.cancelDio(this.data.dioId, tipo, this.motivoReprovacao).subscribe({
            next: () => {
                this.snackBar.open('Cancelamento realizado com sucesso!', '✕', { panelClass: ['success'] });
                this.dialogRef.close(true);

                console.log('tipo: ', tipo);

                //tipo === 'DIO_NAO_ATESTADA' ? this.router.navigateByUrl(`/pendencias/atesto`) : this.router.navigateByUrl(`/pendencias/validacao`);
                window.location.reload();

            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }

}