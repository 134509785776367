<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading"></mat-spinner>
    <mat-card *ngIf="!loading">
        <mat-card-content>
            <div class="row">
                <div class="col-12 d-flex align-items-end mb-2">
                    <h1 class="fs-3 fw-light mb-0">{{ equipamento.marca }} {{ equipamento.modelo }}</h1>
                    <button mat-flat-button color="primary" class="ms-auto" [routerLink]="['/equipamento', equipamento.id, 'editar']">EDITAR EQUIPAMENTO</button>
                </div>
                <div class="col-12 mb-2">
                    <mat-divider></mat-divider>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <span class="fw-bold fs-7 opacity-50">EM ALOCAÇÃO</span>
                    <div>
                        <span class="status-badge bg-info fs-7 lh-lg">{{ equipamento.emAlocacao ? 'Sim' : 'Não' }}</span>
                    </div>                
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <span class="fw-bold fs-7 opacity-50">PLACA</span>
                    <div class="lh-sm">{{ equipamento.placa | mask:'AAA-AAAA' }}</div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <span class="fw-bold fs-7 opacity-50">ANO FABRICAÇÃO</span>
                    <div class="lh-sm">{{ equipamento.fabricacaoAno }}</div>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <span class="fw-bold fs-7 opacity-50">GRUPO</span>
                    <div class="lh-sm">{{ equipamento.grupo.nome }}</div>
                </div>
                <div class="col-12 col-md mb-3">
                    <span class="fw-bold fs-7 opacity-50">DESCRIÇÃO</span>
                    <div>{{ equipamento.descricao || '---' }}</div>
                </div>
                <div class="col-12" *ngIf="alocacoes && alocacoes.length">
                    <span class="fw-bold fs-7 opacity-50">ALOCADO NO CONTRATO</span>
                    <mat-list role="list" class="list-border">
                        <mat-list-item class="py-1 pe-0" role="listitem" *ngFor="let alocacao of alocacoes">
                            <div class="d-flex align-items-center">
                                <mat-icon fontSet="material-icons-outlined" class="opacity-75">description</mat-icon>
                                <div class="d-flex flex-column ms-3 me-auto">
                                    <span class="lh-sm">{{ alocacao.contrato?.nome || '---'}}</span>                                    
                                    <span class="opacity-50 fs-7">{{ alocacao.contrato?.descricao || '---'}}</span>
                                </div>
                                <div class="d-flex flex-column ms-3 me-auto">
                                    <span class="fw-bold fs-7 opacity-50">PREFIXO</span>
                                    <div class="lh-sm">{{ alocacao.codigo }}</div>
                                </div>
                                <div class="d-flex flex-column ms-3 me-auto">
                                    <span class="fw-bold fs-7 opacity-50">INICIO</span>
                                    <div class="lh-sm">{{ alocacao.inicioVigencia | date:'shortDate' }}</div>
                                </div>
                                <div class="d-flex flex-column ms-3 me-auto">
                                    <span class="fw-bold fs-7 opacity-50">FIM</span>
                                    <div class="lh-sm">{{ alocacao.fimVigencia | date:'shortDate' }}</div>
                                </div>
                                <button mat-icon-button color="primary" [routerLink]="['/contrato', alocacao.contrato.id, 'visualizar']">
                                    <mat-icon>open_in_new</mat-icon>
                                </button>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>
                <div class="col-12" *ngIf="!alocacoes || !alocacoes.length">
                    <span class="fw-bold fs-7 opacity-50">ALOCADO NO CONTRATO</span>
                    <div class="lh-sm">EQUIPAMENTO NÃO POSSUIU ALOCAÇÕES</div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>