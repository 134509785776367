import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericCRUDService } from 'src/app/shared/services';
import { FinishAfericaoDto } from './view-dio/update-aferidor/finish-afericao.dto';
import { environment } from 'src/environments/environment';
import { AprovarDto } from './aferir-dio/aprovar.dto';
import { Observable } from 'rxjs';
import { Aferidores } from './dio.model';

@Injectable({
    providedIn: 'root'
})
export class AfericaoService extends GenericCRUDService {
    corteUrl: string = `${environment.managerURL}/afericao-cortes`;
  
    constructor(http: HttpClient) {
        super(http);
        this.url = `/afericao`;
    }

    findAllAferidoresObservable(term: string | null): Observable<Aferidores> {
      return this.http.get<any>(`${this.url}/aferidores`);
    }

    findAllAferidores() {
        return this.http.get<any>(`${this.url}/aferidores`);
    }

    findAfericaoAferidores() {
      return this.http.get<any>(`${this.url}/by-aferidores`);
  }

    findAferidor(id: string) {
      return this.http.get<any>(`${this.url}/aferidor/${id}`);
    }

    enableOrDisableAferidor(aferidorId: string) {
      return this.http.post<any>(`${this.url}/aferidor/${aferidorId}/enable`, null);
    }

    findAfericaoByDio(dioId: string) {
        return this.http.get<any>(`${this.url}/by-dio/${dioId}`);
    }

    startAfericao(idDio: string) {
        return this.http.post<any>(`${this.url}/start/${idDio}`, {});
    }

    finishAfericao(idDio: string, dto: FinishAfericaoDto) {
      return this.http.post<any>(`${this.url}/finish/${idDio}`, dto);
    }

    getCortesAfericao(afericaoId: string) {
      return this.http.get<any>(`${this.corteUrl}/${afericaoId}`);
    }

    createCorteParcialAfericao(dto: any) {
      const form = this.buildFormData(dto);
      return this.http.post<any>(`${this.corteUrl}`, form);
    }

    createCorteParcialTotal(dto: any) {
      const form = this.buildFormData(dto);
      return this.http.post<any>(`${this.corteUrl}/corte-total`, form);
    }

    aprovar(afericaoId: string, data: AprovarDto) {
      return this.http.post<any>(`${this.url}/aprovar/${afericaoId}`, data);
    }

    private buildFormData(object: any) {
      const formData: FormData = new FormData();
      for (const key in object) {
          if (Array.isArray(object[key])) {
              for (const imgKey in object[key]) {
                  formData.append(key, object[key][imgKey]);
              }
          } else {
              formData.append(key, object[key]);
          }
      }
      return formData;
    }
}
