import { Component, OnInit } from '@angular/core';
import { GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { Orgao, OrgaoService } from '..';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Pagination } from 'src/app/shared/models';
import { catchErr } from 'src/app/shared/utils';

@Component({
    selector: 'orgao',
    templateUrl: './orgao.component.html'
})
export class OrgaoComponent implements OnInit {

    loading = true;
    filter: string;
    dataSource: Orgao[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 }
    columns: GenerateTableColumn[] = [
        {
            tdClass: 'td-w-auto',
            template: (rowData: Orgao) => `<img height="40" width="40" class="img-cover rounded-circle bg-default" src="${rowData.imagem || '/assets/img/default_img.jpeg'}" />`
        },
        { header: 'Nome', attr: 'nome' },
        { header: 'Sigla', attr: 'sigla' },
        { 
            tdClass: 'td-w-auto',
            btnProps: { label: 'Visualizar', color: 'accent' },
            click: { action: (rowData: Orgao) => this.router.navigateByUrl(`/localidade/orgao/${rowData.id}/visualizar`)}
        }, {
            tdClass: 'td-w-auto',
            btnProps: { label: 'Editar' },
            click: { action: (rowData: Orgao) => this.router.navigateByUrl(`/localidade/orgao/${rowData.id}/editar`)}
        }
    ]

    constructor(
        private orgaoService: OrgaoService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize
        this.orgaoService.getPaginated<Orgao>(this.pagination.current, this.pagination.pageSize, this.filter).subscribe({
            next: (res: Pagination<Orgao>) => {
                this.dataSource = res.data.map((orgao) => ({ 
                    ...orgao,                    
                    imagem: orgao.imagem ? orgao.imagem.url : null
                }));
                this.pagination.total = res.total;
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);
    }
}
