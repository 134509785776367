import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchErr, showInputError } from 'src/app/shared/utils';
import { Aferidores, Dio } from '../../dio.model';
import { AfericaoService } from '../../afericao.service';
import { catchError, concat, distinctUntilChanged, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { Pagination } from 'src/app/shared/models';

@Component({
    selector: 'app-update-aferidor',
    templateUrl: './update-aferidor.component.html',
})
export class UpdateAferidorComponent implements OnInit {
    loading: any = { 
        main: false,
        save: false,
        aferidor: false,        
    };

    aferidores: Aferidores[]
    aferidoresFiltered: any;
    aferidorIdSelected: string;
    aferidorAtual: string;
    aferidorNumero: number;

    dio: Dio;    
    dioId: string;
    term: string;

    afericao = {} as any;
    aferidor$: Observable<Aferidores[]>;
    aferidorInput$ = new Subject<string>();
    
    showInputError = showInputError;

    constructor(
        private dialogRef: MatDialogRef<UpdateAferidorComponent>,
        private afericaoService: AfericaoService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: Dio,
    ) {
        this.dioId = data.id || '';
        this.dio = data;                        
    }

    ngOnInit() {
        this.loading.main = true;
        this.dio.id && this.afericaoService.findAfericaoByDio(this.dio.id).subscribe({
            next: (res) => {
                console.log('buscou afericao: ', res);                
                this.afericao = res;
                this.aferidorAtual = res.aferidor?.firstName + ' ' + res.aferidor?.lastName;
                this.aferidorNumero = res.aferidor?.numero;
            }
        }).add(() => this.loading.main = false);

        this.aferidor$ = this.getData(this.aferidorInput$, 'aferidor', (term: string) => this.afericaoService.findAllAferidoresObservable(term));

        /*
        this.afericaoService.findAllAferidores().subscribe({
            next: (res) => {
                console.log('res findAll Aferidores: ', res);
                this.aferidores = res.data.sort((a: any, b: any) => a.nome.localeCompare(b.nome, undefined, { sensitivity: 'base' }));
                this.aferidoresFiltered = this.aferidores;
            }, error: (err) => catchErr(err, this.snackBar)
        });
        */
    }

    update() {
        this.loading.main = true;
        this.afericaoService.update(this.dioId, { aferidor: this.aferidorIdSelected }).subscribe({
            next: () => {
                this.snackBar.open('Aferidor associado com sucesso!', '✕', { panelClass: ['success'] });
                this.dialogRef.close(true);
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.main = false);
    }

    search() {
        this.aferidoresFiltered = this.aferidores;
        const term = this.term?.toLocaleLowerCase();
        this.aferidoresFiltered = this.aferidores.filter(c =>
            c.nome.toLocaleLowerCase().indexOf(term) > -1 ||
            c.nome.toLocaleLowerCase().indexOf(term) > -1
        );

        console.log('aferidores filtrados: ', this.aferidoresFiltered);
    }

    setAferidor(aferidor: Aferidores) {
        console.log('set aferidor: ', aferidor);            
        this.aferidorIdSelected = aferidor && aferidor.id ? aferidor.id : '';
    }

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }

    private getData(inputSubject: Subject<string>, loading: string, observable: Function) {
        return concat(
            of([]), // default items
            inputSubject.pipe(
                distinctUntilChanged(),
                tap(() => {
                    console.log('setando loading: ', loading);
                    this.loading[loading] = true
                }),
                switchMap((term) => 
                    term ? observable(term).pipe( 
                        map((res: Pagination<any>) => res.data), // select by attr
                        catchError(() => of([])), // empty list on error
                        tap(() => this.loading[loading] = false)
                    ) as Promise<any> : new Promise(() => this.loading[loading] = false) // not search all
                )
            )
        );
    }
}
