<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    
    <mat-card>
        <mat-card-content>        
            <div class="d-flex align-items-end mb-3">
                <div class="d-flex align-items-center">
                    <h1 class="fs-3 fw-light mb-0">Usuários</h1>
                    <mat-icon class="pointer text-warning mt-1 ms-2" (click)="toggleInfo = !toggleInfo">info</mat-icon>
                </div>
                <div class="d-flex align-items-center ms-auto">
                    <button mat-icon-button color="primary" class="btn-sm" (click)="toggleFilters = !toggleFilters">
                        <mat-icon>tune</mat-icon>
                    </button>
                    <button mat-flat-button color="primary" class="ms-3" [routerLink]="['novo']">NOVO USUÁRIO</button>
                </div>
            </div>

            <!-- INFO -->
            <ng-collapse [toggle]="toggleInfo">
                <div class="callout border-warning bg-yellow-100 mb-3">
                    <mat-list role="list">
                        <mat-list-item class="p-0" role="listitem" [ngClass]="{'mb-1': !last}" *ngFor="let info of rolesInfo; last as last">
                            <span class="fw-bold">{{ info.value | translate }}</span>: {{ info.description }}
                        </mat-list-item>
                    </mat-list>
                </div>
            </ng-collapse>

            <!-- FILTROS -->
            <ng-collapse [toggle]="toggleFilters">
                <div class="border-top pt-3">
                    <div class="row">
                        <mat-form-field class="col-12 col-md">
                            <mat-label>Nome do usuário</mat-label>
                            <input matInput [(ngModel)]="filter">
                        </mat-form-field>
                        <div class="col-12 col-md">
                            <ng-select name="role" [items]="rolesInfo" bindValue="value" placeholder="Perfil de Usuário"
                                [(ngModel)]="selectedRole" [clearable]="false">
                                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    {{ item.nomeExibicao }}
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="col-12 col-md-auto">
                            <button mat-fab color="accent" type="submit" (click)="search()" [disabled]="loading">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-collapse>
            
            <generate-table
                [dataSource]="dataSource" [columns]="columns" [loading]="loading"
                [total]="pagination.total" [pageSize]="pagination.pageSize" 
                (pageEvent)="search($event)"
            ></generate-table>
        </mat-card-content>
    </mat-card>
</div>
