import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AjustarFaturamentoComponent, FaturamentoComponent, FormFaturamentoComponent, DioPrecificacao, ViewFaturamentoComponent, FaturamentoService } from '.';

@NgModule({
    declarations: [
        AjustarFaturamentoComponent,
        FaturamentoComponent,
        FormFaturamentoComponent,
        ViewFaturamentoComponent,
    ],
    imports: [SharedModule],
    providers: [
        DioPrecificacao,
        FaturamentoService,
    ]
})
export class FaturamentoModule { }
