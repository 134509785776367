import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, catchError, concat, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { Breadcrumb, RemoveDialogService } from 'src/app/shared/components';
import { UsuarioResponse, UsuarioService } from 'src/app/usuario';
import { catchErr, convertToDatePicker, showInputError } from 'src/app/shared/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService, RolesEnum } from 'src/app/core';
import { Pagination, StoragePageEnum } from 'src/app/shared/models';
import { StorageService } from 'src/app/shared/services';
import * as dayjs from 'dayjs';
import { Condutor } from '../condutor.model';
import { CondutorService } from '../condutor.service';
import { NgForm } from '@angular/forms';
import { convertDateFormat } from 'src/app/shared/date-utils';

@Component({
    selector: 'app-form-condutor',
    templateUrl: './form-condutor.component.html'
})
export class FormCondutorComponent implements OnInit {

    loading: any = { main: false, save: false, usuario: false };
    breadcrumb: Breadcrumb[];

    usuario$: Observable<UsuarioResponse[]>;
    usuarioInput$ = new Subject<string>();

    title: string;
    condutor = {} as Condutor;
    beneficiariaId: string;
    isContratadoAdm: boolean;    

    showInputError = showInputError;

    constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private condutorService: CondutorService,
        private removeDialogService: RemoveDialogService,
        private router: Router,
        private usuarioService: UsuarioService,
        private snackBar: MatSnackBar,
        private storageService: StorageService
    ) { }

    ngOnInit() {
        console.log('ngOnInit');
        const storage = this.storageService.storage[StoragePageEnum.FormCondutor];
        const storageBeneficiaria = this.storageService.storage[StoragePageEnum.FormBeneficiaria];

        if(storageBeneficiaria && storageBeneficiaria.beneficiaria && storageBeneficiaria.beneficiaria.user){
            this.condutor.user = storageBeneficiaria.beneficiaria.user;
            this.storageService.remove(StoragePageEnum.FormBeneficiaria);
        }

        if (storage && storage.condutor) {
            this.condutor = { ...storage.condutor };
            this.storageService.remove(StoragePageEnum.FormCondutor);
        }

        this.getUsuarios();
        this.beneficiariaId = this.activatedRoute.snapshot.params['idBeneficiaria'];
        this.condutor.id = this.activatedRoute.snapshot.params['idCondutor'];
        this.isContratadoAdm = this.authService.user.role == RolesEnum.CONTRATADO_ADM;

        this.title = this.condutor.id ? 'Editar' : 'Novo';

        if (this.condutor.id) {
            this.loading.main = true;
            this.condutorService.getById<Condutor>(this.condutor.id).subscribe({
                next: (res) => this.condutor = {
                    ...res,
                    validadeCNH: convertToDatePicker(res.validadeCNH),                    
                    user: {
                        id: res.userVirtualId,
                        firstName: res.firstName,
                        lastName: res.lastName,
                        avatarUrl: res.avatarUrl
                    }                    
                },
                error: (err) => catchErr(err, this.snackBar)
            }).add(() => this.loading.main = false);
        }

        this.breadcrumb = this.isContratadoAdm ? [
            { label: 'Início' },
            { label: 'Condutores', route: '/condutores' },
            { label: this.title + ' condutor' },
        ] : [
            { label: 'Início' },
            { label: 'Beneficiaria', route: '/beneficiaria' },
            { label: 'Visualizar beneficiaria', route: `/beneficiaria/${this.beneficiariaId}/visualizar`, queryParams: { tab: 'condutor' } },
            { label: this.title + ' condutor' },
        ]
    }
    

    compareWith(item: any, selected: any) {
        return item.id == selected.id
    }

    routeWithStorage(route: string, objKey: string) {        
        !this.condutor.id && this.storageService.set(StoragePageEnum.FormBeneficiaria, { beneficiaria: { id: this.beneficiariaId } })
        this.router.navigate([route], { queryParams: { returnObj: objKey, userRole: RolesEnum.CONTRATADO_CONDUTOR } });
    }

    save(form: NgForm) {
        this.loading.save = true;
        let request: Observable<any>

        const condutorToSave = {
            id: this.condutor.id,
            nome: this.condutor.user.firstName + ' ' + this.condutor.user.lastName,
            CNH: this.condutor.CNH,
            beneficiaria: this.beneficiariaId,
            userVirtualId: this.condutor.user.id,
            validadeCNH: dayjs(this.condutor.validadeCNH).format('YYYY-MM-DD')
        } as Condutor;

        request = condutorToSave.id
            ? this.condutorService.update<Condutor>(condutorToSave.id, condutorToSave)
            : this.condutorService.create<Condutor>(condutorToSave)

        request.subscribe({
            next: () => {
                this.snackBar.open('Condutor salvo com sucesso!', '✕', { panelClass: ['success'] });
                this.router.navigate(this.isContratadoAdm ? [`/condutores`] : [`/beneficiaria/${this.beneficiariaId}/visualizar`], { queryParams: { tab: 'condutor' } })
            }, error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading.save = false);
    }

    showRemoveDialog() {
        this.removeDialogService.show(this.condutor.firstName, (dialogRef: MatDialogRef<any>) => {
            this.condutorService.removerCondutor<Condutor>(this.condutor.id, this.beneficiariaId).subscribe({
                next: () => {
                    this.snackBar.open('Condutor apagado com sucesso!', '✕', { panelClass: ['success'] });
                    this.router.navigate([`/beneficiaria/${this.beneficiariaId}/visualizar`], { queryParams: { tab: 'condutor' } });
                    dialogRef.close();
                }, error: (err) => catchErr(err, this.snackBar)
            })
        });
    }

    hasAnotacoes(): boolean | undefined{
        return this.condutor && this.condutor.anotacao && this.condutor.anotacao.length > 0;
    }

    private getUsuarios() {
        this.usuario$ = concat(
            of([]), // default items
            this.usuarioInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.loading.usuario = true),
                switchMap((term) => {
                    return term ?
                        this.usuarioService.getPaginated<UsuarioResponse>(0, 20, term, RolesEnum.CONTRATADO_CONDUTOR).pipe(
                            map((res: Pagination<any>) => {                                
                                const enabledUsers = res.data.filter(user => user.enabled === true);                                
                                return enabledUsers;
                            }), // select by attr
                            catchError(() => of([])), // empty list on error
                            tap(() => this.loading.usuario = false)
                        ) : new Promise(() => this.loading.usuario = false) // not search all
                })
            ) as Observable<UsuarioResponse[]>
        );
    }
}
