
<mat-spinner class="mx-auto my-5" *ngIf="!afericao"></mat-spinner>

<ng-container *ngIf="afericao">
    <div mat-dialog-title>Associar Aferidor</div>
    <div mat-dialog-content>        
        <ng-select name="aferidor" [items]="aferidor$ | async" bindLabel="nome" required placeholder="Aferidor"
            typeToSearchText="Buscar Novo Aferidor..." notFoundText="Nenhum item encontrado"
            loadingText="Buscando..." [loading]="loading.aferidor" [typeahead]="aferidorInput$"
            (change)="setAferidor($event)" [(ngModel)]="afericao.aferidor" [compareWith]="compareWith">

            <!-- lista -->
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div class="d-flex flex-column py-2">
                    <span class="lh-sm">{{ item.nome }}</span>
                    <div class="fs-7 opacity-50">Número: {{ item.numero }}</div>
                </div>
            </ng-template>
        </ng-select>

        <span class="fw-bold fs-7 opacity-50">AFERIDOR ATUAL</span>
        <div class="mb-3 ms-3 mt-2 text-black">{{ aferidorAtual }}</div>
        
        <span class="fw-bold fs-7 opacity-50">NÚMERO AFERIDOR ATUAL</span>
        <div class="ms-3 mt-2 text-black">{{ aferidorNumero }}</div>

        <div style="margin-bottom: 110px;"></div>
    </div>
    <div mat-dialog-actions class="px-3 pb-3 justify-content-end">
        <button mat-button mat-dialog-close class="px-3">Fechar</button>
        <button mat-flat-button color="primary" (click)="update()" [ngClass]="{'btn-dialog-spinner': loading.save}" [disabled]="!aferidorIdSelected || loading.save">
            <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
            <span *ngIf="!loading.main">Associar</span>
        </button>
    </div>
</ng-container>