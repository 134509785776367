import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericCRUDService } from 'src/app/shared/services';

export interface RegraCorteAfericao {
  id: string;
  nome: string;
  descricao?: string;
  created_at: string;
  updated_at: string;
}

@Injectable({
  providedIn: 'root'
})
export class RegrasCortesAfericoesService extends GenericCRUDService{

  constructor(http: HttpClient) {
    super(http);
    this.url = `/regras-afericoes`;
}

  getRegrasAfericoes(): Observable<RegraCorteAfericao[]> {
    return this.http.get<RegraCorteAfericao[]>(this.url);
  }

  createRegraAfericao(regra: { nome: string; descricao?: string }): Observable<RegraCorteAfericao> {
    return this.http.post<RegraCorteAfericao>(this.url, regra);
  }
  
}
