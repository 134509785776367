<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>

    <mat-spinner class="mx-auto my-5" *ngIf="loading.main"></mat-spinner>
    <mat-card *ngIf="!loading.main">
        <mat-card-content>
          <div class="d-flex align-items-end mb-3">
            <h1 class="fs-3 fw-light mb-3">{{ title }} condutor</h1>
            <button mat-flat-button color="primary" class="ms-auto" [disabled]="!condutor.redFlag" [routerLink]="['/beneficiaria',beneficiariaId, 'condutor', condutor.id, 'anotacao']">Ver Anotações</button>
          </div>
            <form class="row align-items-center" name="form" (ngSubmit)="form.valid && save(form)" #form="ngForm" novalidate>
                
                <!-- USUÁRIO -->
                <div class="col-12HAHAHA">
                    <ng-select name="user" [items]="usuario$ | async" required
                        placeholder="Usuário" typeToSearchText="Buscar usuário..." notFoundText="Nenhum item encontrado" loadingText="Buscando..."
                        [loading]="loading.usuario" [typeahead]="usuarioInput$" [(ngModel)]="condutor.user" [compareWith]="compareWith">
                        
                        <!-- selected -->
                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            <span class="ng-value-label">{{ item.firstName }} {{ item.lastName }}</span>
                        </ng-template>

                        <!-- lista -->
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <img class="rounded-circle me-2 img-cover" width="25" height="25" [src]="item.avatarUrl || 'assets/img/default_user.jpeg'" />
                            <span>{{ item.firstName }} {{ item.lastName }}</span>
                        </ng-template>

                        <!-- add novo -->
                        <ng-template ng-footer-tmp>
                            <div class="d-flex align-items-center text-primary pointer" (click)="routeWithStorage('/usuario/novo', 'user')">
                                <mat-icon class="me-2">person_add</mat-icon> Adicionar novo usuário
                            </div>
                        </ng-template>
                    </ng-select>
                </div>

                <!-- CNH -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>CNH</mat-label>
                    <input matInput name="cnh" #cnh="ngModel" [(ngModel)]="condutor.CNH">
                </mat-form-field>

                <!-- VALIDADE -->
                <mat-form-field class="col-12 col-md-6">
                    <mat-label>Validade</mat-label>
                    <input matInput [matDatepicker]="picker" name="validadeCNH" #validadeCNH="ngModel"
                        autocomplete="off" [(ngModel)]="condutor.validadeCNH" required>

                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>                

                <div class="col-12">
                    <hr class="border-gray mt-0">
                </div>
                <div class="col-12 col-md-auto" *ngIf="condutor.id">
                    <button type="button" mat-button color="warn" class="btn-lg px-4" (click)="showRemoveDialog()" [disabled]="hasAnotacoes()">APAGAR</button>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button type="button" mat-button color="primary" class="btn-lg px-4" 
                        [routerLink]="isContratadoAdm ? ['/condutores'] : ['/beneficiaria', beneficiariaId ,'visualizar']" [queryParams]="isContratadoAdm ? {} : { tab: 'condutor' }">
                        VOLTAR
                    </button>
                </div>
                <div class="col-12 col-md-3">
                    <button mat-flat-button color="primary" class="btn-lg w-100" [disabled]="form.form.invalid || loading.save">
                        <mat-spinner class="btn-spinner" *ngIf="loading.save"></mat-spinner>
                        <span *ngIf="!loading.save">SALVAR</span>
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
