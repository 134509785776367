<div class="p-4">
    <breadcrumb [items]="breadcrumb"></breadcrumb>
    <mat-card>
        <mat-card-content>
            <div class="d-flex align-items-end mb-3">
                <h1 class="fs-3 fw-light mb-0">Relatório de Aferições do Aferidor</h1>
                <button mat-icon-button color="primary" class="d-print-none btn-sm ms-auto" (click)="toggleFilters = !toggleFilters">
                    <mat-icon>tune</mat-icon>
                </button>
                <button mat-flat-button class="d-print-none ms-3 bg-gray-400 ms-auto" (click)="print()">IMPRIMIR</button>
                <button mat-flat-button color="primary" class="d-print-none ms-3" [matMenuTriggerFor]="menu">EXPORTAR</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="export('pdf')">PDF</button>
                    <button mat-menu-item (click)="export('csv')">Excel</button>
                </mat-menu>
            </div>

            <!-- FILTROS -->
            <ng-collapse [toggle]="toggleFilters">
                <div class="border-top px-1 py-3">
                    <div class="row align-items-end">
                        <div class="col-12 col-md-6">
                            <!-- Adicione os filtros específicos aqui -->
                        </div>
                    </div>
                </div>
            </ng-collapse>

            <!-- TABLE -->
            <div class="overflow-x-auto">
                <generate-table [dataSource]="dataSource" [columns]="columns" [loading]="loading"
                    [total]="pagination.total" [pageSize]="pagination.pageSize"
                    (pageEvent)="search($event)"></generate-table>
            </div>

        </mat-card-content>
    </mat-card>
</div>