import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DioNaoExecutadoComponent, RelatorioComponent, ValorBrutoBeneficiarioComponent } from './';
import { RelatorioConsumoPorEquipamentoComponent } from './relatorio-consumo-por-equipamento/relatorio-consumo-por-equipamento.component';
import { ValorBrutoMonetarioComponent } from './valor-bruto-monetario/valor-bruto-monetario.component';
import { EquipamentosExistentesComponent } from './equipamentos-existentes/equipamentos-existentes.component';
import { ValoresBrutosEquipamentosComponent } from './valores-brutos-equipamentos/valores-brutos-equipamentos.component';
import { RelatorioAfericoesComponent } from './relatorio-afericoes/relatorio-afericoes.component';
import { RelatorioAprovacoesComponent } from './relatorio-aprovacoes/relatorio-aprovacoes.component';
import { RelatorioDiosAferidasComponent } from './relatorio-dios-aferidas/relatorio-dios-aferidas.component';
import { RelatorioAfericoesAferidorComponent } from './relatorio-afericoes-aferidor/relatorio-afericoes-aferidor.component';

@NgModule({
    declarations: [
        DioNaoExecutadoComponent,
        RelatorioComponent,
        RelatorioConsumoPorEquipamentoComponent,        
        ValorBrutoBeneficiarioComponent,
        ValorBrutoMonetarioComponent,
        ValoresBrutosEquipamentosComponent,
        EquipamentosExistentesComponent,
        RelatorioAfericoesComponent,
        RelatorioAprovacoesComponent,
        RelatorioDiosAferidasComponent,
        RelatorioAfericoesAferidorComponent,
    ],
    imports: [SharedModule]
})
export class RelatorioModule { }
