import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Breadcrumb, GenerateTableColumn, GenerateTablePageEvent, GenerateTablePagination } from 'src/app/shared/components';
import { ContratoService } from 'src/app/contrato';
import { Beneficiaria, BeneficiariaService } from 'src/app/beneficiaria';
import { catchErr } from 'src/app/shared/utils';
import { RelatorioService } from '../relatorio.service';
import { GenericRelatorioComponent } from '../generic-relatorio.component';
import { convertToHoursAndMinutes, dataAtualString, primeiroDiaDoMesString } from 'src/app/shared/date-utils';
import * as dayjs from 'dayjs';

@Component({
    selector: 'app-relatorio-dios-aferidas',
    templateUrl: './relatorio-dios-aferidas.component.html'
})
export class RelatorioDiosAferidasComponent extends GenericRelatorioComponent implements OnInit {

    breadcrumb: Breadcrumb[] = [
        { label: 'Início' },
        { label: 'Relatórios', route: '/relatorio' },
        { label: 'Relatório DIO\'s aferidas' },
    ]

    dataSource: any[];
    pagination: GenerateTablePagination = { total: 0, current: 0, pageSize: 10 };
    columns: GenerateTableColumn[] = [
        { header: 'N° DIO', attr: 'numeroDio' },
        { header: 'Data Execução do DIO', attr: 'dataExecucao' },
        { header: 'Hora Disponível', attr: 'horaDisponivel' },
        { header: 'Hora Trabalhada', attr: 'horaTrabalhada' },
        { header: 'Data Aferição', attr: 'dataAfericao' },
        { header: 'N° Aferidor', attr: 'numeroAferidor' },
        { header: 'Descrição da Regra', attr: 'regrasCortes' },
        { header: 'Diferença H.D.', attr: 'horaDisponivelCancelada' },
        { header: 'Diferença H.T.', attr: 'horaTrabalhadaCancelada' },
        { header: 'Observações Gerais', attr: 'observacoes' },
        { header: 'Hora Disponível Aferição', attr: 'horaDisponivelAfericao' },
        { header: 'Hora Trabalhada Aferição', attr: 'horaTrabalhadaAfericao' },
    ];

    constructor(
        private relatorioService: RelatorioService,
        private beneficiariaService: BeneficiariaService,
        protected override contratoService: ContratoService,
        protected override snackBar: MatSnackBar,
    ) {
        super(contratoService, snackBar);
    }

    ngOnInit() {
        this.loading = true;
        this.beneficiariaService.getAll<Beneficiaria>().subscribe({
            next: (res) => this.beneficiarias = res.data,
            error: (err) => catchErr(err, this.snackBar)
        }).add(() => this.loading = false);

        this.filters.dataInicio = primeiroDiaDoMesString();
        this.filters.dataFim = dataAtualString();

        this.search();
    }

    search(pageEvent?: GenerateTablePageEvent) {
        this.loading = true;
        this.pagination.current = pageEvent ? pageEvent.current : this.pagination.current
        this.pagination.pageSize = pageEvent ? pageEvent.pageSize : this.pagination.pageSize

        const filters = { ...this.filters };
        
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.relatorioService.getRelatorioDiosAferidas(filters).subscribe({
            next: (res: any) => {
                if (res.report.data) {
                    this.dataSource = res.report.data.map((item: any) => this.buildResponse(item));
                    console.log('dataSource: ', this.dataSource);
                }
            }
        }).add(() => this.loading = false);
    }

    setBeneficiaria(beneficiaria: Beneficiaria) {
        this.buscaContratos(beneficiaria.id);
    }

    buildResponse(item: any) {
        let dataOperacao = '---';

        if (item.dataPeriodoInicioOperacao && item.dataPeriodoFimOperacao) {
            const dataInicioOperacao = dayjs(item.dataPeriodoInicioOperacao).format('DD/MM/YYYY');
            const dataFimOperacao = dayjs(item.dataPeriodoFimOperacao).format('DD/MM/YYYY');
            dataOperacao = 'De ' + dataInicioOperacao + " até " + dataFimOperacao;
        }

        return {
            numeroDio: item.numeroDio,
            contratoNome: item.contratoNome,
            alocacaoCode: item.alocacaoCode,
            numeroAferidor: item.numeroAferidor,
            horaDisponivel: convertToHoursAndMinutes(item.horaDisponivel),
            horaTrabalhada: convertToHoursAndMinutes(item.horaTrabalhada),
            beneficiaria: item.beneficiaria,
            observacoes: item.observacoes,
            dataAfericao: item.dataAfericao ? dayjs(item.dataAfericao).format('DD/MM/YYYY HH:mm:ss') : '',
            dataExecucao: item.dataExecucao ? dayjs(item.dataExecucao).format('DD/MM/YYYY HH:mm:ss') : '',
            dataLimiteExecucao: dayjs(item.dataLimiteExecucao).format('DD/MM/YYYY HH:mm:ss'),
            horaDisponivelAfericao: convertToHoursAndMinutes(item.horaDisponivelAfericao),
            horaTrabalhadaAfericao: convertToHoursAndMinutes(item.horaTrabalhadaAfericao),
            horaDisponivelCancelada: convertToHoursAndMinutes(item.horaDisponivelCancelada),
            horaTrabalhadaCancelada: convertToHoursAndMinutes(item.horaTrabalhadaCancelada),
            regrasCortes: item.regrasCortes,
            created_at: dayjs(item.created_at).format('DD/MM/YYYY HH:mm:ss'),
        }
    }

    export(type: string = 'csv') {
        this.loading = true;

        const filters = { ...this.filters, removePaingation: true };
        filters.dataInicio = filters.dataInicio ? `${dayjs(filters.dataInicio).format('YYYY-MM-DD')}T00:00:00` : filters.dataInicio;
        filters.dataFim = filters.dataFim ? `${dayjs(filters.dataFim).format('YYYY-MM-DD')}T23:59:59` : filters.dataFim;

        this.relatorioService.downloadArquivoCsvOrPdf(filters, 'relatorio-dios-aferidas', type).subscribe({
            next: (res: any) => {
                const url = window.URL.createObjectURL(res.body as Blob);
                const startReport = filters.dataInicio ? dayjs(filters.dataInicio).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
                const endReport = filters.dataFim ? dayjs(filters.dataFim).format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')
                const fileName = `relatorio_dios_aferidas_${startReport}_${endReport}`
                const anchor = document.createElement("a");
                anchor.download = `${fileName}.${type}`;
                anchor.href = url;
                anchor.click();
                this.toggleFilters = false;
                this.loading = false;
            }, error: (err) => console.log('err', err)
        });
    }

}
