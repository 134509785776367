import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// material modules
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

// components
import { BreadcrumbComponent, GenerateTableComponent, ImgDialog, RemoveDialog, RemoveDialogService } from './components';
import { TranslatePaginatorIntl } from './paginator-intl';
import { SafeHtmlPipe, TranslatePipe } from './pipes';
import { AccessControlDirective } from './directives';
import { GenericCRUDService, StorageService } from './services';
import { NgDdFileModule } from 'ng-dd-file';
import { NgCollapseModule } from 'ng-collapse';
import { CarouselComponent } from './components/carousel/carousel.component';

// terceiros
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCurrencyDirective } from 'ngx-currency';
import { UtcDatePipe } from './pipes/utc-date.pipe';
import { UtcHoraMinutoPipe } from './pipes/utc-hora-minuto.pipe';
import { OpenInNewTabDirective } from './directives/open-in-new-tab.directive';

const libsModule = [
    CommonModule,
    FormsModule,
    HttpClientModule,
    NgCollapseModule,
    NgDdFileModule,
    NgSelectModule,
    RouterModule,

    NgxCurrencyDirective,
    NgxMaskDirective,

    NgxMaskPipe
]

const materialModule = [
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
]

@NgModule({
    declarations: [
        AccessControlDirective,
        OpenInNewTabDirective,
        BreadcrumbComponent,
        CarouselComponent,
        GenerateTableComponent,

        ImgDialog,
        RemoveDialog,

        SafeHtmlPipe,
        TranslatePipe,
        UtcDatePipe,
        UtcHoraMinutoPipe,
    ],
    imports: [
        ...libsModule,
        ...materialModule
    ],
    exports: [
        ...libsModule,
        ...materialModule,

        BreadcrumbComponent,
        CarouselComponent,
        GenerateTableComponent,

        AccessControlDirective,
        OpenInNewTabDirective,

        SafeHtmlPipe,
        TranslatePipe,
        UtcDatePipe,
        UtcHoraMinutoPipe,
    ],
    providers: [
        GenericCRUDService,
        RemoveDialogService,
        StorageService,

        provideNgxMask(),
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { minWidth: '500px', maxWidth: '80vw', maxHeight: '80vh', enterAnimationDuration: '300ms', exitAnimationDuration: '300ms' } },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { horizontalPosition: 'end', verticalPosition: 'top', duration: 5000, panelClass: ['primary'] } },
        { provide: MatPaginatorIntl, useClass: TranslatePaginatorIntl }
    ]
})
export class SharedModule { }
